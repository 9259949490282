import Typography from "@mui/material/Typography";
import React from "react";
import {useMutation, useQuery} from "@tanstack/react-query";
import {deleteSubredditGroup, queryGroups} from "../../api";
import {Chip, IconButton, Stack, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import Box from "@mui/material/Box";
import AddIcon from '@mui/icons-material/Add';
import Button from "@mui/material/Button";
import DeleteIcon from '@mui/icons-material/Delete';
import {Link, useNavigate} from "react-router-dom";
import {queryClient} from "../../app";

export const SubredditGroupsList = () => {
  const navigate = useNavigate();
  const subredditGroupQuery = useQuery(
    ["subredditGroupQuery"],
    () => queryGroups(),
    {
      enabled: true
    }
  );

  const updateSubredditGroupMutation = useMutation({
    mutationFn: (id: string) =>
      deleteSubredditGroup(id),
  });

  const handleDeleteGroup = async (groupId: string) => {
     await updateSubredditGroupMutation.mutateAsync(groupId, { onSuccess: () => {
         queryClient.invalidateQueries(['subredditGroupQuery'])
       }});
  }
  function RenderTableRow() {
    if (!subredditGroupQuery.data?.length) {
      return (
        <TableRow>
          <TableCell>
            You don't have any groups created yet. Try creating one!
          </TableCell>
        </TableRow>
      );
    }

    return (
      <>
        {subredditGroupQuery?.data.map((group, i) => (
          <TableRow key={group.name + i}>
            <TableCell>
              <Stack direction={'row'} gap={2}>
                <Chip color={'secondary'} size={'small'} label={group.subreddits.length + ' Subs'} />
                <Link to={`${group.id}`}>
                  {group.name}
                </Link>
              </Stack>
            </TableCell>
            <TableCell>
              <IconButton onClick={() => handleDeleteGroup(group.id)}>
                <DeleteIcon color={'action'} />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </>
    );
  }
  return (
    <>
      <Typography variant={'h5'}>
        Subreddit Groups
      </Typography>
      <Typography variant={'body2'}>
        Groups are a great way for you to categorize subreddits you frequently use.
      </Typography>
      <Box mt={2}>
        <Button variant={'contained'} size={'small'} onClick={() => navigate('add')}>
          <AddIcon fontSize={'small'}/> Add
        </Button>
      </Box>
      <Box mt={2}>
        <Table>
          <TableHead>
            <TableCell>
              Name
            </TableCell>
            <TableCell>

            </TableCell>
          </TableHead>
          <TableBody>
            <RenderTableRow />
          </TableBody>
        </Table>
      </Box>
    </>
  )
}