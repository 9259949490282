import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import TwitterIcon from "@mui/icons-material/Twitter";
import RedditIcon from "@mui/icons-material/Reddit";
import React from "react";
import {useTheme} from "@material-ui/core";
import useMediaQuery from "@mui/material/useMediaQuery";

interface GradientOpenWithIconProps {
  platform: string
}

export const GradientOpenWithIcon = ({ platform }: GradientOpenWithIconProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const platformColors = {
    twitter: ["#77BDEEFF", "#1DA1F2", "#196A9A"],
    reddit: ["#FF4500", "#FF8717"]
  };

  const getGradientStops = () => {
    switch (platform) {
      case 'twitter':
        return (
          <>
            <stop offset="0%" stopColor={platformColors.twitter[1]} />
            <stop offset="50%" stopColor={platformColors.twitter[0]} />
          </>
        );
      case 'reddit':
        return (
          <>
            <stop offset="0%" stopColor={platformColors.reddit[0]} />
            <stop offset="100%" stopColor={platformColors.reddit[1]} />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Tooltip title={platform.charAt(0).toUpperCase() + platform.slice(1) + " Upload"}>
      <Box sx={{
        position: 'relative',
        display: 'inline-block',
        cursor: 'pointer',
        transition: 'transform 0.2s',
        '&:hover': { transform: 'scale(1.05)' }
      }}>
        <svg width={0} height={0}>
          <linearGradient id="gradientIcon" x1="0%" y1="0%" x2="0%" y2="100%">
            {getGradientStops()}
          </linearGradient>
        </svg>
        <UploadFileIcon
          sx={{
            fontSize: isMobile ? '6em' : '8em',
            fill: "url(#gradientIcon)",
            pointerEvents: 'none'
          }}
        />
        <div style={{ position: 'absolute', bottom: 0, right: 0 }}>
          {platform === 'twitter' ? <TwitterIcon fontSize="small" /> : <RedditIcon fontSize="small" />}
        </div>
      </Box>
    </Tooltip>
  );
}