import { Box, ClickAwayListener, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import ResponsiveAppBar from "../../components/app-header";
import Container from "@mui/material/Container";
import { Navigate, Route, Routes } from "react-router-dom";
import {
  CreateViral,
  ListVirals,
  Viral,
  Settings,
  Support,
  Pricing,
} from "../";
import Drawer from "@mui/material/Drawer";

import { Toaster } from "react-hot-toast";
import NavigationDrawer from "../../components/navigation-drawer";
import { useMediaQuery } from "@material-ui/core";
import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import { HowTo } from "../how-to/how-to";
import { AdultContentDialog } from "./components/adult-content-dialog";
import { CreatePost } from "../create-post/create-post";
import { ListPosts } from "../list-posts/list-posts";
import {
  DialogProvider,
} from "../../components/app-dialog-provider";
import {History} from "../history/history";
import {SubredditGroupsList} from "../subreddit-groups/subreddit-groups-list";
import {SubredditGroupsAdd} from "../subreddit-groups/subreddit-groups-add";
import {SubredditGroupsEdit} from "../subreddit-groups/subreddit-groups-edit";
import {Dashboard} from "../dashboard/dashboard";
import {Trends} from "../trends/trends";
import {CreateTweet} from "../create-tweet/create-tweet";

// function getSocketHost(): string {
//   if(window.location.host.includes('local')){
//     return `ws://localhost`
//   } else {
//     return `wss://${window.location.host}`
//   }
// }
//
// const socketUrl = `${getSocketHost()}/api/users/ws/subscribe`;

// Maybe we can use websocket one day. Uncomment this to get it to work.
// const {
//   getWebSocket,
// } = useWebSocket(socketUrl, {
//   onMessage: (message) => {
//     console.log('should see this multiple times...0.0.0');
//     setSocketMessageUpdate(message)
//   },
//   shouldReconnect: (closeEvent) => true,
// });

// const [socketMessageUpdat/**/e, setSocketMessageUpdate] = useState<any>(null);

const useStyles = makeStyles((theme) =>
  createStyles({
    mainAppContainerDesktop: {
      paddingLeft: "265px !important",
    },
    drawerDesktop: {
      display: "block",
      "& .MuiDrawer-paper": { boxSizing: "border-box", width: "250px" },
    },
  })
);
const AppHome = (): React.ReactElement => {
  const [open, setOpen] = useState(false);
  const [navManuOpen, setNavManuOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();

  useEffect(() => {
    setOpen(true);
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickAway = () => {
    if (isMobile && navManuOpen) {
      setNavManuOpen(false);
    }
  };

  return (
    <>
      <DialogProvider>
        <Toaster />
        <Box component="nav" aria-label="mailbox folders">
          {navManuOpen ? (
            <ClickAwayListener onClickAway={handleClickAway}>
              <Drawer
                open={navManuOpen}
                anchor={isMobile ? "top" : "left"}
                variant={isMobile ? "persistent" : "permanent"}
                className={isMobile ? "" : classes.drawerDesktop}
              >
                <NavigationDrawer key={0} setNavManuOpen={setNavManuOpen} />
              </Drawer>
            </ClickAwayListener>
          ) : (
            <Drawer
              open={navManuOpen}
              anchor={isMobile ? "top" : "left"}
              variant={isMobile ? "persistent" : "permanent"}
              className={isMobile ? "" : classes.drawerDesktop}
            >
              <NavigationDrawer key={1} setNavManuOpen={setNavManuOpen} />
            </Drawer>
          )}
        </Box>
        <Container
          maxWidth={false}
          className={!isMobile ? classes.mainAppContainerDesktop : ""}
        >
          <AdultContentDialog open={open} handleClose={handleClose} />
          <ResponsiveAppBar
            setNavManuOpen={setNavManuOpen}
            navManuOpen={navManuOpen}
          />
          <Grid
            container
            style={{ padding: !isMobile ? 20 : "20px 0px ", marginTop: "64px" }}
          >
            <Grid item xs={12}>
              <Routes>
                <Route path="/" element={<Navigate to="/dashboard" />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/virals" element={<ListVirals />} />
                <Route path="/posts/new/:step" element={<CreatePost />} />
                <Route path="/tweets/new/:step" element={<CreateTweet />} />
                <Route path="/posts" element={<ListPosts />} />
                <Route path="/history" element={<History />} />
                <Route path="/groups" element={<SubredditGroupsList />} />
                <Route path="/groups/add" element={<SubredditGroupsAdd />} />
                <Route path="/groups/:id" element={<SubredditGroupsEdit />} />
                <Route path="/virals/new" element={<CreateViral />} />
                <Route path="/virals/:id" element={<Viral />} />
                <Route path="/settings/*" element={<Settings />} />
                <Route path="/support" element={<Support />} />
                <Route path="/pricing" element={<Pricing />} />
                <Route path="/how-to" element={<HowTo />} />
                <Route path="/trends" element={<Trends />} />
              </Routes>
            </Grid>
          </Grid>
        </Container>
      </DialogProvider>
    </>
  );
};

export default AppHome;
