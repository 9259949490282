import React, {FC, useEffect, useState} from 'react';
import { Box } from '@mui/material';
import { PostResponse } from '../../../api/types';
import {makeStyles} from "@material-ui/core/styles";
import {transformUrl} from "../../../utils";

interface RenderMediaProps {
  post: PostResponse;
  isMobile?: boolean;
}

const useStyles = makeStyles((theme) => ({
  mediaChild: {
    width: '100%',
    height: '100%',
    objectFit: 'cover' // Keep aspect ratio
  },
  mediaBox: {
    maxWidth: '100%',
    width: 100 * 1.5, // Set your desired width
    height: 60 * 1.5  // Set your desired height
  },
  mediaBoxMobile: {
    maxWidth: '100%',
    height: 60 * 2.5
  }
}));
const RenderMedia: FC<RenderMediaProps> = ({ post, isMobile = false }) => {
  const classes = useStyles()

  const [mediaType, setMediaType] = useState<'image' | 'video' | 'redgifs' | null>(null);

  useEffect(() => {
    if (post.link) {
      const extension = post.link.split('.').pop()?.toLowerCase();
      if (post.link.includes('redgifs')) {
        setMediaType('redgifs')
      } else if (['png', 'jpg', 'jpeg', 'gif', 'webp'].includes(extension || '')) {
        setMediaType('image');
      } else if (['mp4', 'webm', '.mov'].includes(extension || '')) {
        setMediaType('video');
      }
      // Add more media types as necessary
    }
  }, [post.link]);


  if (post.link && mediaType) {
    return (
      <Box className={isMobile ? classes.mediaBoxMobile : classes.mediaBox}>
        {mediaType === 'image' && (
          <img src={post.link} alt="link for media" className={classes.mediaChild} />
        )}
        {mediaType === 'video' && (
          <video controls className={classes.mediaChild}>
            <source src={post.link} />
          </video>
        )}
        {mediaType === 'redgifs' && (
          <div style={{position: 'relative', width: '100%', height: '100%'}}>
            <iframe src={transformUrl(post.link)}
                    frameBorder='0'
                    scrolling='no'
                    className={classes.mediaChild}
                    allowFullScreen>
            </iframe>
          </div>
        )}
      </Box>
    );
  }

  if (post.file && post.file.downloadUrl && post.file.contentType?.startsWith('image')) {
    return (
      <Box className={isMobile ? classes.mediaBoxMobile : classes.mediaBox}>
        <img src={post.file.downloadUrl} alt="link for media" className={classes.mediaChild} />
      </Box>
    );
  }

  if (post.file && post.file.downloadUrl && post.file.contentType?.startsWith('video')) {
    return (
      <Box className={isMobile ? classes.mediaBoxMobile : classes.mediaBox}>
        <video controls className={classes.mediaChild}>
          <source src={post.file.downloadUrl} type={post.file.contentType} />
        </video>
      </Box>
    );
  }

  return null;
};

export default RenderMedia;
