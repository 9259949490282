import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ListItemIcon from "@mui/material/ListItemIcon";
import RedditIcon from '@mui/icons-material/Reddit';
import TwitterIcon from '@mui/icons-material/Twitter';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import SchoolIcon from '@mui/icons-material/School';
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import HistoryIcon from '@mui/icons-material/History';
import VideoCameraFrontIcon from "@mui/icons-material/VideoCameraFront";
import SettingsIcon from "@mui/icons-material/Settings";
import ListItemText from "@mui/material/ListItemText";
import React, { Dispatch, SetStateAction } from "react";
import {useLocation, useNavigate} from "react-router-dom";
import FeatureUsageBar from "./feature-usage-bar";
import { useGetUser } from "../hooks/api-hooks/useGetUser";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {Stack} from "@mui/material";

interface NavigationDrawerProps {
  setNavManuOpen: Dispatch<SetStateAction<boolean>>;
  // navManuOpen: boolean;
}

const useStyles = makeStyles((theme) => ({
  menuCategory: {
    color: theme.palette.grey.A200,
  },
}));

export default function NavigationDrawer({
  setNavManuOpen,
}: NavigationDrawerProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useGetUser();

  const handleNavigate = (route: string) => {
    navigate(route);
    setNavManuOpen(false);
  };

  const isActive = (path: string) => {
    return location.pathname === path
  }

  const isMatchingActive = (path: string) => {
    return location.pathname.includes(path)
  }

  return (
    <div>
      <Toolbar />
      <Stack direction={'column'} p={2} px={2} gap={2}>
        <Button
          variant="contained" // Added this to give it a filled look, but you can change it
          startIcon={<RedditIcon />} // This is where we add the Reddit icon
          fullWidth={true}
          style={{
            backgroundColor: "#FF4500", // Reddit's primary color
            color: "white" // Text color, setting it to white for better contrast
          }}
          onClick={() => handleNavigate("/posts/new/upload")}
        >
          Create Posts
        </Button>

        <Button
          variant="contained" // Added this to give it a filled look, but you can change it
          startIcon={<TwitterIcon />} // This is where we add the Reddit icon
          fullWidth={true}
          style={{
            backgroundColor: "#1DA1F2", // Twitter's primary color
            color: "white" // Text color, setting it to white for better contrast
          }}
          onClick={() => handleNavigate("/tweets/new/upload")}
        >
          Create Tweets
        </Button>

        <Button
          variant="contained" // Added this to give it a filled look, but you can change it
          startIcon={<AddCircleOutlineIcon />} // This is where we add the Reddit icon
          color={'primary'}
          fullWidth={true}
          onClick={() => handleNavigate("/virals/new")}
        >
          Create Viral
        </Button>

      </Stack>
      <List>
        <ListItem key={"Dashboard"} disablePadding>
          <ListItemButton onClick={() => handleNavigate("/dashboard")} selected={isMatchingActive('/dashboard')}>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary={"Dashboard"} />
          </ListItemButton>
        </ListItem>
        <ListItem key={"Scheduled"} disablePadding>
          <ListItemButton onClick={() => handleNavigate("/posts")} selected={isActive('/posts')}>
            <ListItemIcon>
              <CalendarMonthIcon />
            </ListItemIcon>
            <ListItemText primary={"Scheduled"} />
          </ListItemButton>
        </ListItem>
        <ListItem key={"History"} disablePadding>
          <ListItemButton onClick={() => handleNavigate("/history")} selected={isActive('/history')}>
            <ListItemIcon>
              <HistoryIcon />
            </ListItemIcon>
            <ListItemText primary={"History"} />
          </ListItemButton>
        </ListItem>
        <ListItem key={"My Virals"} disablePadding>
          <ListItemButton onClick={() => handleNavigate("/virals")} selected={isActive('/virals')}>
            <ListItemIcon>
              <VideoCameraFrontIcon />
            </ListItemIcon>
            <ListItemText primary={"My Virals"} />
          </ListItemButton>
        </ListItem>
        <ListItem key={"Groups"} disablePadding>
          <ListItemButton onClick={() => handleNavigate("/groups")} selected={isMatchingActive('/groups')}>
            <ListItemIcon>
              <WorkspacesIcon />
            </ListItemIcon>
            <ListItemText primary={"Subreddit Groups"} />
          </ListItemButton>
        </ListItem>
        <ListItem key={"Trend Analysis"} disablePadding>
          <ListItemButton onClick={() => handleNavigate("/trends")} selected={isActive('/trends')}>
            <ListItemIcon>
              <TrendingUpIcon />
            </ListItemIcon>
            <ListItemText primary={"Trend Analysis"} />
          </ListItemButton>
        </ListItem>
        <ListItem key={"Settings"} disablePadding>
          <ListItemButton onClick={() => handleNavigate("/settings")} selected={isActive('/settings')}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary={"Settings"} />
          </ListItemButton>
        </ListItem>
        <ListItem key={"Pricing"} disablePadding>
          <ListItemButton onClick={() => handleNavigate("/pricing")} selected={isActive('/pricing')}>
            <ListItemIcon>
              <LocalOfferIcon />
            </ListItemIcon>
            <ListItemText primary={"Pricing"} />
          </ListItemButton>
        </ListItem>
        <ListItem key={"Support"} disablePadding>
          <ListItemButton onClick={() => handleNavigate("/support")} selected={isActive('/support')}>
            <ListItemIcon>
              <ContactSupportIcon />
            </ListItemIcon>
            <ListItemText primary={"Support"} />
          </ListItemButton>
        </ListItem>
        <ListItem key={"HowTo"} disablePadding>
          <ListItemButton onClick={() => handleNavigate("/how-to")} selected={isActive('/how-to')}>
            <ListItemIcon>
              <SchoolIcon />
            </ListItemIcon>
            <ListItemText primary={"How To"} />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <List>
        {user.data?.subscription?.features?.map((feature) => {
          return (
            <ListItem key={user.data.id + feature.name}>
              <FeatureUsageBar
                usage={feature.usage}
                limit={feature.limit}
                featureName={feature.name}
              />
            </ListItem>
          );
        })}
      </List>
    </div>
  );
}
