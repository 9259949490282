import {Chip, Stack, TextField} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import React, {Dispatch, SetStateAction, useCallback} from "react";
import Button from "@mui/material/Button";
import { SubredditResponse } from "../api/types";
import {debounce, formatSubs} from "../utils";
import VerifiedIcon from "@mui/icons-material/Verified";


interface FilterPillButtonProps {
  label: string;
  isOn: boolean;
  onClick?: (selected: boolean) => void; // Optional onClick function
}

const FilterPillButton: React.FC<FilterPillButtonProps> = ({
  label,
  isOn,
  onClick,
}) => {
  const handleButtonClick = () => {
    if (onClick) onClick(!isOn);
  };

  return (
    <Button
      variant={isOn ? "contained" : "outlined"}
      size="medium"
      color={isOn ? "secondary" : "primary"}
      onClick={handleButtonClick}
    >
      {label}
    </Button>
  );
};


interface MemoizedOptionProps {
  option: SubredditResponse
}

const MemoizedOption = React.memo(({ option }: MemoizedOptionProps) => (
  <Stack direction={"column"} >
    <Stack direction={"row"} justifyContent={'center'} alignItems={'center'} gap={2}>
      {typeof option === "string"
        ? option
        : `r/${option.name} - ${formatSubs(option.subs)}`}{" "}
      {option.over18 ? (
        <span
          style={{
            border: "1px solid #FF585B",
            borderRadius: '2px',
            padding: '0 4px',
            color: "#FF585B",
            fontSize: "12px",
            fontWeight: 500,
          }}
        >
            nsfw
          </span>
      ) : null}
    </Stack>
    <div>
      {option.verify ? (
        <>
          <i>Verify</i> <VerifiedIcon fontSize={"small"} color={"primary"} />
        </>
      ) : (
        <i>No Verify</i>
      )}
    </div>
  </Stack>
));

// @ts-ignore
const MemoizedTag = React.memo(({ value, ...props }) => (
  <Chip
    variant="outlined"
    label={typeof value === "string" ? value : value.name}
    {...props}
    style={{
      backgroundColor: "#FF326F",
      fontSize: ".85em",
      border: "none",
      color: "white",
    }}
  />
));

interface RedditSelectProps {
  verifyFilter: boolean;
  setVerifyFilter: Dispatch<SetStateAction<boolean>>;
  nsfwFilter: boolean;
  setNsfwFilter: Dispatch<SetStateAction<boolean>>;
  setFilteredSubreddits: Dispatch<SetStateAction<SubredditResponse[]>>;
  subreddits: SubredditResponse[];
  searchInputValue: string | null;
  selectedSubreddits: SubredditResponse[];
  setSelectedSubreddits: React.Dispatch<
    React.SetStateAction<SubredditResponse[]>
  >;
  updateFilteredSubreddits: () => void;
  subredditsTopFilter: boolean;
  setSubredditsTopFilter: Dispatch<SetStateAction<boolean>>;
  setSearchInputValue: Dispatch<SetStateAction<string | null>>
}


export const RedditSelect = ({
  verifyFilter,
  setVerifyFilter,
  nsfwFilter,
  setNsfwFilter,
  subreddits,
  selectedSubreddits,
  setSelectedSubreddits,
  updateFilteredSubreddits,
  subredditsTopFilter,
  setSubredditsTopFilter,
  setSearchInputValue

}: RedditSelectProps) => {

  const setSelected = (list: SubredditResponse[]) => {
    const mergedMap = new Map();

    // Populating Map from the list
    list.forEach(item => {
      mergedMap.set(item.name, item);
    });

    // Adding items from selectedSubreddits if they exist in the list (mergedMap)
    selectedSubreddits.forEach(item => {
      if (mergedMap.has(item.name)) {
        // If the item exists in the list, keep it
        mergedMap.set(item.name, item);
      } // If the item doesn't exist in the list, it won't be added, effectively removing it
    });

    const mergedList = Array.from(mergedMap.values());
    setSelectedSubreddits(mergedList);
  };

  const debouncedUpdate = useCallback(
    debounce((updateInputValue) => {
      setSearchInputValue(updateInputValue)
    }, 100),
    []
  );

  return (
    <>
      <Stack direction={"row"} spacing={3} mb={3}>
        <FilterPillButton
          label={"No Verify"}
          isOn={verifyFilter}
          onClick={() => {
            setVerifyFilter(!verifyFilter);
          }}
        />
        <FilterPillButton
          label={"NSFW Only"}
          isOn={nsfwFilter}
          onClick={() => {
            setNsfwFilter(!nsfwFilter);
          }}
        />
        <FilterPillButton
          label={"Top 200 Subs"}
          isOn={subredditsTopFilter}
          onClick={() => {
            setSubredditsTopFilter(!subredditsTopFilter);
          }}
        />
      </Stack>

      {/* Subreddit multi select */}

      <Autocomplete
        disableListWrap
        multiple
        freeSolo
        id="tags-outlined"
        options={subreddits || []}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.name
        }
        onInputChange={(event, newInputValue) => {
          debouncedUpdate(newInputValue);
        }}
        filterSelectedOptions
        onChange={(event, value, reason, details) => {
          const enhancedValue = value.map((item) => {
            // If it's a string (custom subreddit), create a new SubredditResponse object
            if (typeof item === "string") {
              return {
                name: item,
                category: "custom",
                verify: false,
                over18: null,
                subs: 0, // Default properties, modify as needed
              };
            }
            return item;
          });
          setSelected(enhancedValue);
        }}
        value={selectedSubreddits}
        renderOption={(props, option, { selected, index }) => (
          <li {...props}>
            {/*@ts-ignore*/}
            <MemoizedOption option={option} />
          </li>
        )}
        renderInput={(params) => (
          <>
            <TextField {...params} label="Add Subreddits Here" />
          </>
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            // @ts-ignore
            <MemoizedTag value={option} {...getTagProps({ index })} />
          ))
        }
      />
    </>
  );
};
