import Typography from "@mui/material/Typography";
import React from "react";
import {Alert, Card, CardContent, CircularProgress, Grid, Stack} from "@mui/material";
import {HealthStatusBar} from "./components/health-status-bar";
import {useGetStatistics} from "../../hooks/api-hooks/useGetStatistics";
import Button from "@mui/material/Button";
import ScheduleIcon from '@mui/icons-material/Schedule';
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import VideoCameraFrontIcon from "@mui/icons-material/VideoCameraFront";
import SettingsIcon from '@mui/icons-material/Settings';
import {Link as RouterLink, useNavigate} from "react-router-dom";
import {useGetUser} from "../../hooks/api-hooks/useGetUser";
import Box from "@mui/material/Box";
import { useStyles } from "../../shared/styles/shared-styles";
import TwitterIcon from "@mui/icons-material/Twitter";
import RedditIcon from "@mui/icons-material/Reddit";

export const Dashboard = () => {
  const classes = useStyles();
  const navigate = useNavigate()
  const user = useGetUser();

  const statisticsQuery = useGetStatistics();

  return (
    <>
      <Typography variant={'h5'} mb={3}>
        Dashboard
      </Typography>
      {/* ACTION BAR  */}
      <Grid container spacing={2} mb={3} className={classes.cardContainer}>
        <Grid item xs={12} md={4} xl={2}>
          <Card className={`${classes.actionCard} ${classes.cardFullHeight}`} variant='outlined'>
            <CardContent className={classes.cardHeroContent}>
              <Stack direction='column' alignItems='center' justifyContent='space-between' spacing={2} className={classes.cardHeroStack}>
                <ScheduleIcon className={classes.actionIcon} />
                <Typography mb={1} variant='h6' align={'center'}>
                  Viral Score
                </Typography>
                <Typography gutterBottom>
                  Improve your viral score by posting frequently to grow your fans.
                </Typography>
                  <Button
                    variant="contained" // Added this to give it a filled look, but you can change it
                    startIcon={<TwitterIcon />} // This is where we add the Reddit icon
                    fullWidth={true}
                    style={{
                      backgroundColor: "#1DA1F2", // Twitter's primary color
                      color: "white" // Text color, setting it to white for better contrast
                    }}
                    onClick={() => navigate("/tweets/new/upload")}
                  >
                    Create Tweets
                  </Button>

                  <Button
                    variant="contained" // Added this to give it a filled look, but you can change it
                    startIcon={<RedditIcon />} // This is where we add the Reddit icon
                    fullWidth={true}
                    style={{
                      backgroundColor: "#FF4500", // Reddit's primary color
                      color: "white" // Text color, setting it to white for better contrast
                    }}
                    onClick={() => navigate("/posts/new/upload")}
                  >
                    Create Posts
                  </Button>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4} xl={2}>
          <Card className={`${classes.actionCard} ${classes.cardFullHeight}`} variant='outlined'>
            <CardContent className={classes.cardHeroContent}>
              <Stack direction='column' alignItems='center' justifyContent='space-between' spacing={2} className={classes.cardHeroStack}>
                <VideoCameraFrontIcon className={classes.actionIcon} />
                <Typography mb={1} variant='h6' align={'center'}>
                  Viral Clips
                </Typography>
                <Typography gutterBottom>
                  Create 5-15s Viral Clips From Your Long Videos and Share them on Reddit & Twitter/X
                </Typography>
                <Button variant='contained' className={classes.actionButton} onClick={() => navigate('/virals/new')}>
                  Create Viral
                </Button>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4} xl={2}>
          <Card className={`${classes.actionCard} ${classes.cardFullHeight}`} variant='outlined'>
            <CardContent className={classes.cardHeroContent}>
              <Stack direction='column' alignItems='center' justifyContent='space-between' spacing={2} className={classes.cardHeroStack}>
                <WorkspacesIcon className={classes.actionIcon} />
                <Typography mb={1} variant='h6' align={'center'}>
                  Improve Workflow
                </Typography>
                <Typography gutterBottom>
                  Create re-usable subreddit groups to make it easier to schedule
                </Typography>
                <Button variant='contained' className={classes.actionButton} onClick={() => navigate('/groups')}>
                  Manage subreddit groups
                </Button>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4} xl={2}>
          <Card className={`${classes.settingCard} ${classes.cardFullHeight}`} variant='outlined'>
            <CardContent className={classes.cardHeroContent}>
              <Stack direction='column' alignItems='center' justifyContent='space-between' spacing={2} className={classes.cardHeroStack}>
                <SettingsIcon className={classes.actionIcon} />
                <Typography mb={1} variant='h6' align={'center'}>
                  Complete your checklist!
                </Typography>
                <Button variant='contained' className={classes.actionButton} onClick={() => navigate('/settings/checklist')}>
                  Settings
                </Button>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* STATISTICS */}

      <Box  mb={3}>
        <Typography variant={'h5'}>
          Reddit Viral Stats { statisticsQuery.isFetching ? <CircularProgress size={20} style={{marginLeft:15}} />  : null }
        </Typography>
      </Box>

      {!user.data?.isRedditLinked ? <Alert severity={'warning'} sx={{marginBottom: 3}}>
        Please <RouterLink to="/settings/checklist">link your Reddit account</RouterLink> to fully generate your statistics
      </Alert> : null}
      {!user.data?.isTwitterLinked ? <Alert severity={'warning'} sx={{marginBottom: 3}}>
        Please <RouterLink to="/settings/checklist">link your Twitter account</RouterLink> to fully generate your statistics
      </Alert> : null}
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Card variant={'outlined'} className={classes.card}>
            <CardContent>
              <Stack>
                <Typography gutterBottom variant="h6" className={classes.cardTitle}>
                  Viral Score
                </Typography>
                <Typography variant={'h2'}>
                  {statisticsQuery.data?.viralScore}
                </Typography>
                <HealthStatusBar value={statisticsQuery.data?.viralScore || 0} />
                <Typography variant={'caption'} mt={2} className={classes.captionTitle}>
                  Your viral score is your current overall viral presence online in the past 30 days. Score can reach 100
                </Typography>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card variant={'outlined'} className={classes.card}>
            <CardContent>
              <Stack>
                <Typography gutterBottom variant="h6" className={classes.cardTitle}>
                  Upcoming Reddit Posts
                </Typography>
                <Typography variant={'h2'}>
                  {statisticsQuery.data?.totalNumberOfUpcomingPosts}
                </Typography>
                <HealthStatusBar value={statisticsQuery.data?.totalNumberOfUpcomingPosts || 0} type={'numberOfPosts'} />
                <Typography variant={'caption'} mt={2} className={classes.captionTitle}>
                  Upcoming reddit posts in the next 30 days
                </Typography>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card variant={'outlined'} className={classes.card}>
            <CardContent>
              <Stack>
                <Typography gutterBottom variant="h6" className={classes.cardTitle}>
                  Total Reddit Upvotes
                </Typography>
                <Typography variant={'h2'}>
                  {statisticsQuery.data?.totalRedditUpvotes}
                </Typography>
                <HealthStatusBar value={-1} />
                <Typography variant={'caption'} mt={2} className={classes.captionTitle}>
                  Your total reddit upvotes in the past 30 days
                </Typography>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card variant={'outlined'} className={classes.card}>
            <CardContent>
              <Stack>
                <Typography gutterBottom variant="h6" className={classes.cardTitle}>
                  Past Reddit Posts
                </Typography>
                <Typography variant={'h2'}>
                  {statisticsQuery.data?.totalNumberOfPreviousPosts}
                </Typography>
                <HealthStatusBar value={-1} />
                <Typography variant={'caption'} mt={2} className={classes.captionTitle}>
                  Total number of Reddit posts in the past 30 days
                </Typography>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card variant={'outlined'} className={classes.card}>
            <CardContent>
              <Stack>
                <Typography gutterBottom variant="h6" className={classes.cardTitle}>
                  Total Viral Videos
                </Typography>
                <Typography variant={'h2'}>
                  {statisticsQuery.data?.totalViralVideos}
                </Typography>
                <HealthStatusBar value={-1} />
                <Typography variant={'caption'} mt={2} className={classes.captionTitle}>
                  How many viral videos have you made
                </Typography>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Box  mb={3} mt={3}>
        <Typography variant={'h5'} color={'grey'}>
          Twitter Viral Stats  (Coming Soon)
        </Typography>
      </Box>
    </>
  )
}