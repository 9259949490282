import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import React, {useEffect, useRef} from "react";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import {useGetViral} from "../../../hooks";

interface PlayVideoModalProps{
  viralId: string;
  videoModalOpen: boolean;
  setVideoModalOpen: (bool: boolean) => void;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    boxVideo: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: '72em',
      width: '100%', // Reduce width to 90% to provide some space on the sides.
      height: '100%',
      maxHeight: '50%', // Reduce height to 90% to provide some space on the top and bottom.
      display: 'flex', // Use flex to center the video
      alignItems: 'center',
      justifyContent: 'center',
    },
    video: {
      width: '100%', // Full width to fill the parent box
      maxHeight: '100%', // Full height to fill the parent box
      objectFit: 'contain', // This will ensure that the video is scaled correctly, while maintaining its aspect ratio.
    },
  }));

export function PlayVideoModal({viralId, videoModalOpen, setVideoModalOpen}: PlayVideoModalProps) {
  const classes = useStyles();
  const viral = useGetViral(viralId);
  const file = viral.data?.viralFiles.find((file) => file.type === 'final');
  const video = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (videoModalOpen && !viral.isLoading && video.current) {
      // @ts-ignore
      video.current.src = file?.streamUrl;
      video.current.play();
    } else if (video.current) {
      video.current.pause();
    }
    return () => {

    }
  }, [file?.streamUrl, videoModalOpen, viral]);

  return (
    <Modal
      id="modal"
      aria-label="modal-headline"
      open={videoModalOpen}
      onClose={() => setVideoModalOpen(false)}
    >
      <>
        <Box className={classes.boxVideo}>
          <video
            ref={video}
            className={classes.video}
            loop
            autoPlay
            controls
          >
            <source src={file?.streamUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Box>
      </>
    </Modal>
  );
}
