import React from "react";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { Box } from "@mui/system";
import {Accordion, AccordionDetails, AccordionSummary, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IosShareIcon from '@mui/icons-material/IosShare';
import {Divider} from "@material-ui/core";

export const HowTo = (): React.ReactElement => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant={"h5"}>How to</Typography>
        </Grid>
        <Grid item xs={12} xl={8}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="tips-for-video"
            >
              <Typography>Tips for Creating a Good Viral video</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Typography variant={"h5"}>
                    Kind of Videos
                  </Typography>
                  <Typography variant={"body1"}>
                    <br/>
                    Viraldaddy is always improving its AI models but currently viraldaddy works best for adult content style videos that are considered "full-length".
                    <br/>
                    <br/>
                    Full length means videos
                    that are either 2 to 30 minutes of video. Viraldaddy also works best when there are multiple sexual activities or body parts present in the video. Currently, Viraldaddy selects what it deems are the best parts of your video and makes sure to include parts over the entirety of your video; saving you time!
                    <br/>
                    <br/>
                    At the moment the following are automatically detected and analyzed by viraldaddy:
                    <ul>
                      <li> Blowjob </li>
                      <li> Boobs </li>
                      <li> Booty </li>
                      <li> Cleavage </li>
                      <li> Cowgirl </li>
                      <li> Doggy-style (includes Anal) </li>
                      <li> Missionary (includes Anal) </li>
                      <li> Solo Female </li>
                      <li> Titty Fuck </li>
                    </ul>
                    Coming soon
                    <ul>
                      <li>Cum shot</li>
                      <li>Male Masturbation</li>
                      <li>Penis</li>
                      <li>Fingering</li>
                    </ul>
                  </Typography>

                </Grid>
                <Grid item>
                  <Typography variant={"h5"} sx={{ mb: 2 }}>Do's and Dont's for better videos</Typography>
                  <Divider />
                  <Typography variant={"h6"} sx={{ mt: 2 }}>Video Length</Typography>
                  <Box display="flex" alignItems="center" color="green" sx={{ mt: 1 }}>
                    <CheckCircleIcon />
                    <Typography sx={{ ml: 1 }}>Better: Longer videos</Typography>
                  </Box>
                  <Box display="flex" alignItems="center" color="orange" sx={{ mt: 1 }}>
                    <CancelIcon />
                    <Typography sx={{ ml: 1 }}>Ok: Short videos (Anything less than 1 minute can work but better for longer videos)</Typography>
                  </Box>
                  <Divider style={{ marginTop: 2, marginBottom: 2 }}/>
                  <Typography variant={"h6"}>Video Lighting</Typography>
                  <Box display="flex" alignItems="center" color="green" sx={{ mt: 1 }}>
                    <CheckCircleIcon />
                    <Typography sx={{ ml: 1 }}>Do: Lots of Light</Typography>
                  </Box>
                  <Box display="flex" alignItems="center" color="red" sx={{ mt: 1 }}>
                    <CancelIcon />
                    <Typography sx={{ ml: 1 }}>Don't: low lighting (iphone with a spot light is okay!)</Typography>
                  </Box>
                  <Divider style={{ marginTop: 2, marginBottom: 2 }}/>
                  <Typography variant={"h6"}>Video Content</Typography>
                  <Box display="flex" alignItems="center" color="green" sx={{ mt: 1 }}>
                    <CheckCircleIcon />
                    <Typography sx={{ ml: 1 }}>Do: Must contain pornographic or adult content</Typography>
                  </Box>
                  <Box display="flex" alignItems="center" color="red" sx={{ mt: 1 }}>
                    <CancelIcon />
                    <Typography sx={{ ml: 1 }}>Don't: Non-pornographic videos</Typography>
                  </Box>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>Downloading Videos on iOS</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Apple does not easily let users download videos to their mobile devices on iOS Safari.

                <ol>
                  <li> When you click download the browser will open the video in the browser for you to play it. </li>
                  <li> Tab the iOS share icon <IosShareIcon/> and scroll own until you see "Save Video" </li>
                  <li> Your video will now be in your Downloaded files under files on your iOS phone</li>
                </ol>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>

    </>
  )
}