import Typography from "@mui/material/Typography";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  createRedditGroup,
  getRedditImages,
  getSubreddits,
} from "../../api";
import {
  Link,
  List,
  ListItemText,
  Stack,
  TextField
} from "@mui/material";
import Box from "@mui/material/Box";
import VerifiedIcon from "@mui/icons-material/Verified";
import {
  CreateSubredditGroupBody,
  SubredditResponse,
} from "../../api/types";
import Button from "@mui/material/Button";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { queryClient } from "../../app";
import ListItem from "@mui/material/ListItem";
import LaunchIcon from "@mui/icons-material/Launch";
import Tooltip from "@mui/material/Tooltip";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import {makeStyles} from "@material-ui/core";
import SearchIcon from "@mui/icons-material/Search";
import {useDialog} from "../../components/app-dialog-provider";
import {RedditSelect} from "../../components/reddit-select";
import {useGetUser} from "../../hooks/api-hooks/useGetUser";

const useStyles = makeStyles(() => ({
  subredditLink: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none !important",
    textDecorationColor: "none",
    color: "black !important",
    backgroundColor: "#f5f5f5",
    "&:hover": {
      color: "gray !important",
    },
  },
  subredditRules: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none !important",
    textDecorationColor: "none",
    cursor: "pointer",
    marginLeft: "8px !important",
  },
}));
export const SubredditGroupsAdd = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [verifyFilter, setVerifyFilter] = useState(false);
  const [nsfwFilter, setNsfwFilter] = useState(false);
  const [subredditGroupName, setSubredditGroupName] = useState("");
  const [searchInputValue, setSearchInputValue] = useState<string | null>(null);
  const [subredditTopFilter, setSubredditTopFilter] = useState(false);
  const [subredditsForGroup, setSubreddits] = useState<SubredditResponse[]>([]);
  const [filteredSubreddits, setFilteredSubreddits] = useState<
    SubredditResponse[]
  >([]);

  const user = useGetUser()

  const { showDialog, hideDialog } = useDialog();

  const subreddits = useQuery([`getSubreddits`], () => getSubreddits(), {
    enabled: true,
  });

  const subredditGroupMutation = useMutation({
    mutationFn: (createSubredditGroup: CreateSubredditGroupBody) =>
      createRedditGroup(createSubredditGroup),
  });

  useEffect(() => {
    updateFilteredSubreddits();
  }, [searchInputValue, subreddits.data, verifyFilter, nsfwFilter, subredditTopFilter]);

  const updateFilteredSubreddits = () => {
    const lowercasedSearchInput = searchInputValue?.toLowerCase();
    let customSubAdded = false;
    if(subreddits.data){
      const filtered = subreddits.data.filter((subreddit) => {
        if (subreddit.name.toLowerCase() === lowercasedSearchInput) {
          customSubAdded = true;
        }

        // If all filters are off, show all
        if (!verifyFilter && !nsfwFilter && !subredditTopFilter) {
          return true;
        }

        let verifyCondition = verifyFilter ? !subreddit.verify : true;
        let nsfwCondition = nsfwFilter ? subreddit.over18 : true;
        let subredditTopCondition = subredditTopFilter ? subreddit.top : true;

        // Return based on the combination of the three filters
        return verifyCondition && nsfwCondition && subredditTopCondition;
      });

      if(user.data?.redditUserName){
        filtered.unshift({
          name: `u_${user.data?.redditUserName}`,
          verify: false,
          over18: null,
          top: null,
          subs: 0,
        });
      }

      if (searchInputValue && !customSubAdded) {
        filtered.unshift({
          name: searchInputValue,
          verify: false,
          over18: null,
          top: null,
          subs: 0,
        });
      }

      setFilteredSubreddits(filtered);
    }
  };

  const handleGroupNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSubredditGroupName(event.target.value);
  };

  const handleCreateSubredditGroup = async () => {
    const payload = {
      name: subredditGroupName.toString(),
      subreddits: subredditsForGroup.map((s) => s.name.toLowerCase()),
    };
    await subredditGroupMutation.mutateAsync(payload, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["subredditGroupQuery"] });
        toast.success("Group Added!");
        navigate("/groups");
      },
    });
  };

  const handleShowWhatsHot = async (subreddit: string) => {
    showDialog({
      title: `Trending examples from ${subreddit}`,
      actions: [
        {
          label: "Confirm",
          onClick: () => {
            hideDialog();
          },
        },
      ],
      fetchData: async () => {
        const images = await getRedditImages(subreddit);
        if (!images || !images.data.children.length) {
          return (
            <>
              <SearchIcon /> No rules found
            </>
          );
        }
        return images.data.children.map((subreddit) => {
          return (
            <>
              <img src={subreddit.data.thumbnail} alt={"reddit thumbnail"} />
            </>
          );
        });
      },
    });
  };

  return (
    <>
      <Typography variant={"h5"}>Add Group</Typography>
      <Box mt={2}>
        <TextField
          size={"small"}
          label="Subreddit Group Name"
          helperText={"Create a name for your group to help you organize"}
          placeholder="Example: Non Nude"
          onChange={handleGroupNameChange}
        />
      </Box>
      <Box mt={2}>
        <RedditSelect
          nsfwFilter={nsfwFilter}
          setNsfwFilter={setNsfwFilter}
          verifyFilter={verifyFilter}
          setVerifyFilter={setVerifyFilter}
          subreddits={filteredSubreddits}
          setFilteredSubreddits={setFilteredSubreddits}
          searchInputValue={searchInputValue}
          selectedSubreddits={subredditsForGroup}
          setSelectedSubreddits={setSubreddits}
          updateFilteredSubreddits={updateFilteredSubreddits}
          setSubredditsTopFilter={setSubredditTopFilter}
          subredditsTopFilter={subredditTopFilter}
          setSearchInputValue={setSearchInputValue}
        />
      </Box>
      <Box>
        <Typography
          mb={1}
          mt={3}
          variant={"body1"}
          sx={{ fontSize: "1.2em", color: "gray" }}
        >
          Review your subreddits
        </Typography>
        <List>
          {subredditsForGroup.map((subreddit) => {
            return (
              <ListItem>
                <ListItemText primary={
                  <Stack
                    direction={"row"}
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Typography>
                      <b>
                        <Link
                          target={'_blank'}
                          className={classes.subredditLink}
                          href={`https://reddit.com/r/${subreddit.name}`}
                        >
                          r/{subreddit.name}
                          <LaunchIcon
                            style={{ marginLeft: 8 }}
                            target={"_blank"}
                            fontSize={"small"}
                            color={"action"}
                          />
                        </Link>
                      </b>
                    </Typography>
                    <Tooltip title={subreddit.verify ? 'Verify' : 'No Verify'}>
                      {subreddit.verify ?  <VerifiedIcon color={'primary'} />
                        : <VerifiedOutlinedIcon />
                      }
                    </Tooltip>
                    <Typography>
                      <Link
                        onClick={() => handleShowWhatsHot(subreddit.name)}
                        className={classes.subredditRules}
                      >
                        Trending <WhatshotIcon />
                      </Link>
                    </Typography>
                  </Stack>
                } />
              </ListItem>
            );
          })}
        </List>
      </Box>
      <Box mt={2}>
        <Button variant={"contained"} onClick={handleCreateSubredditGroup}>
          Save
        </Button>
      </Box>
    </>
  );
};
