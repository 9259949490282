import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';

import Login from './views/login';
import AppHome from './views/app-home/app-home';
import ProtectedRoute from './components/protected-route';
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import WaitList from "./views/wait-list";


export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: Infinity
    }
  }
})
const App = () => (
  <>
    <CssBaseline />
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/waitlist" element={<WaitList />} />
          <Route
            path="/*"
            element={(
              <ProtectedRoute>
                <AppHome />
              </ProtectedRoute>
            )}
          />
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  </>
);

export default App;
