import * as React from "react";
import {useEffect, useRef, useState} from "react";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import {CreatePostBody, CreatePostResponse, PostScheduleBody, ViralResponse} from "../../api/types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {ThumbnailRow} from "../create-post/components/thumbnail-row";
import {useMutation, useQuery} from "@tanstack/react-query";
import {createPost, getViral, scheduleTweets} from "../../api";
import {useDialog} from "../../components/app-dialog-provider";
import {
  Alert,
  Backdrop,
  capitalize,
  CircularProgress,
  LinearProgress,
  List,
  ListItemText,
  Typography
} from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";
import useMediaQuery from "@mui/material/useMediaQuery";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {queryClient} from "../../app";
import {Link, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useTheme} from "@material-ui/core/styles";
import ListItem from "@mui/material/ListItem";
import {useGetUser} from "../../hooks/api-hooks/useGetUser";
import {PostScheduleBuilder} from "../create-post/craete-post-builder";
import {QontoConnector, QontoStepIcon} from "../../components/connector-bar";
import {TweetRequestProvider, useTweetRequestContext} from "./components/tweet-request-provider";
import {UploadComponent} from "./components/upload-component";
import {MemoizedReviewComponentTwitter} from "./components/review-component-twitter";

const steps = ["Upload", "Review"];

function CreateTweetWrapper() {
  const [searchParams, setSearchParams] = useSearchParams();
  const tweetContext = useTweetRequestContext();
  const { step } = useParams();
  const theme = useTheme();
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [isUploadingFiles, setIsUploadingFiles] = useState<boolean>(false);
  const [isFinalizingPost] = useState<boolean>(false);
  const scheduleTweetsMutation = useMutation( {mutationFn: (createPostBody: CreatePostBody[]) => scheduleTweets(createPostBody)})
  const { showDialog, hideDialog } = useDialog();
  const navigate = useNavigate();

  const initialStep = step ? steps.indexOf(capitalize(step)) : 0;
  const [activeStep, setActiveStep] = React.useState(initialStep === -1 ? 0 : initialStep);

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const user = useGetUser();

  const viralId = searchParams.get('viralId')
  const viral = useQuery<ViralResponse>( [`getViral`, viralId], () => getViral(viralId || undefined),
    {enabled: !!viralId, staleTime: 0}
  );

  const addedToPostContextRef = useRef(false);

  useEffect(() => {
    async function downloadURLtoBlob(url: string) {
      // Fetch the content from the URL
      const response = await fetch(url);

      // Check if the response was successful
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      // Convert the response to a blob
      // Return the blob
      return await response.blob();
    }

    function blobToFile(theBlob: Blob, fileName: string): File {
      return new File([theBlob], fileName, {
        type: theBlob.type,
        lastModified: Date.now()
      });
    }

    if (!addedToPostContextRef.current && viral.data && viralId) {
      const video = viral.data.viralFiles.find(vf => vf.type === 'final');
      if (video?.streamUrl) {
        downloadURLtoBlob(video.streamUrl).then((blob) => {
          const file = blobToFile(blob, "viral-file" + viralId);
          const posts: CreatePostBody[] = [];
          const builder = new PostScheduleBuilder();
          const post = builder
            .setLink(null)
            .setFile(true)
            .setObjectFile(file)
            .setExtension(blob.type);
          posts.push(post.build());

          tweetContext.setTweetRequestContext([...tweetContext.tweetRequestContext, ...posts]);

          // Mark the post as added
          addedToPostContextRef.current = true;
        });
      }
    }
  }, [viral.data, viralId, tweetContext]);

  useEffect(() => {
    if (!user.isLoading && user.data) {
      const { isTwitterLinked, isTwitterV1Linked } = user.data;

      if (!isTwitterLinked || !isTwitterV1Linked) {
        showDialog({
          title: 'You must link your accounts before posting',
          content: (
            <>
              <List>
                {!isTwitterLinked && <ListItem><ListItemText>- Twitter Account</ListItemText></ListItem>}
                {!isTwitterV1Linked && <ListItem><ListItemText>- Twitter V1 Media</ListItemText></ListItem>}
              </List>
              <Typography>
                Please head over to <Link to="settings/checklist">settings and link your accounts</Link>.
              </Typography>
            </>
          ),
          actions: [
            { label: 'Confirm', onClick: () => hideDialog() },
          ],
        });
      }
    }
  }, []);


  useEffect(() => {
    // If step is undefined, default to the first step
    const newActiveStep = step ? steps.indexOf(capitalize(step)) : 0;
    if (newActiveStep !== -1) {
      setActiveStep(newActiveStep);
    }
  }, [step]);
  const uploadFilesForPosts = async (createPostResponse: CreatePostResponse[]) => {
    // Initialize a counter variable to keep track of the total files uploaded
    let lastUpdateTime = Date.now();
    const throttleTime = 0;

    for (let index = 0; index < createPostResponse.length; index++) {
      const post = createPostResponse[index];

      await new Promise<void>((resolve, reject) => {
        if (!post.uploadUrl || !post.fileId || !post.contentType) {
          resolve();
          return;
        }

        if (!tweetContext.tweetRequestContext[index].objectFile?.size) {
          resolve();
          return;
        }

        const xhr = new XMLHttpRequest();
        xhr.open("PUT", post.uploadUrl, true);

        xhr.setRequestHeader("Content-Type", post.contentType);
        xhr.setRequestHeader("x-goog-meta-post-id", post.postId);
        xhr.setRequestHeader("x-goog-meta-file-id", post.fileId);
        xhr.setRequestHeader("x-goog-meta-user-id",  user.data ? user.data.id : 'missing' );

        xhr.upload.onprogress = (event: ProgressEvent) => {
          if (event.lengthComputable) {
            const now = Date.now();
            if (now - lastUpdateTime > throttleTime) {
              lastUpdateTime = now; // Update the last update time
              const bytesUploaded = event.loaded;
              const totalBytes = event.total;
              const progress = Math.floor((bytesUploaded / totalBytes) * 100);
              setUploadProgress(progress);
            }
          }
        };

        xhr.onload = () => {
          if (xhr.status >= 200 && xhr.status < 300) {
            setUploadProgress(0)
            resolve();
          } else {
            reject(new Error("Upload failed with status " + xhr.status));
          }
        };

        xhr.onerror = () => {
          reject(new Error("Upload failed with network error"));
        };

        xhr.send(tweetContext.tweetRequestContext[index].objectFile);
      });
    }
  };

  const handleNext = () => {
    confirmSchedule();
  };

  const confirmSchedule = () => {
    showDialog({
      title: `Ready to schedule?`,
      content: "Once you confirm we will schedule all your posts",
      actions: [
        {label: 'schedule', onClick: async () => await handleSchedule()},
        {label: 'cancel', onClick: () => hideDialog()}
      ],
    });
  }
  const handleSchedule = async () => {
    const [missingContent, missingTitles] = isScheduleDisabled()
    let isTwitterLinked: boolean = false;
    let isTwitterV1Linked: boolean = false;

    if (!user.isLoading && user.data) {
      ({ isTwitterLinked, isTwitterV1Linked } = user.data);
    }

    if (missingContent || missingTitles || !isTwitterLinked || !isTwitterV1Linked) {
      showDialog({
        title: `Missing required inputs`,
        content:
          <List>
            {missingContent ?
              <ListItem hidden={!missingContent} disablePadding>
                <ListItemText primary={'Please upload content'} />
              </ListItem> : null }

            {missingTitles ?
              <ListItem disablePadding>
                <ListItemText primary={'Please add titles to all subreddit posts'} />
              </ListItem> : null }

            {!isTwitterLinked ?
              <ListItem disablePadding>
                <ListItemText primary={'Please link your twitter account'} />
              </ListItem> : null }

            {!isTwitterV1Linked ?
              <ListItem disablePadding>
                <ListItemText primary={'Please link your twitter v1 media account'} />
              </ListItem> : null }

          </List>,
        actions: [
          {label: 'confirm', onClick: () => hideDialog()}
        ],
      });
      return;
    }
    hideDialog()
    setIsUploadingFiles(true);
    try{
      const createPostResponse = await scheduleTweetsMutation.mutateAsync(tweetContext.tweetRequestContext);
      await uploadFilesForPosts(createPostResponse);
      setIsUploadingFiles(false);
      await queryClient.invalidateQueries({queryKey: ['getPosts']})
      navigate('/posts?tab=twitter')
    }catch(e){
      setIsUploadingFiles(false);
    }
  }

  const handleStepClick = (step: string) => {
    navigate(`/tweets/new/${step.toLowerCase()}`);
  };

  const isScheduleDisabled = () => {
    const missingContent = tweetContext.tweetRequestContext.length < 1;
    const tweetPostSchedules: PostScheduleBody[] = [];
    tweetContext.tweetRequestContext.forEach(post => {
      const tweetPosts = post.postSchedule.filter(ps => ps.platformId === 2);
      tweetPosts.forEach((p) => {
        tweetPostSchedules.push(p);
      })
    });
    // Add or missing twitter body
    const missingTitles = tweetPostSchedules.some((ps) => !ps.metaData.twitter?.body || ps.metaData.twitter?.body === '' )
    return  [missingContent, missingTitles]
  }

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={viral.isFetching && !viral.data}
      >
        <Stack direction={'column'} justifyContent={'center'} alignItems={'center'}>
          <CircularProgress color="inherit" />
          <Typography>
            Loading Viral
          </Typography>
        </Stack>
      </Backdrop>
      <Dialog open={isFinalizingPost} fullWidth={true} maxWidth={"sm"}>
        <DialogTitle>Finalizing posts</DialogTitle>
        <DialogContent>
          <LinearProgress
            color={"secondary"}
          />
          <br />
          <DialogContentText>
            <Alert severity="warning">
              This could take a minute. Uploading to redgifs.
              <b> Please don't close this window or navigate away.</b></Alert>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog open={isUploadingFiles} fullWidth={true} maxWidth={"sm"}>
        <DialogTitle>Uploading number of files: {tweetContext.tweetRequestContext.filter((p) => p.file).length}</DialogTitle>
        <DialogContent>
          <LinearProgress
            variant="determinate"
            color={"secondary"}
            value={uploadProgress}
          />
          <br />
          <DialogContentText>
            This should only take a minute, please don't close the browser while the
            video is uploading
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Stack spacing={4}>
        <Stepper activeStep={activeStep} connector={<QontoConnector />}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel
                onClick={() => handleStepClick(label)}
                sx={{
                  ".MuiStepLabel-labelContainer span": {
                    fontSize: isSmallScreen ? "1.5em" : "2em", // Conditionally set the font size
                    cursor: "pointer",
                    "&:hover": {
                      textDecoration: "underline",
                      textDecorationThickness: "3px",
                      textDecorationColor: "#784af4",
                    },
                  },
                }}
                StepIconComponent={QontoStepIcon}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Stack>
      <Box>
        <ThumbnailRow postRequestContext={tweetContext.tweetRequestContext} setPostRequestContext={tweetContext.setTweetRequestContext} />
      </Box>
      <Box mt={2}>
        <Button variant={'contained'}  sx={{ textTransform: 'none' }} disableElevation>
          @{user.data?.twitterUserName}
        </Button>
      </Box>
      <Box>
        {step === 'upload' && <UploadComponent />}
        {step === 'review' && <MemoizedReviewComponentTwitter />}
      </Box>
      { step === 'review' ? <Button style={{marginTop: 15}} variant={"contained"} onClick={handleNext}>
        Schedule
      </Button> : null}
    </>
  )
}
export function CreateTweet() {
  return (
    <TweetRequestProvider>
      <CreateTweetWrapper />
    </TweetRequestProvider>
  );
}
