import * as React from "react";
import { createContext, ReactNode, useContext, useState } from "react";
import { CreatePostBody } from "../../../api/types";

// Define the type for the post request context
type TweetRequestContextType = {
  tweetRequestContext: CreatePostBody[];
  setTweetRequestContext: React.Dispatch<React.SetStateAction<CreatePostBody[]>>;

};

export const TweetRequestContext = createContext<
  TweetRequestContextType | undefined
>(undefined);

// Provider to share state

// Custom hook to use TweetRequestContext
export const useTweetRequestContext = () => {
  const context = useContext(TweetRequestContext);
  if (!context) {
    throw new Error(
      "useTweetRequestContext must be used within a PostRequestProvider"
    );
  }
  return context;
};

export const TweetRequestProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [tweetRequestContext, setTweetRequestContext] = useState<
    CreatePostBody[]
  >([]);

  return (
    <TweetRequestContext.Provider
      value={{
        tweetRequestContext,
        setTweetRequestContext,
      }}
    >
      {children}
    </TweetRequestContext.Provider>
  );
};
