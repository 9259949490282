import {SubredditTrendsResponse} from "../../api/types";

export const calculateAveragePosts = (trends:  SubredditTrendsResponse[]): number => {
  const sum = trends.reduce((previousValue, currentValue, currentIndex, array) => {
    return previousValue + currentValue.numberOfPosts
  }, 0)
  return Math.round(sum / trends.length)
}

export const calculateAverageVideoImageRatio = (users: SubredditTrendsResponse[] | undefined): number => {
  if(!users){
    return 0
  }
  let totalVideos = 0;
  let totalImages = 0;

  users.forEach(user => {
    totalVideos += user.numberOfVideos;
    totalImages += user.numberOfImages;
  });

  // Calculate the average ratios
  const avgVideos = Math.floor(totalVideos / users.length);
  const avgImages = Math.floor(totalImages / users.length);

  return Math.round((avgVideos / avgImages));
}

export const calculateAverageMedia = (users: SubredditTrendsResponse[] | undefined): [number,number] => {
  if(!users){
    return [0,0]
  }
  let totalVideos = 0;
  let totalImages = 0;

  users.forEach(user => {
    totalVideos += user.numberOfVideos;
    totalImages += user.numberOfImages;
  });

  // Calculate the average ratios
  const avgVideos = Math.floor(totalVideos / users.length);
  const avgImages = Math.floor(totalImages / users.length);

  return [avgImages, avgVideos]
}
