import axios, { AxiosError } from "axios";
import toast from "react-hot-toast";

import {
  CreateViral,
  FileResponse,
  QueryVirals,
  PlansResponse,
  UserBody,
  UserResponse,
  ViralResponse,
  WatermarkFinalizeBody,
  WatermarkUploadResponse,
  CreatePostBody,
  SubredditResponse,
  RedditData,
  PostResponse,
  CreatePostResponse,
  QueryPostResponse,
  SubredditRulesResponse,
  UpdatePostScheduleBody,
  SubredditGroupResponse,
  CreateSubredditGroupBody,
  UserStatisticsResponse, SubredditTrendsResponse,
} from "./types";

const VIRALS_ROUTE = `/api/virals`;
const POSTS_ROUTE = `/api/posts`;
const REDDIT_ROUTE = `/api/reddit`;
const SUBREDDIT_GROUP_ROUTE = `/api/subreddit-groups`;
const FILES_ROUTE = `/api/files`;
const USERS_ROUTE = `/api/users`;
const CAPTIONS_ROUTE = `/api/captions`;
const PLANS_ROUTE = `/api/plans`;

export interface CreateViralResponse {
  viral: {
    id: string;
    status: "processing" | "complete";
  };
  files: {
    uploadUrl: string;
    fileId: string;
  }[];
}

export interface SubmitViralToRedditBody {
  viralId: string;
  provider: string;
  title: string;
}

export interface SubmitViralToTwitterBody {
  viralId: string;
  tweet: string;
}

export interface SubmitViralToRedgifsBody {
  viralId: string;
  tags: string[];
}

function formatError(axios: AxiosError) {
  if (!axios) toast("Please contact support");
  // @ts-ignore
  if (axios?.response?.status >= 400 && axios?.response?.status < 500) {
    // @ts-ignore
    toast.error(axios?.response?.data?.message);
  } else {
    toast.error(axios.message);
  }
}

export const createViral = async (
  body: CreateViral
): Promise<CreateViralResponse> => {
  try {
    const response = await axios.post(VIRALS_ROUTE, body);
    return response.data;
  } catch (err) {
    // @ts-ignore
    const message =  err?.response?.data?.message ? err?.response?.data?.message : err.message
    // @ts-ignore
    toast.error(message);
    // @ts-ignore
    throw new Error(message);
  }
};

export const processViral = async (viralId: string): Promise<void> => {
  try {
    const response = await axios.post(`${VIRALS_ROUTE}/${viralId}/process`);
    return response.data;
  } catch (err) {
    // @ts-ignore
    toast.error(err.message);
    // @ts-ignore
    throw new Error(err.message);
  }
};

export const getVirals = async (
  limit: number,
  offset: number | null,
  query: string | null
): Promise<QueryVirals> => {
  let url = VIRALS_ROUTE;
  try {
    let config = {
      params: {
        limit,
        offset,
      },
    };

    if (query) {
      url = `${VIRALS_ROUTE}?${query}`;
      // @ts-ignore
      config = {};
    }

    const response = await axios.get(url, config);
    return response.data;
  } catch (err) {
    // @ts-ignore
    toast.error(err.message);
    // @ts-ignore
    throw new Error(err.message);
  }
};

export const getViral = async (
  viralId: string | undefined
): Promise<ViralResponse> => {
  try {
    const response = await axios.get(`${VIRALS_ROUTE}/${viralId}`);
    return response.data;
  } catch (err) {
    // @ts-ignore
    toast.error(err.message);
    // @ts-ignore
    throw new Error(err.message);
  }
};

export const downloadViral = async (
  viralId: string | undefined
): Promise<string> => {
  try {
    const response = await axios.get(`${VIRALS_ROUTE}/${viralId}/download`);
    return response.data;
  } catch (err) {
    // @ts-ignore
    toast.error(err.message);
    // @ts-ignore
    throw new Error(err.message);
  }
};

export const getFile = async (fileId: string): Promise<FileResponse> => {
  try {
    const response = await axios.get(`${FILES_ROUTE}/${fileId}`);
    return response.data;
  } catch (err) {
    // @ts-ignore
    toast.error(err.message);
    // @ts-ignore
    throw new Error(err.message);
  }
};

export const deleteViral = async (viralId: string): Promise<void> => {
  try {
    await axios.delete(`${VIRALS_ROUTE}/${viralId}`);
  } catch (err) {
    // @ts-ignore
    toast.error(err.message);
    // @ts-ignore
    throw new Error(err.message);
  }
};

export const getUser = async (): Promise<UserResponse> => {
  try {
    const response = await axios.get(`${USERS_ROUTE}/me`);
    return response.data;
  } catch (err) {
    // @ts-ignore
    toast.error(err.message);
    // @ts-ignore
    throw new Error(err.message);
  }
};

export const getStatistics = async (): Promise<UserStatisticsResponse> => {
  try {
    const response = await axios.get(`${USERS_ROUTE}/me/statistics`);
    return response.data;
  } catch (err) {
    // formatError(err as unknown as AxiosError);
    // @ts-ignore
    throw new Error(err.message);
  }
};

export const updateUser = async (body: UserBody): Promise<UserResponse> => {
  try {
    const response = await axios.patch(`${USERS_ROUTE}/me`, { ...body });
    return response.data;
  } catch (err: unknown) {
    formatError(err as AxiosError);
    // @ts-ignore
    throw new Error(err.message);
  }
};

export const uploadWatermark = async (): Promise<WatermarkUploadResponse> => {
  try {
    const response = await axios.post(`${USERS_ROUTE}/watermarks/upload`);
    return response.data;
  } catch (err) {
    // @ts-ignore
    toast.error(err.message);
    // @ts-ignore
    throw new Error(err.message);
  }
};

export const finalizeWatermark = async ({
  fileId,
}: WatermarkFinalizeBody): Promise<UserResponse> => {
  try {
    const response = await axios.post(`${USERS_ROUTE}/watermarks/finalize`, {
      fileId,
    });
    return response.data;
  } catch (err) {
    // @ts-ignore
    toast.error(err.message);
    // @ts-ignore
    throw new Error(err.message);
  }
};

export const getCaptions = async (type: string): Promise<string[]> => {
  try {
    const response = await axios.get(`${CAPTIONS_ROUTE}?type=${type}`);
    return response.data;
  } catch (err) {
    // @ts-ignore
    toast.error(err.message);
    // @ts-ignore
    throw new Error(err.message);
  }
};

export const activateBeta = async (code: number): Promise<void> => {
  try {
    const response = await axios.post(`${USERS_ROUTE}/me/activate-beta`, {
      code,
    });
    return response.data;
  } catch (err) {
    // @ts-ignore
    toast.error(err.message);
    // @ts-ignore
    throw new Error(err.message);
  }
};

export const submitViralToReddit = async ({
  viralId,
  provider,
  title,
}: SubmitViralToRedditBody): Promise<string[]> => {
  try {
    const response = await axios.post(`${VIRALS_ROUTE}/${viralId}/submit`, {
      provider,
      title,
    });
    return response.data;
  } catch (err) {
    // @ts-ignore
    toast.error(err.message);
    // @ts-ignore
    throw new Error(err.message);
  }
};

export const submitViralToTwitter = async ({
  viralId,
  tweet,
}: SubmitViralToTwitterBody): Promise<string[]> => {
  try {
    const response = await axios.post(
      `${VIRALS_ROUTE}/${viralId}/submit/twitter`,
      {
        tweet,
      }
    );
    return response.data;
  } catch (err) {
    formatError(err as unknown as AxiosError);
    // @ts-ignore
    throw new Error(err.message);
  }
};

export const submitViralToRedgifs = async ({
  viralId,
  tags,
}: SubmitViralToRedgifsBody): Promise<string[]> => {
  try {
    const response = await axios.post(
      `${VIRALS_ROUTE}/${viralId}/submit/redgifs`,
      {
        tags,
      }
    );
    return response.data;
  } catch (err) {
    formatError(err as unknown as AxiosError);
    // @ts-ignore
    throw new Error(err.message);
  }
};

export const getPlans = async (): Promise<PlansResponse[]> => {
  try {
    const response = await axios.get(`${PLANS_ROUTE}`);
    return response.data;
  } catch (err) {
    // @ts-ignore
    toast.error(err.message);
    // @ts-ignore
    throw new Error(err.message);
  }
};

export const createCheckoutSession = async (
  subscriptionId: number
): Promise<{ url: string }> => {
  try {
    const response = await axios.post(
      `${PLANS_ROUTE}/create-checkout-session`,
      { subscriptionId }
    );
    return response.data;
  } catch (err) {
    formatError(err as unknown as AxiosError);
    // @ts-ignore
    throw new Error(err.message);
  }
};

export const cancelSubscription = async (): Promise<void> => {
  try {
    const response = await axios.post(`${PLANS_ROUTE}/cancel`, {});
    return response.data;
  } catch (err) {
    formatError(err as unknown as AxiosError);
    // @ts-ignore
    throw new Error(err.message);
  }
};

export const createPost = async (createPost: CreatePostBody[]): Promise<CreatePostResponse[]> => {
  try {
    const response = await axios.post(`${POSTS_ROUTE}/reddit`, createPost);
    return response.data;
  } catch (err) {
    formatError(err as unknown as AxiosError);
    // @ts-ignore
    throw new Error(err.message);
  }
};

export const scheduleTweets = async (createPost: CreatePostBody[]): Promise<CreatePostResponse[]> => {
  try {
    const response = await axios.post(`${POSTS_ROUTE}/twitter`, createPost);
    return response.data;
  } catch (err) {
    formatError(err as unknown as AxiosError);
    // @ts-ignore
    throw new Error(err.message);
  }
};

export const finalizePost = async (postIds: string[]): Promise<void> => {
  try {
    const response = await axios.post(`${POSTS_ROUTE}/finalize`, postIds);
    return response.data;
  } catch (err) {
    formatError(err as unknown as AxiosError);
    // @ts-ignore
    throw new Error(err.message);
  }
};


export const getPosts = async (
  platformId: number,
  limit: number = 20,
  offset: number | null = 0,
  status: string = 'scheduled',
  orderBy: string = 'scheduledAt:ASC',
): Promise<QueryPostResponse> => {
  try {
    let url = POSTS_ROUTE;
    let config = {
          params: {
            limit,
            platformId,
            offset,
            status,
            orderBy
          },
        };
    const response = await axios.get(url, config);
    return response.data;
  } catch (err) {
    formatError(err as unknown as AxiosError);
    // @ts-ignore
    throw new Error(err.message);
  }
};

export const updatePostSchedule = async (
  postId: string,
  postScheduleId: string,
  patchBody: Partial<UpdatePostScheduleBody>
): Promise<Partial<PostResponse>> => {
  try {
    const response = await axios.patch(`${POSTS_ROUTE}/${postId}/schedules/${postScheduleId}`, patchBody);
    return response.data;
  } catch (err) {
    formatError(err as unknown as AxiosError);
    // @ts-ignore
    throw new Error(err.message);
  }
};

export const getSubreddits = async (): Promise<SubredditResponse[]> => {
  try {
    const response = await axios.get(`${REDDIT_ROUTE}/subreddits`);
    return response.data;
  } catch (err) {
    formatError(err as unknown as AxiosError);
    // @ts-ignore
    throw new Error(err.message);
  }
};

export const getMyTopSubreddits = async (): Promise<string[]> => {
  try {
    const response = await axios.get(`${REDDIT_ROUTE}/submissions/me`);
    return response.data;
  } catch (err) {
    formatError(err as unknown as AxiosError);
    // @ts-ignore
    throw new Error(err.message);
  }
};

export const getRedditTitles = async (titles: string[]): Promise<Record<string, string[]>> => {
  try {
    const titlesQueryParam = titles.join(',');
    const response = await axios.get(`${REDDIT_ROUTE}/subreddits/titles`, {
      params: {
        subreddits: titlesQueryParam
      }
    });
    return response.data;
  } catch (err) {
    formatError(err as unknown as AxiosError);
    // @ts-ignore
    throw new Error(err.message);
  }
};

export const getRedditFlair = async (subreddits: string[]): Promise<Record<string, {flairId: string, flairText: string }[]>> => {
  try {
    const joinedSubreddits = subreddits.join(',');
    const response = await axios.get(`${REDDIT_ROUTE}/subreddits/flairs`, {
      params: {
        subreddits: joinedSubreddits
      }
    });
    return response.data;
  } catch (err) {
    formatError(err as unknown as AxiosError);
    // @ts-ignore
    throw new Error(err.message);
  }
};

export const getRedditRules = async (subreddit: string): Promise<SubredditRulesResponse[]> => {
  try {
    const response = await axios.get(`${REDDIT_ROUTE}/subreddits/${subreddit}/rules`);
    return response.data;
  } catch (err) {
    formatError(err as unknown as AxiosError);
    // @ts-ignore
    throw new Error(err.message);
  }
};

export const getRedditImages = async (subreddit: string, limit: number = 10): Promise<RedditData> => {
  try {
    const response = await axios.get(`https://www.reddit.com/r/${subreddit}/top.json?limit=${limit}&t=month`);
    return response.data;
  } catch (err) {
    formatError(err as unknown as AxiosError);
    // @ts-ignore
    throw new Error(err.message);
  }
};

export const queryGroups = async (): Promise<SubredditGroupResponse[]> => {
  try {
    const response = await axios.get(`${SUBREDDIT_GROUP_ROUTE}/`);
    return response.data;
  } catch (err) {
    formatError(err as unknown as AxiosError);
    // @ts-ignore
    throw new Error(err.message);
  }
};

export const getRedditGroup = async (groupId: string): Promise<SubredditGroupResponse> => {
  try {
    const response = await axios.get(`${SUBREDDIT_GROUP_ROUTE}/${groupId}`);
    return response.data;
  } catch (err) {
    formatError(err as unknown as AxiosError);
    // @ts-ignore
    throw new Error(err.message);
  }
};

export const createRedditGroup = async (createGroupBody: CreateSubredditGroupBody): Promise<SubredditGroupResponse> => {
  try {
    const response = await axios.post(`${SUBREDDIT_GROUP_ROUTE}`, createGroupBody);
    return response.data;
  } catch (err) {
    formatError(err as unknown as AxiosError);
    // @ts-ignore
    throw new Error(err.message);
  }
};

export const updateSubredditGroup = async (groupId: string, updateSubredditGroup: CreateSubredditGroupBody): Promise<SubredditGroupResponse> => {
  try {
    const response = await axios.put(`${SUBREDDIT_GROUP_ROUTE}/${groupId}`, updateSubredditGroup);
    return response.data;
  } catch (err) {
    formatError(err as unknown as AxiosError);
    // @ts-ignore
    throw new Error(err.message);
  }
};

export const deleteSubredditGroup = async (groupId: string): Promise<SubredditGroupResponse> => {
  try {
    const response = await axios.delete(`${SUBREDDIT_GROUP_ROUTE}/${groupId}`);
    return response.data;
  } catch (err) {
    formatError(err as unknown as AxiosError);
    // @ts-ignore
    throw new Error(err.message);
  }
};

export const getTrends = async (): Promise<SubredditTrendsResponse[]> => {
  try {
    const response = await axios.get(`${REDDIT_ROUTE}/trends`);
    return response.data;
  } catch (err) {
    formatError(err as unknown as AxiosError);
    // @ts-ignore
    throw new Error(err.message);
  }
}

export const getTrend = async (userName: string): Promise<string[]> => {
  try {
    const response = await axios.get(`${REDDIT_ROUTE}/trends/${userName}`);
    return response.data;
  } catch (err) {
    formatError(err as unknown as AxiosError);
    // @ts-ignore
    throw new Error(err.message);
  }
}