import React, { createContext, useContext, useState, ReactNode, useEffect } from "react";
import { CustomDialog, CustomDialogProps } from "./app-dialog";

interface DialogContextProps {
  showDialog: (props: Omit<CustomDialogProps, "open" | "onClose">) => void;
  hideDialog: () => void;
}

const DialogContext = createContext<DialogContextProps | undefined>(undefined);

interface DialogProviderProps {
  children: ReactNode;
}

const DialogProvider: React.FC<DialogProviderProps> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [dialogProps, setDialogProps] = useState<
    Omit<CustomDialogProps, "open" | "onClose">
  >({ title: "",  actions: [] });

  const showDialog = (props: Omit<CustomDialogProps, "open" | "onClose">) => {
    setDialogProps(props);
    setOpen(true);
  };

  const hideDialog = () => {
    setOpen(false);
  };

  return (
    <DialogContext.Provider value={{ showDialog, hideDialog }}>
      {children}
      <CustomDialog {...dialogProps} open={open} onClose={hideDialog} />
    </DialogContext.Provider>
  );
};

const useDialog = (): DialogContextProps => {
  const context = useContext(DialogContext);
  if (!context) {
    throw new Error("useDialog must be used within a DialogProvider");
  }
  return context;
};

export { DialogProvider, useDialog };
