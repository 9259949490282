export function timeAgo(dateString: string) {
  const date = new Date(dateString);
  // @ts-ignore
  const seconds = Math.floor((new Date() - date) / 1000);

  let interval = Math.floor(seconds / 31536000);
  if (interval >= 1) {
    return interval + " year" + (interval === 1 ? "" : "s") + " ago";
  }

  interval = Math.floor(seconds / 2592000);
  if (interval >= 1) {
    return interval + " month" + (interval === 1 ? "" : "s") + " ago";
  }

  interval = Math.floor(seconds / 86400);
  if (interval >= 1) {
    return interval + " day" + (interval === 1 ? "" : "s") + " ago";
  }

  interval = Math.floor(seconds / 3600);
  if (interval >= 1) {
    return interval + " hour" + (interval === 1 ? "" : "s") + " ago";
  }

  interval = Math.floor(seconds / 60);
  if (interval >= 1) {
    return interval + " minute" + (interval === 1 ? "" : "s") + " ago";
  }

  return Math.floor(seconds) + " second" + (seconds === 1 ? "" : "s") + " ago";
}

export function formatSubs(number: number): string {
  if (number < 1000) {
    return number.toString();
  } else if (number < 1000000) {
    return (number / 1000).toFixed(0) + "K";
  } else {
    return (number / 1000000).toFixed(1) + "M";
  }
}

export const transformUrl = (url: string) => {
  let localUrl = url;
  if(localUrl.includes('i.redgifs.com')){
    let id = localUrl.split('/')[localUrl.split('/').length - 1].split('.')[0];
    localUrl = `https://www.redgifs.com/watch/${id}`
  }
  return localUrl.replace("/watch/", "/ifr/");
};

export const formatDate = (dateString: string | null) => {
  if (!dateString) return null;
  const utcDate = new Date(dateString);
  return utcDate.toLocaleString();
};
export function parseQueryStringIntoPagination(query: string | null, defaultLimit: number = 0, defaultOffset: number = 0): { limit: number, offset: number } {
  if (query === null) {
    return { limit: defaultLimit, offset: defaultOffset };
  }

  const params = new URLSearchParams(query);

  const limitString = params.get('limit');
  const offsetString = params.get('offset');

  let limit = limitString !== null ? parseInt(limitString, 10) : defaultLimit;
  let offset = offsetString !== null ? parseInt(offsetString, 10) : defaultOffset;

  if (isNaN(limit) || isNaN(offset)) {
    throw new Error("Invalid parameters");
  }

  return { limit, offset };
}

export function getTimeZone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export function debounce<F extends (...args: any[]) => any>(func: F, wait: number): (...args: Parameters<F>) => void {
  let timeout: NodeJS.Timeout | null;

  return function executedFunction(...args: Parameters<F>) {
    const later = () => {
      if (timeout) {
        clearTimeout(timeout);
      }
      func(...args);
    };

    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(later, wait);
  };
}