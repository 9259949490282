import React, {FC, useEffect, useLayoutEffect, useState} from "react";
import { CreatePostBody } from "../../../api/types";
import Box from "@mui/material/Box";
import {transformUrl} from "../../../utils";

const THUMBNAIL_SIZE = 160;

interface RenderMediaProps {
  post: CreatePostBody;
}

// @ts-ignore
const BorderPostMedia = ({ children }) => {
  return (
    <Box
      sx={{
        margin: "0 1em 1em 0",
        position: "relative",
        width: `${THUMBNAIL_SIZE}px`,
        height: `${THUMBNAIL_SIZE}px`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {children}
    </Box>
  );
};

const RenderCreatePostMedia: FC<RenderMediaProps> = ({ post }) => {
  const [mediaType, setMediaType] = useState<'image' | 'video' | 'redgifs' | null>(null);

  useEffect(() => {
    if (post.link) {
      const extension = post.link.split('.').pop()?.toLowerCase();
      if (post.link.includes('redgifs')) {
        setMediaType('redgifs')
      } else if (['png', 'jpg', 'jpeg', 'gif', 'webp'].includes(extension || '')) {
        setMediaType('image');
      } else if (['mp4', 'webm', '.mov'].includes(extension || '')) {
        setMediaType('video');
      }
      // Add more media types as necessary
    }
  }, [post.link]);

  if (post.link && mediaType) {
    return (
      <BorderPostMedia>
        {mediaType === 'image' && (
          <img
            style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "contain" }}
            src={post.link}
            alt="link for media"
          />
        )}
        {mediaType === 'video' && (
          <video controls style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "contain" }}>
            <source src={post.link} />
          </video>
        )}
        {mediaType === 'redgifs' && (
          <div style={{position: 'relative', width: '100%', height: '100%'}}>
            <iframe src={transformUrl(post.link)}
                    frameBorder='0'
                    scrolling='no'
                    style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "contain" }}
                    allowFullScreen>
            </iframe>
          </div>
        )}
      </BorderPostMedia>
    );
  }

  if (
    post.file &&
    post.objectFile &&
    post.objectFile.type.startsWith("image")
  ) {
    return (
      <BorderPostMedia>
        <img
          style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "contain" }}
          src={URL.createObjectURL(post.objectFile)}
          alt="link for media"
        />
      </BorderPostMedia>
    );
  }

  if (
    post.file &&
    post.objectFile &&
    post.objectFile.type.startsWith("video")
  ) {
    return (
      <BorderPostMedia>
        <video
          style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "contain" }}
          autoPlay={false}
          src={URL.createObjectURL(post.objectFile)}
        />
      </BorderPostMedia>
    );
  }

  return null;
};

export default RenderCreatePostMedia;
