import React, {ChangeEvent, useState} from 'react';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useNavigate } from 'react-router-dom';
import {FormControl, FormLabel, InputAdornment, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import {useMutation} from "@tanstack/react-query";
import {activateBeta, downloadViral} from "../api";
import toast from "react-hot-toast";

const Copyright = () => (
  <Typography variant="body2" color="textSecondary" align="center">
    {'Copyright © '}
    <Link color="inherit" href="https://material-ui.com/">
      ViralDaddy
    </Link>
    {' '}
    {new Date().getFullYear()}

  </Typography>
);

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  link: {
    textDecoration: 'none',
  },
  facebook: {
    margin: theme.spacing(0, 0, 2),
    backgroundColor: '#3b5998',
  },
  google: {
    margin: theme.spacing(0, 0, 2),
    backgroundColor: '#de5246',
  },
}));

const WaitList = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [code, setCode] = useState<string>('')

  const sendCodeMutation = useMutation( {mutationFn: (code: number) => activateBeta(code), onSuccess:(result) => {
      const url = new URL('/virals', window.location.href);
      window.location.href = url.toString();
    }});
  const onCodeHandle = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    if (typeof event.target.value === "string") {
      const code = event.target.value.trim()
      setCode(code);
    }
  }

  const handleSubmitCode = async (code: string) => {
    await sendCodeMutation.mutateAsync(Number(code))
  }

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Typography component="h1" variant="h5" style={{marginBottom: 20}}>
          Hey there!
        </Typography>

        <div>
          <Typography style={{marginBottom: 20}}>
            Thank you for your interest in Viral Daddy. Currently, our platform is invite-only for user research, but we will notify you when we are open to the general public. We appreciate your enthusiasm and look forward to welcoming you to Viral Daddy soon.
          </Typography>

          <Typography style={{marginBottom: 20}}>
            Have a beta user code? Enter it below
          </Typography>
          <FormControl component="fieldset">
            <FormLabel component="legend">Beta code</FormLabel>
            <TextField value={code} onChange={onCodeHandle}
                       inputProps={{ maxLength: 6 }}
                       InputProps={
                         {
                           endAdornment: (
                             <InputAdornment position="end">
                               <LoadingButton
                                 loading={sendCodeMutation.isLoading}
                                 aria-label="edit-watermark-button"
                                 color={'primary'}
                                 variant="contained"
                                 onClick={() => handleSubmitCode(code)}
                               >
                                 Submit
                               </LoadingButton>
                             </InputAdornment>
                           )
                         }
                       }
            />
          </FormControl>

        </div>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default WaitList;
