import React, {useState, ChangeEvent, useEffect} from 'react';
import { Chip, TextField, makeStyles } from '@material-ui/core';
import {CreatePostBody} from "../../../api/types";
import {PostScheduleBuilder} from "../craete-post-builder";
import {usePostRequestContext} from "./post-request-provider";

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: '#f5f5f5',
    padding: '16px',
    borderRadius: '8px',
  },
  chipContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
    marginTop: '8px',
  },
  textField: {
    width: '100%',
  },
}));

const LinkChips: React.FC = () => {
  const classes = useStyles();
  const [links, setLinks] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState('');

  const { postRequestContext, setPostRequestContext } = usePostRequestContext();

  useEffect(() => {
    const currentLinkPosts = postRequestContext.filter(p => p.link).map(p => p.link) as string[];
    if(currentLinkPosts.length > 0){
      setLinks(currentLinkPosts)
      setInputValue(currentLinkPosts.join(' '))
    }
  }, [])

  useEffect(() => {
    const currentNonLinkPosts = postRequestContext.filter(p => !p.link);

    // Construct new posts from valid links
    const linkPosts: CreatePostBody[] = links.map(l => {
      const builder = new PostScheduleBuilder();
      return builder
        .setLink(l)
        .setFile(false)
        .setObjectFile(null)
        .setExtension(null)
        .build();
    });

    // Merge non-link posts and link posts
    setPostRequestContext([...currentNonLinkPosts, ...linkPosts]);

  }, [links]);

  const validateURL = (url: string) => {
    const pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name and extension
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // fragment locator

    return pattern.test(url);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);

    const potentialLinks = event.target.value.split(' ');
    const newLinks = potentialLinks.filter(validateURL);
    setLinks(newLinks);
  };

  return (
    <div className={classes.container}>
      <TextField
        className={classes.textField}
        label="Paste links. Add a space for each new link. (For Reddit, most video and some image link now only support Redgifs)"
        multiline
        minRows={4}
        variant="outlined"
        value={inputValue}
        onChange={handleInputChange}
      />
      <div className={classes.chipContainer}>
        {inputValue.length > 0 && inputValue.split(' ').map((link, index) => (
          <Chip
            key={index}
            label={link}
            color={validateURL(link) ? 'primary' : 'secondary'}
          />
        ))}
      </div>
    </div>
  );
};

export default LinkChips;