import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import BrandingWatermarkIcon from "@mui/icons-material/BrandingWatermark";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LoyaltyIcon from "@mui/icons-material/Loyalty";

import ListItemIcon from "@mui/material/ListItemIcon";
import {
  Grid,
  List,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { useGetUser } from "../../hooks/api-hooks/useGetUser";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Watermark } from "./components/watermark";
import CheckList from "./components/check-list";
import MyProfile from "./components/my-profile";
import MembershipPage from "./components/membership";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(4),
  },
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    marginBottom: theme.spacing(2),
  },
  username: {
    marginBottom: theme.spacing(2),
    fontWeight: "bold",
    fontSize: "1.5rem",
  },
  membership: {
    marginBottom: theme.spacing(2),
  },
}));

export const Settings = () => {
  const [currentSetting, setCurrentSetting] = useState("profile");
  const navigate = useNavigate();
  const location = useLocation();
  const user = useGetUser();
  const classes = useStyles();

  useEffect(() => {
    const path = location.pathname.split("/")[2];
    if (path === "watermark" || path === "checklist" || path === "membership") {
      setCurrentSetting(path);
    } else {
      setCurrentSetting("profile");
    }
  }, [location]);

  const goTo = (setting: string) => {
    setCurrentSetting(setting);
    if (setting === "profile") {
      navigate("/settings");
    } else {
      navigate(setting);
    }
  };

  return (
    <Grid container rowSpacing={{ xs: 3 }} columnSpacing={{ xs: 2 }}>
      <Grid item xs={12} style={{ marginBottom: "20px" }}>
        <Typography variant={"h5"}>Settings</Typography>
      </Grid>
      {/* Settings Navigation */}
      <Grid item xs={12} md={3}>
        <List>
          <ListItemButton
            selected={currentSetting === "profile"}
            onClick={() => goTo("profile")}>
            <ListItemIcon>
              <AccountCircleIcon />
            </ListItemIcon>
            <ListItemText primary="Profile" />
          </ListItemButton>
          <ListItemButton
            selected={currentSetting === "watermark"}
            onClick={() => goTo("watermark")}
          >
            <ListItemIcon>
              <BrandingWatermarkIcon />
            </ListItemIcon>
            <ListItemText primary="Watermark" />
          </ListItemButton>
          <ListItemButton
            selected={currentSetting === "checklist"}
            onClick={() => goTo("checklist")}
          >
            <ListItemIcon>
              <PlaylistAddCheckIcon />
            </ListItemIcon>
            <ListItemText primary="Checklist" />
          </ListItemButton>
          <ListItemButton
            selected={currentSetting === "membership"}
            onClick={() => goTo('membership')}
          >
            <ListItemIcon>
              <LoyaltyIcon />
            </ListItemIcon>
            <ListItemText primary="Membership" />
          </ListItemButton>
        </List>
      </Grid>
      {/* Profile */}
      <Grid item xs={12} md={6}>
        <Routes>
          <Route path="/" element={<MyProfile />} />
          <Route path="/watermark" element={<Watermark />} />
          <Route path="/checklist" element={<CheckList />} />
          <Route
            path="/membership"
            element={<MembershipPage/>}
          />
        </Routes>
      </Grid>
    </Grid>
  );
};
