export enum ViralLengthPreset {
  SHORT = 'short',
  MEDIUM = 'medium',
  LONG = 'long',
}

export interface CreateViral {
  lengthPreset: ViralLengthPreset;
  sourceNumberOfClips: number;
  thumbnail?: string;
  name?: string | null;
  includeWatermark: boolean;
  totalRawDuration: number;
  captions: string[];
  isPromo?: boolean;
  promoText?: string;
}

export interface ViralResponse {
  id: string
  status: string;
  createdAt: string;
  name: string;
  thumbnailUrl: string;
  metaData: Record<string, string> | null

  submissions: {provider: string, submittedAt: string}[]
  viralFiles: {
    id: string,
    fileId: string,
    type: string,
    status: string,
    streamUrl: string
  }[]
}

export interface QueryVirals {
  results: ViralResponse[],
  next: string | null;
  previous: string | null;
  count: number;
}

export interface FileResponse {
  id?: string;
  name?: string;
  downloadUrl?: string | null;
  bucket?: string;
  filePath?: string;
  fileType?: string;
  contentType?: string | null;
  customMetadata?: any | null;
  uploadStatus?: string | null;
  uploadFailReason?: string | null;
  createdBy?: string;
  createdAt?: Date;
  updatedBy?: string;
  updatedAt?: Date;
}

export interface PlansResponse {
  id: number;
  label: string;
  code: string;
  monthlyPrice: number;
  features: {
    name: string;
    description: string;
    limit: number;
  }[]
}

export interface  UserResponse {
  id: string;
  userName: string;

  redditIconUrl: string;
  watermarkText: string;
  watermarkFont: string | null;
  watermarkColor: string | null;
  watermarkPref: string;
  watermarkImageUrl: string
  email: string;
  role: string;
  isRedditLinked: boolean;
  isRedgifsLinked: boolean;
  isTwitterLinked: boolean;
  isTwitterV1Linked: boolean;
  notifyOnComplete: boolean;
  redditUserName?: string | null;
  twitterUserName?: string | null;
  twitterIconUrl: string;
  subscription: {
    id: number;
    label: string;
    code: string;
    cancelAtPeriodEnd: boolean | null;
    currentPeriodStart: string;
    currentPeriodEnd: string;
    status: string;
    features: {
      name: string;
      limit: number;
      usage: number;
    }[] | null
  }
}

export interface  UserBody {
  watermarkText?: string;
  watermarkFont?: string | null;
  watermarkColor?: string | null;
  watermarkPref?: string;
  email?: string | null;
  userName?: string;
  notifyOnComplete?: boolean;
}

export interface WatermarkUploadResponse {
  uploadUrl: string;
  fileId: string
}

export interface WatermarkFinalizeBody {
  fileId: string
}

// export interface File {
//   id: string,
//   name: string,
//   downloadUrl?: string | null
// }

export interface CreatePostBody {
  link: string | null;
  file: boolean | null;
  objectFile: File | null;
  extension: string | null;
  postSchedule: PostScheduleBody[]
  tweetFrequency?: number
}

export interface CreatePostResponse {
  postId: string;
  contentType?: string | null;
  fileId?: string | null;
  uploadUrl?: string | null;
}

export interface PostScheduleBody {
  platformId: number;
  metaData: PostScheduleMetaData;
}

export interface UpdatePostScheduleBody {
  status: string;
  metaData: Partial<PostScheduleMetaData>;
  scheduledAt: string;
}

export interface PostScheduleMetaData {
  reddit?: {
    subreddit: string;
    title: string;
    flairId?: string | null;
  };
  twitter?: {
    body: string;
  };
  redgifs?: {
    tags: string[];
  };
}

export interface PostResponse {
  postId: string,
  file: FileResponse | null;
  link: string | null;
  postScheduleId: string,
  platformId: number,
  platformPostId: string,
  metaData: PostScheduleMetaData,
  status: string,
  scheduledAt: string,
  postedAt: string | null,
}

export interface QueryPostResponse {
  results: PostResponse[],
  next: string | null;
  previous: string | null;
  count: number;
}

export interface SubredditResponse {
  name: string,
  verify: boolean,
  over18?: boolean | null,
  top?: boolean | null,
  subs: number,
  category?: string,
  isCustom?: boolean;
}

export interface SubredditRulesResponse {
  title: string,
  description: string,
}


export interface RedditData {
  data: {
    children: {
      data: {
        thumbnail_height: number;
        thumbnail_width: number;
        thumbnail: string;
        title: string;
      };
    }[];
  };
}

export interface SubredditGroupResponse {
  id: string;
  name: string;
  subreddits: string[]
}

export interface CreateSubredditGroupBody {
  name: string;
  subreddits: string[]
}

export interface UserStatisticsResponse {
  viralScore: number;
  totalRedditUpvotes: number;
  totalNumberOfUpcomingPosts: number;
  totalNumberOfPreviousPosts: number;
  totalViralVideos: number;
}

export interface SubredditTrendsResponse {
  userName: string;
  numberOfPosts: number;
  numberOfImages: number;
  numberOfVideos: number;
  totalPostKarma: number;
}