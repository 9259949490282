import {Chip, styled} from "@mui/material";

export const StyledChip = styled(
  Chip,
  {
    shouldForwardProp: (prop: string) =>
      ![
        'status',
      ].includes(prop),
  }
)<{status: string}>`
    background: ${({ theme, status }) => {
  if (status === 'created') return theme.palette.primary.main;
  if (status === 'processing') return theme.palette.info.main;
  if (status === 'failed') return theme.palette.error.light;
  return theme.palette.success.light
}};
    color: ${({ theme, status }) => theme.palette.common.white}};
  `;
