import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import RedditIcon from '@mui/icons-material/Reddit';
import TwitterIcon from '@mui/icons-material/Twitter';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useNavigate } from 'react-router-dom';
import ResponsiveAppBar from "../components/app-header";

const Copyright = () => (
  <Typography variant="body2" color="textSecondary" align="center">
    {'Copyright © '}
    <Link color="inherit" href="https://viraldaddy.ai">
      ViralDaddy
    </Link>
    {' '}
    {new Date().getFullYear()}

  </Typography>
);

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(20),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  link: {
    textDecoration: 'none',
  },
  redgifs: {
    margin: theme.spacing(0, 0, 2),
    backgroundColor: 'red',
  },
  google: {
    margin: theme.spacing(0, 0, 2),
    backgroundColor: '#de5246',
  },
  reddit: {
    margin: theme.spacing(0, 0, 2),
    backgroundColor: '#FF4500',
  },
  twitter: {
    margin: theme.spacing(0, 0, 2),
    backgroundColor: '#1d9bf0',
  },
}));

const Login = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Container component="main" maxWidth="xs">
      <ResponsiveAppBar
        navManuOpen={false}
        hideMenu={true}
      />
      <Box className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography variant="h5">
          Sign Up or Sign In
        </Typography>
        <br/>
        <Typography component="h2" variant="h5" color="textSecondary">
          It's <b><u>Free</u></b> to get started!
        </Typography>
        <br/>
        <form className={classes.form} noValidate>
          <a className={classes.link} href="/api/login/reddit">
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.reddit}
              startIcon={<RedditIcon/>}
            >
              Reddit
            </Button>
          </a>
          <a className={classes.link} href="/api/login/twitter">
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.twitter}
              startIcon={<TwitterIcon/>}
            >
              Twitter
            </Button>
          </a>
          {/*<a className={classes.link} href="/api/login/google">*/}
          {/*  <Button*/}
          {/*    fullWidth*/}
          {/*    variant="contained"*/}
          {/*    color="primary"*/}
          {/*    className={classes.google}*/}
          {/*    startIcon={<GoogleIcon/>}*/}
          {/*  >*/}
          {/*    Google*/}
          {/*  </Button>*/}
          {/*</a>*/}
        </form>
      </Box>
      <br/>
      <Typography align={'center'}>
        We currently only provide OAuth Providers for signing up but will soon introduce more providers!
      </Typography>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default Login;
