import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useStyles } from "../../shared/styles/shared-styles";
import { useGetTrends } from "../../hooks/api-hooks/useGetTrends";
import {
  calculateAverageMedia,
  calculateAveragePosts,
  calculateAverageVideoImageRatio,
} from "./utils";
import Box from "@mui/material/Box";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import {getTrend, getTrends} from "../../api";
import RenderMediaOnly from "../../components/render-media";
import { useDialog } from "../../components/app-dialog-provider";
import {useQuery} from "@tanstack/react-query";
import {SubredditTrendsResponse} from "../../api/types";

export const Trends = () => {
  const classes = useStyles();

  const [ratio, setRatio] = useState(0);
  const [avgPosts, setAvgPosts] = useState(0);
  const [avgMedia, setAvgMedia] = useState([0, 0]);

  const { showDialog, hideDialog } = useDialog();

  const trendsQuery = useQuery<SubredditTrendsResponse[]>(
    ["getTrends"],
    () => getTrends(),
    {
      cacheTime: 60 * 60 * 24,
      onError: () => {

      }
    }
  );

  useEffect(() => {
    if (trendsQuery.data) {
      setRatio(calculateAverageVideoImageRatio(trendsQuery.data));
      setAvgPosts(calculateAveragePosts(trendsQuery.data));
      setAvgMedia(calculateAverageMedia(trendsQuery.data));
    }
  }, [trendsQuery.isFetching]);

  function viewTrending(userName: string) {
    showDialog({
      title: `Trending for user ${userName}`,
      fetchData: async () => {
        const trends = await getTrend(userName);
        return (
          <>
            <Grid container spacing={2}>
              {trends.map((s, i) => (
                <Grid xs={12} item key={i}>
                  <RenderMediaOnly link={s} />
                </Grid>
              ))}
            </Grid>
          </>
          )
      },
      actions: [{ label: "Confirm", onClick: () => hideDialog() }],
    });
  }


  function TopCreators() {
    if (trendsQuery.isFetching) {
      return <>Loading...</>;
    }

    if (!trendsQuery.isFetching && !trendsQuery.data) {
      return <>
        <Table>
          <TableHead>
            <TableCell>Username</TableCell>
            <TableCell>Monthly Karma</TableCell>
            <TableCell>Monthly Posts</TableCell>
            <TableCell>Trending</TableCell>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell colSpan={4} style={{textAlign:'center'}}>No Data</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </>;
    }

    if (trendsQuery.data) {
      return (
        <Table>
          <TableHead>
            <TableCell>Username</TableCell>
            <TableCell>Monthly Karma</TableCell>
            <TableCell>Monthly Posts</TableCell>
            <TableCell>Trending</TableCell>
          </TableHead>
          <TableBody>
            {trendsQuery.data.map((trend, i) => (
              <TableRow>
                <TableCell>
                  <Typography>
                    <Link
                      target={"_blank"}
                      href={`https://reddit.com/user/${trend.userName}`}
                    >
                      {" "}
                      {trend.userName}{" "}
                    </Link>
                  </Typography>
                </TableCell>
                <TableCell>
                  {trend.totalPostKarma.toLocaleString("en-US")}
                </TableCell>
                <TableCell>{trend.numberOfPosts}</TableCell>
                <TableCell>
                  <IconButton onClick={() => viewTrending(trend.userName)}>
                    <TrendingUpIcon color={"primary"} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      );
    }

    return (
      <>
        <b>bruh</b>
      </>
    );
  }

  return (
    <>
      <Typography variant={"h5"} mb={3}>
        Trends
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Card variant={"outlined"} className={classes.card}>
            <CardContent>
              <Stack>
                <Typography
                  gutterBottom
                  variant="h6"
                  className={classes.cardTitle}
                >
                  Average Number Of Posts Per Month
                </Typography>
                <Typography variant={"h2"}>{avgPosts}</Typography>
                <Typography
                  variant={"caption"}
                  mt={2}
                  className={classes.captionTitle}
                >
                  The average number of posts by top content creators per month
                </Typography>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card variant={"outlined"} className={classes.card}>
            <CardContent>
              <Stack>
                <Typography
                  gutterBottom
                  variant="h6"
                  className={classes.cardTitle}
                >
                  Average Image Posts
                </Typography>
                <Typography variant={"h2"}>{avgMedia[0]}</Typography>
                <Typography
                  variant={"caption"}
                  mt={2}
                  className={classes.captionTitle}
                >
                  The average number of image posts by top content creators per
                  month
                </Typography>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card variant={"outlined"} className={classes.card}>
            <CardContent>
              <Stack>
                <Typography
                  gutterBottom
                  variant="h6"
                  className={classes.cardTitle}
                >
                  Average Video Posts
                </Typography>
                <Typography variant={"h2"}>{avgMedia[1]}</Typography>
                <Typography
                  variant={"caption"}
                  mt={2}
                  className={classes.captionTitle}
                >
                  The average number of video posts by top content creators per
                  month
                </Typography>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card variant={"outlined"} className={classes.card}>
            <CardContent>
              <Stack>
                <Typography
                  gutterBottom
                  variant="h6"
                  className={classes.cardTitle}
                >
                  Average Video To Image Ratio
                </Typography>
                <Typography variant={"h2"}>{ratio}</Typography>
                <Typography
                  variant={"caption"}
                  mt={2}
                  className={classes.cardTitle}
                >
                  {`For every 1 image post, there are approximately ${ratio.toFixed(
                    0
                  )} videos posts.`}
                </Typography>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Box mt={3} mb={3}>
        <Typography variant={"h5"}>Top 100 Creators - Past 30 Days</Typography>
      </Box>
      <TopCreators />
    </>
  );
};
