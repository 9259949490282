import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Button from "@mui/material/Button";
import React, { ReactNode } from "react";
import DialogContentText from "@mui/material/DialogContentText";
export interface ConfirmDialogProps {
  message: ReactNode;
  title: string;
  onClose: () => void;
  onOk: () => void;
  onCancel: () => void;
  open: boolean;
  cancelText: string;
  okText: string;
  confirmColor?: "primary" | "error";
}
export const ConfirmDialog = (props: ConfirmDialogProps) => {
  const {
    open,
    message,
    onClose,
    title,
    onOk,
    onCancel,
    cancelText = "Cancel",
    okText = "Confirm",
    confirmColor,
  } = props;

  const handleOk = () => {
    onOk(); // Call the provided onOk callback
    onClose(); // Close the dialog
  };

  const handleCancel = () => {
    onCancel();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCancel}
          color={"primary"}
          autoFocus
        >
          {cancelText}
        </Button>
        <Button
          color={confirmColor ? confirmColor : "primary"}
          onClick={handleOk}
        >
          {okText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
