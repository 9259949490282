import React, {
  FC,
  ReactElement,
} from 'react';

import {
  Navigate,
  useLocation,
} from 'react-router-dom';

import {useGetUser} from "../hooks/api-hooks/useGetUser";
import {Backdrop, CircularProgress} from "@mui/material";
import {createStyles, makeStyles} from "@material-ui/core/styles";

interface ProtectedRouteProps {
  children: ReactElement;
}


const useStyles = makeStyles((theme) =>
  createStyles({
    loading: {
      textAlign:"center",
      display: 'flex',
      flexDirection: 'column',
      background: 'linear-gradient(-45deg, rgba(255,50,111), rgba(255,50,111) 5%, #000023)',
      backgroundSize: '400% 400%',
      animation: 'loading .75s ease-in-out infinite',
    },
    '@keyframes loading': {
      '0%': {
        backgroundPosition: '0% 0%',
      },
      '100%': {
        backgroundPosition: '100% 0%',
      },
    },
  }),
);


const ProtectedRoute: FC<ProtectedRouteProps> = ({ children }) => {
  const user = useGetUser();
  const from = useLocation();
  const classes = useStyles();

  if (user.isSuccess && user.data?.userName) {
    return children;
  }

  if(user.isFetched && user.isError && !user.data?.userName){
    return <Navigate to="/login" state={{ from }} />;
  }

  return <>
    <Backdrop className={classes.loading} invisible={true} open={true} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <CircularProgress style={{color: 'rgba(255,50,111)'}} />
      <br/>
      one moment...
    </Backdrop>
  </>
};

export default ProtectedRoute;
