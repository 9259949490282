import React from 'react';
import Box from "@mui/material/Box";

interface HealthStatusBarProps {
  value: number;
  type?: 'default' | 'votes' | 'numberOfPosts' ;
}

const normalizeUpvotes = (votes:number) => {
  if( votes <= 100){
    return 20
  }
  if (votes <= 500 ){
    return 40
  }
  if( votes <= 1000){
    return 60
  }

  if( votes <= 1500){
    return 80
  }

  return 100
}

const normalizeUpcomingNumberOfPosts = (numberOfPosts:number) => {
  if( numberOfPosts <= 40){
    return 20
  }
  if (numberOfPosts <= 80 ){
    return 40
  }
  if( numberOfPosts <= 120){
    return 60
  }

  if( numberOfPosts <= 200){
    return 80
  }

  return 100
}

export const HealthStatusBar: React.FC<HealthStatusBarProps> = ({ value, type = 'default' }) => {
  let localValue = value;
  // Decide color based on value
  if(type === 'votes'){
    localValue = normalizeUpvotes(value);
  }

  if(type === 'numberOfPosts'){
    localValue = normalizeUpcomingNumberOfPosts(value);
  }

  let color: string;
  if (localValue <= 20) {
    color = 'red';
  } else if (localValue <= 40) {
    color = 'orange';
  } else if (localValue <= 60) {
    color = 'yellow';
  } else if (localValue <= 80) {
    color = 'greenyellow';
  } else {
    color = 'green';
  }

  // Decide how many blocks to fill based on value
  const filledBlocks = Math.ceil(localValue / 20);

  return (
    <Box display="flex">
      {[1, 2, 3, 4, 5].map((blockNum) => (
        <Box
          key={blockNum}
          width={18}
          height={18}
          m={0.5}
          bgcolor={blockNum <= filledBlocks ? color : 'lightgrey'}
        />
      ))}
    </Box>
  );
};