import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import Button from "@mui/material/Button";
import React, {ReactNode} from "react";
import Typography from "@mui/material/Typography";
import DialogContentText from "@mui/material/DialogContentText";
export interface ConfirmDialogProps {
  message: ReactNode;
  title: string;
  onClose: () => void;
  open: boolean;
}
export const AlertDialog = (props: ConfirmDialogProps) => {
  const { open, message, onClose, title } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
