import React, { useEffect, useState, ReactNode } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

interface Action {
  label: string;
  onClick: () => void | Promise<void>;
}

export interface CustomDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  content?: ReactNode;
  actions: Action[];
  fetchData?: () => Promise<ReactNode>; // Function to fetch content data
}

export const CustomDialog: React.FC<CustomDialogProps> = ({ open, onClose, title, actions, fetchData, content }) => {
  const [innerContent, setInnerContent] = useState<ReactNode | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let isMounted = true; // Flag to check if the component is still mounted
    if(content){
      setInnerContent(content);
      return;
    }
    if(!fetchData){
      return;
    }
    const fetchAndSetData = async () => {
      if (fetchData) {
        setLoading(true);
        try {
          const result = await fetchData();
          if (isMounted) {
            setInnerContent(result);
          }
        } catch (error) {
          // Handle error, if needed
        } finally {
          if (isMounted) {
            setLoading(false);
          }
        }
      }
    };

    fetchAndSetData();

    return () => {
      isMounted = false; // Cleanup: set flag to false when component unmounts
    };
  }, [fetchData, content]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {loading ? <CircularProgress /> : innerContent}
      </DialogContent>
      <DialogActions>
        {actions.map((action, index) => (
          <Button key={index} onClick={action.onClick} color="primary">
            {action.label}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
};

export default CustomDialog;
