import {createContext} from "react";

interface WebSocketContextProps {
  // socket: WebSocket | EventSource | null;
  socketMessageUpdate: any
}

export const WebSocketContext = createContext<WebSocketContextProps>({
  // socket: null,
  socketMessageUpdate: null
});
