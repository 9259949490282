import React, { useEffect, useState } from "react";
import { TextField, Button } from "@material-ui/core";
import { Checkbox, FormControlLabel, InputAdornment } from "@mui/material";
import { useGetUser } from "../../../hooks/api-hooks/useGetUser";
import { useUpdateUser } from "../../../hooks";
import toast from "react-hot-toast";

function MyProfile(): JSX.Element {
  const [email, setEmail] = useState<string>('');
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<string | null>(null);

  const [username, setUsername] = useState<string>("");
  const [isUserNameEditing, setIsUserNameEditing] = useState<boolean>(false);
  const [usernameError, setUsernameError] = useState<string | null>(null);

  const user = useGetUser();
  const updateUserMutation = useUpdateUser();

  useEffect(() => {
    if (user.data?.email) {
      setEmail(user.data.email);
    }
    if (user.data?.userName) {
      setUsername(user.data.userName);
    }
  }, [user.data]);

  const validateEmail = (email: string) => {
    const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    return emailRegex.test(email);
  };

  const validateUsername = (username: string) => {
    const regex = /^[\p{L}\p{N}_-]+$/u;
    return regex.test(username);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    setEmailError(validateEmail(newEmail) ? null : "Invalid email");
  };

  const handleSaveEmail = async () => {
    if (!validateEmail(email)) {
      toast.error("Please format the email correctly");
    } else {
      if (email !== user.data?.email) {
        await updateUserMutation.mutateAsync(
          { email },
          {
            onSuccess: () => {
              toast.success("Email saved!");
            },
          }
        );
        user.refetch();
      }
      setIsEditing(false);
    }
  };

  const handleEditEmail = () => {
    setIsEditing(true);
  };

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newUsername = event.target.value;
    setUsername(newUsername);
    setUsernameError(validateUsername(newUsername) ? null : "Invalid username");
  };

  const handleEditUsername = () => {
    setIsUserNameEditing(true);
  };

  const handleSaveUsername = async () => {
    if (!validateUsername(username)) {
      toast.error(
        'Please format the username correctly. Only alphanumeric characters and only "_" and "-" special characters'
      );
    } else {
      if (username !== user.data?.userName) {
        await updateUserMutation.mutateAsync({ userName: username });
        user.refetch();
      }
      setIsUserNameEditing(false);
    }
  };

  const handleEditNotifyEmail = async () => {
    if (!user.data?.email) {
      toast.error("Please add an email before you enable notifications");
      return;
    }
    await updateUserMutation.mutateAsync(
      {
        notifyOnComplete: !user.data?.notifyOnComplete,
      },
      {
        onSuccess: (result) => {
          if (result.notifyOnComplete) {
            toast.success(
              "You will now receive email notifications on complete"
            );
          } else {
            toast.success("You will no longer receive email notifications");
          }
        },
      }
    );
    user.refetch();
  };

  return (
    <>
      <TextField
        id="username"
        label="Username"
        margin="normal"
        variant="outlined"
        error={usernameError !== null}
        helperText={usernameError}
        fullWidth={true}
        disabled={!isUserNameEditing}
        onChange={handleUsernameChange}
        value={username}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {!isUserNameEditing ? (
                <Button variant="contained" onClick={handleEditUsername}>
                  Change Username
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSaveUsername}
                >
                  Save Username
                </Button>
              )}
            </InputAdornment>
          ),
        }}
      />
      <div style={{ display: "flex", alignItems: "center" }}>
        <TextField
          id="email"
          label="Email"
          margin="normal"
          variant="outlined"
          placeholder={'Enter email'}
          error={emailError !== null}
          helperText={emailError}
          value={email}
          onChange={handleEmailChange}
          disabled={!isEditing}
          fullWidth={true}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {!isEditing ? (
                  <Button variant="contained" onClick={handleEditEmail}>
                    Change email
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSaveEmail}
                  >
                    Save email
                  </Button>
                )}
              </InputAdornment>
            ),
          }}
        />
      </div>
      <FormControlLabel
        control={
          <Checkbox onClick={handleEditNotifyEmail} checked={user.data?.notifyOnComplete} />
        }
        label="Email me when my viral is complete"
      />
    </>
  );
}

export default MyProfile;
