import * as React from "react";
import Badge, { BadgeProps } from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import RedditIcon from "@mui/icons-material/Reddit";
import TwitterIcon from "@mui/icons-material/Twitter";
import SvgIcon from "@mui/material/SvgIcon";

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));

interface BadgeProperties {
  count: number;
  provider: string;
}
export default function CustomizedBadge({ count, provider }: BadgeProperties) {
  const CountIcon = ({ provider }: {provider: string}) => {
    if (provider === "reddit") {
      return <RedditIcon color={count > 0 ? "action" : "disabled"} />;
    }
    if (provider === "twitter") {
      return <TwitterIcon color={count > 0 ? "action" : "disabled"} />;
    }
    if (provider === "redgifs") {
      return (
        <SvgIcon>
          <image xlinkHref="/redgifs.svg" width="24" height="24" />
        </SvgIcon>
      );
    }
    return <></>;
  };
  return (
    <Tooltip title={`Posted to ${provider} ${count} times`}>
      <StyledBadge badgeContent={count} color="secondary">
        <CountIcon provider={provider} />
      </StyledBadge>
    </Tooltip>
  );
}
