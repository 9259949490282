import { makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles((theme) => ({
  cardTitle: {
    color: theme.palette.grey.A200
  },
  captionTitle: {
    color: theme.palette.grey.A200
  },
  card: {
    minHeight: '0', // or any value you prefer
  },
  cardHero: {
  },
  cardHeroContent: {
    height: '100%'
  },
  cardHeroStack: {
    height: '100%'
  },
  cardContainer: {
    display: 'flex',
    alignItems: 'stretch'
  },
  cardFullHeight: {
    height: '100%',
    minHeight: '200px',
    display: 'flex',
    flexDirection: 'column'
  },
  actionCard: {
    background: 'linear-gradient(45deg, #FFC8D3 30%, #FFE1C5 90%)', // softer pink to peach gradient
    boxShadow: '0 3px 5px 2px rgba(255, 182, 193, .3)',  // adjusted shadow color
    color: theme.palette.grey[700],  // softer than black
  },
  settingCard: {
    background: '#E6E6FA !important', // Lavender
    color: theme.palette.grey[700],  // softer than black
  },
  actionButton: {
    backgroundColor: theme.palette.grey[700],  // this is a mid-grey
    color: theme.palette.common.white,
    width: '100%',
    '&:hover': {
      backgroundColor: theme.palette.grey[600], // slightly lighter grey on hover
      opacity: 0.8,
    },
  },
  actionCardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',  // take the full height of the parent card
  },
  actionIcon: {
    fontSize: 40, // or whatever size you prefer
    marginBottom: theme.spacing(2),
  },
  flexGrow: {
    flexGrow: 1, // This will make the container expand and push other items
  },
}));