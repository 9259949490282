import React, {FC, useEffect, useState} from 'react';
import { Box } from '@mui/material';
import {makeStyles} from "@material-ui/core/styles";
import {transformUrl} from "../utils";

interface RenderMediaProps {
  link: string;
  isMobile?: boolean;
}

const useStyles = makeStyles((theme) => ({
  mediaChild: {
    width: '100%',
    height: '100%',
    objectFit: 'cover' // Keep aspect ratio
  },
  mediaBox: {
    maxWidth: '100%',
    height: 300 * 1.5  // Set your desired height
  },
  mediaBoxMobile: {
    maxWidth: '100%',
    height: 60 * 2.5
  }
}));
const RenderMediaOnly: FC<RenderMediaProps> = ({ link, isMobile = false }) => {
  const classes = useStyles()

  const [mediaType, setMediaType] = useState<'image' | 'video' | 'redgifs' | null>(null);

  useEffect(() => {
    if (link) {
      const extension = link.split('.').pop()?.toLowerCase();
      if (link.includes('redgifs')) {
        setMediaType('redgifs')
      } else if (['png', 'jpg', 'jpeg', 'gif', 'webp'].includes(extension || '')) {
        setMediaType('image');
      } else if (['mp4', 'webm', '.mov'].includes(extension || '')) {
        setMediaType('video');
      }
      // Add more media types as necessary
    }
  }, [link]);


  if (link && mediaType) {
    return (
      <Box className={isMobile ? classes.mediaBoxMobile : classes.mediaBox}>
        {mediaType === 'image' && (
          <img src={link} alt="link for media" className={classes.mediaChild} />
        )}
        {mediaType === 'video' && (
          <video controls className={classes.mediaChild}>
            <source src={link} />
          </video>
        )}
        {mediaType === 'redgifs' && (
          <div style={{position: 'relative', width: '100%', height: '100%'}}>
            <iframe src={transformUrl(link)}
                    frameBorder='0'
                    scrolling='no'
                    className={classes.mediaChild}
                    allowFullScreen>
            </iframe>
          </div>
        )}
      </Box>
    );
  }

  return null;
};

export default RenderMediaOnly;
