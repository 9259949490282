import {Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";
import {Link} from "react-router-dom";

export const Support = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant={"h5"}>Support</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          Email us: <a href="mailto:support@viraldaddy.ai">support@viraldaddy.ai</a>
        </Typography>

      </Grid>
      <Grid item xs={12}>
        <Typography>
          DM us on twitter: <a href="https://twitter.com/viraldaddyai" target="_blank" rel="noopener noreferrer">
            twitter.com/viraldaddyai
          </a>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          Visit us on reddit: <a href="https://www.reddit.com/r/viraldaddy" target="_blank" rel="noopener noreferrer">
            reddit.com/r/viraldaddy
          </a>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          Or click the live chat icon in the bottom right! Response will be within 24 hours!
        </Typography>
      </Grid>
    </Grid>
  )
}