import {useGetViral} from "../../hooks";
import React, {useState} from "react";
import {useParams} from "react-router-dom";
import {Card, CardContent, CircularProgress, Grid, LinearProgress} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import RedditIcon from '@mui/icons-material/Reddit';
import TwitterIcon from '@mui/icons-material/Twitter';
import DownloadIcon from '@mui/icons-material/Download';
import {StyledChip} from "../list-virals/components/styled-status-chip";
import {getFile, getViral} from "../../api";
import {useQuery} from "@tanstack/react-query";

export const Viral = () => {
  const {id: viralId} = useParams();
  const [refetchViralInterval, setRefetchViralInterval] = useState<boolean | number>(5000);
  const viral = useQuery( [`getViral`, viralId], () => getViral(viralId),
    {
      enabled: true,
      // @ts-ignore
      refetchInterval: refetchViralInterval,
      onSuccess:(viral) => {
        if(viral.status === 'completed' || viral.status === 'failed') setRefetchViralInterval(false)
      }
    }
  );
  const downloadFile = async (id: string) => {
    const fileResponse = await getFile(id);
    if(fileResponse){
      setTimeout(() => {
        window.open(fileResponse.downloadUrl as string  , '_blank');
      })
    }
  };

  if(viral.isLoading || !viral.isSuccess){
    return (
      <>
        <CircularProgress />
      </>
    )
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box>
          {viral.data.status === 'processing' ?
            <>
              <LinearProgress />
              <Typography variant={"caption"} display={"block"} gutterBottom> Almost done viralizing! </Typography>
            </> : null
          }
        </Box>
      </Grid>
      {/* RAW VIDEOS */}
      {/*<Grid xs={12} style={{marginTop:10}}>*/}
      {/*  <Grid container spacing={2}>*/}
      {/*    <Grid item xs={12}>*/}
      {/*      <Typography variant={"h6"}>*/}
      {/*        Raw videos*/}
      {/*      </Typography>*/}
      {/*    </Grid>*/}
      {/*    {viral.data.viralFiles.filter((file) => file.type === 'raw' ).map((file, key) => {*/}
      {/*      return   (*/}
      {/*        <Grid item key={key}>*/}
      {/*          <Box style={{backgroundColor: '#ededed', padding: 20, maxWidth:"400px"}} >*/}
      {/*            <Chip label={`${key+1}`} size="small" style={{marginBottom: 10}} />*/}
      {/*            <ReactPlayer width={'100%'} height={'100%'} controls={true} url={file.streamUrl}/>*/}
      {/*          </Box>*/}
      {/*        </Grid>)*/}
      {/*    })}*/}
      {/*  </Grid>*/}
      {/*</Grid>*/}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant={"h5"}>
            My Viral
          </Typography>
          <StyledChip status={viral.data.status} label={viral.data.status}/>
        </Grid>
          {viral.data.viralFiles.filter((file) => file.type === 'final' ).map((file, key) => {
            return   (
              <Grid item key={key} style={{width: '426px', height:'300px', marginTop:'15px'}}>
                <Card style={{width: '100%', height:'300px'}} raised={true}>
                  <CardContent>
                    <video width={'100%'} height={"240px"} controls={true} src={file.streamUrl}/>
                    <Button disabled={viral.data.status !== 'completed'} variant='outlined' size={"small"} onClick={() => downloadFile(file.fileId)}> <DownloadIcon/> Download </Button>
                  </CardContent>
                </Card>
              </Grid>
            )
          })}
      </Grid>
      {/*<Grid container spacing={2} style={{marginTop:10}}>*/}
      {/*  <Grid item xs={12}>*/}
      {/*    Coming soon:*/}
      {/*  </Grid>*/}
      {/*  <Grid item>*/}
      {/*    <Button disabled variant="contained"><TwitterIcon/> Post to Twitter</Button>*/}
      {/*  </Grid>*/}
      {/*  <Grid item>*/}
      {/*    <Button disabled variant="contained"> <RedditIcon /> Post to Reddit wall</Button>*/}
      {/*  </Grid>*/}
      {/*</Grid>*/}
    </Grid>
  )
}