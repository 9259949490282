import {useQuery} from "@tanstack/react-query";
import {getPosts} from "../../api";
import {
  Alert,
  Card, CardContent,
  Chip,
  Grid,
  Link,
  Tab,
  Table,
  TableBody,
  TableCell, TableFooter,
  TableHead, TablePagination,
  TableRow,
  Tabs,
  useTheme
} from "@mui/material";
import React, {Suspense, useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import RenderMedia from "../create-post/components/render-media";
import RedditIcon from "@mui/icons-material/Reddit";
import TwitterIcon from "@mui/icons-material/Twitter";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import {formatDate, getTimeZone, parseQueryStringIntoPagination} from "../../utils";
import {useStyles} from "../list-virals/list-virals";
import CircularProgress from "@mui/material/CircularProgress";
import {useSearchParams} from "react-router-dom";
import {useGetUser} from "../../hooks/api-hooks/useGetUser";

const PLATFORM_NAME: { [key: number]: string }= {
  1: 'Reddit',
  2: 'Twitter',
  3: 'Redgifs'
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0, pt: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export const History = () => {
  const classes = useStyles();

  // Pagination info
  const [paginationTableCount, setPaginationTableCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const startingTab = searchParams.get('tab');

  const [limit, setLimit] = useState<number>(20);
  const [offset, setOffset] = useState<number>(0);
  const [previous, setPrevious] = useState<string | null>(null);
  const [next, setNext] = useState<string | null>(null);

  const [tabValue, setTabValue] = React.useState(getTabValue(startingTab));
  const [platformQueryId, setPlatformQueryId] = React.useState(1);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const user = useGetUser()

  /**
   * Returns the tab value based on the search params
   * @param platform
   */
  function getTabValue(platform: string | null){
    if(platform === 'reddit'){
      return 0
    }
    if(platform === 'twitter'){
      return 1
    }
    return 0;
  }
  
  /**
   * Returns the platform name from tabs to set in teh search params
   * @param tabs
   */
  function getPlatformFromTabs(tabs: number | null){
    if(tabs === 0){
      return 'reddit'
    }
    if(tabs === 1){
      return 'twitter'
    }
    return 'reddit';
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    const platform = getPlatformFromTabs(newValue);
    setSearchParams(`tab=${platform}`)
    setTabValue(newValue);
  };

  const STATUS = 'posted,failed'
  const DEFAULT_ORDER_KEY = 'scheduledAt:DESC';
  const posts = useQuery(
    [`getPosts`, platformQueryId, STATUS, offset, limit],
    () => getPosts(platformQueryId, limit, offset, STATUS, DEFAULT_ORDER_KEY),
    {
      enabled: true,
      onSuccess: (data) => {
        if (data.count !== paginationTableCount) {
          setPaginationTableCount(data.count);
        }
        setPrevious(data.previous);
        setNext(data.next);
      },
    }
  );

  useEffect(() => {
    if(tabValue === 0){
      setPlatformQueryId(1)
    }
    if(tabValue === 1){
      setPlatformQueryId(2)
    }
  }, [tabValue])

  const setNextPage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setCurrentPage(newPage);
    if (newPage > currentPage) {
      const pagination = parseQueryStringIntoPagination(posts.data?.next || null);
      setLimit(pagination.limit);
      setOffset(pagination.offset);
    } else if (newPage < currentPage) {
      const pagination = parseQueryStringIntoPagination(posts.data?.previous || null);
      setLimit(pagination.limit);
      setOffset(pagination.offset);
    }
  };

  function getPlatformName(id: number) {
    return PLATFORM_NAME[id]
  }

  function ConditionalTrowReddit() {
    if(posts.isLoading || posts.isFetching){
      return (
        <TableRow>
          <TableCell colSpan={5} style={{textAlign: 'center'}}>
            <CircularProgress />
          </TableCell>
        </TableRow>
      )
    }
    if (!posts.isLoading && posts.data && posts.data.results.length > 0) {
      return (
        <>
          {posts.data.results.map((post, i) => (
            <TableRow key={post.postId + i}>
              <TableCell>
                <Chip label={post.status} color={post.status === 'posted' ? 'success' : 'error'}/>
              </TableCell>
              <TableCell>
                <Link target={'_blank'} href={`https://reddit.com/r/${post.metaData.reddit?.subreddit}`} > {post.metaData.reddit?.subreddit} </Link>
              </TableCell>
              <TableCell>
                <Link target={'_blank'} href={`https://reddit.com/${post.platformPostId}`}>
                  {post.metaData.reddit?.title}
                </Link>
              </TableCell>
              <TableCell>
                {formatDate(post.postedAt) || 'Not posted yet'}
              </TableCell>
              <TableCell>
                <RenderMedia post={post}/>
              </TableCell>
            </TableRow>
          ))}
        </>
      );
    }
    return (
      <TableRow>
        <TableCell colSpan={5} style={{textAlign: 'center'}}>No post history</TableCell>
      </TableRow>
    )
  }

  function ConditionalTrowTwitter() {
    if(posts.isLoading || posts.isFetching){
      return (
        <TableRow>
          <TableCell colSpan={5} style={{textAlign: 'center'}}>
            <CircularProgress />
          </TableCell>
        </TableRow>
      )
    }
    if (!posts.isLoading && posts.data && posts.data.results.length > 0) {
      return (
        <>
          {posts.data.results.map((post, i) => (
            <TableRow key={post.postId + i}>
              <TableCell>
                <Chip label={post.status} color={post.status === 'posted' ? 'success' : 'error'}/>
              </TableCell>
              <TableCell>
                <Link target={'_blank'} href={`https://twitter.com/${user.data?.twitterUserName}/status/${post.platformPostId}`}>
                  {post.metaData.twitter?.body}
                </Link>
              </TableCell>
              <TableCell>
                {formatDate(post.postedAt) || 'Not posted yet'}
              </TableCell>
              <TableCell>
                <RenderMedia post={post}/>
              </TableCell>
            </TableRow>
          ))}
        </>
      );
    }
    return (
      <TableRow>
        <TableCell colSpan={5} style={{textAlign: 'center'}}>No post history</TableCell>
      </TableRow>
    )
  }

  function RedditListHistory(){
    if(isMobile){
      return (
        <>
          <Grid container spacing={2} mt={1}>
            {posts.isLoading || posts.isFetching ?
              <Grid item xs={12}>
                <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                  <CircularProgress />
                </Box>
              </Grid>
              : null}
            {!posts.isLoading && posts.data && posts.data.results.length > 0 ? posts.data.results.map((post, i) => {
              return (
                <Grid item xs={12} key={post.postId + i}>
                  <Card elevation={3}>
                    <CardContent>
                      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                        <Link href={`https://reddit.com/${post.platformPostId}`}>
                          <Typography variant="subtitle1" component="h6">
                            {post.metaData.reddit?.title}
                          </Typography>
                        </Link>
                        <Chip label={post.status} color={post.status === 'posted' ? 'success' : 'error'} />
                      </Box>
                      <Typography variant="body2" color="textSecondary">
                        Subreddit - <b>{post.metaData.reddit?.subreddit}</b>
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Posted At - <b> {formatDate(post.postedAt) || 'Not posted yet'}</b>
                      </Typography>
                      <Box mt={2}>
                        <RenderMedia post={post} isMobile={isMobile} />
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              );
            }) : (
              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary" align="center">
                  No post history
                </Typography>
              </Grid>
            )}
          </Grid>
          <TableFooter
            className={`${classes.tableNavigationMobile} ${classes.paperStyle} `}
          >
            <TableRow>
              <TablePagination
                colSpan={12}
                count={paginationTableCount || 0}
                rowsPerPage={limit}
                page={currentPage}
                rowsPerPageOptions={[-1]}
                nextIconButtonProps={{
                  disabled: posts.isFetching || !next,
                }}
                backIconButtonProps={{
                  disabled: posts.isFetching || !previous
                }}
                onPageChange={setNextPage}
              />
            </TableRow>
          </TableFooter>
        </>
      )
    }
    return (
      <Suspense fallback={<h1>loading</h1>}>
        <TablePagination
          className={classes.tableNavigationDesktop}
          colSpan={12}
          count={paginationTableCount || 0}
          rowsPerPage={limit}
          page={currentPage}
          rowsPerPageOptions={[-1]}
          nextIconButtonProps={{
            disabled: posts.isFetching || !next,
          }}
          backIconButtonProps={{
            disabled: posts.isFetching || !previous,
          }}
          onPageChange={setNextPage}
        />
        <Table>
          <TableHead>
            <TableCell>Status</TableCell>
            <TableCell>Subreddit</TableCell>
            <TableCell>Title</TableCell>
            <TableCell>Posted At</TableCell>
            <TableCell>Content</TableCell>
          </TableHead>
          <TableBody>
            <ConditionalTrowReddit />
          </TableBody>
        </Table>
      </Suspense>
    )
  }

  function TwitterHistoryList(){
    if(isMobile){
      return (
        <>
          <Grid container spacing={2} mt={1}>
            {posts.isLoading || posts.isFetching ?
              <Grid item xs={12}>
                <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                  <CircularProgress />
                </Box>
              </Grid>
              : null}
            {!posts.isLoading && posts.data && posts.data.results.length > 0 ? posts.data.results.map((post, i) => {
              return (
                <Grid item xs={12} key={post.postId + i}>
                  <Card elevation={3}>
                    <CardContent>
                      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                        <Link href={`https://twitter.com/${user.data?.twitterUserName}/status/${post.platformPostId}`}>
                          <Typography variant="subtitle1" component="h6">
                            {post.metaData.twitter?.body}
                          </Typography>
                        </Link>
                        <Chip label={post.status} color={post.status === 'posted' ? 'success' : 'error'} />
                      </Box>
                      <Typography variant="body2" color="textSecondary">
                        Posted At - <b> {formatDate(post.postedAt) || 'Not posted yet'}</b>
                      </Typography>
                      <Box mt={2}>
                        <RenderMedia post={post} isMobile={isMobile} />
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              );
            }) : (
              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary" align="center">
                  No post history
                </Typography>
              </Grid>
            )}
          </Grid>
          <TableFooter
            className={`${classes.tableNavigationMobile} ${classes.paperStyle} `}
          >
            <TableRow>
              <TablePagination
                colSpan={12}
                count={paginationTableCount || 0}
                rowsPerPage={limit}
                page={currentPage}
                rowsPerPageOptions={[-1]}
                nextIconButtonProps={{
                  disabled: posts.isFetching || !next,
                }}
                backIconButtonProps={{
                  disabled: posts.isFetching || !previous
                }}
                onPageChange={setNextPage}
              />
            </TableRow>
          </TableFooter>
        </>
      )
    }
    return (
      <Suspense fallback={<h1>loading</h1>}>
        <TablePagination
          className={classes.tableNavigationDesktop}
          colSpan={12}
          count={paginationTableCount || 0}
          rowsPerPage={limit}
          page={currentPage}
          rowsPerPageOptions={[-1]}
          nextIconButtonProps={{
            disabled: posts.isFetching || !next,
          }}
          backIconButtonProps={{
            disabled: posts.isFetching || !previous,
          }}
          onPageChange={setNextPage}
        />
        <Table>
          <TableHead>
            <TableCell>Status</TableCell>
            <TableCell>Title</TableCell>
            <TableCell>Posted At</TableCell>
            <TableCell>Content</TableCell>
          </TableHead>
          <TableBody>
            <ConditionalTrowTwitter />
          </TableBody>
        </Table>
      </Suspense>
    )
  }


  return (
    <>
      <Typography variant={"h5"}>History</Typography>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="icon position tabs example"
      >
        <Tab
          icon={<RedditIcon />}
          iconPosition="start"
          label={
            <>
              Reddit{" "}
              {platformQueryId === 1 ? (
                <Chip
                  sx={{ marginLeft: 1 }}
                  size={"small"}
                  label={posts.data?.count}
                  color={"secondary"}
                />
              ) : null}
            </>
          }
        />
        <Tab
          icon={<TwitterIcon />}
          iconPosition="start"
          label={
            <>
              Twitter / X{" "}
              {platformQueryId === 2 ? (
                <Chip
                  sx={{ marginLeft: 1 }}
                  size={"small"}
                  label={posts.data?.count}
                  color={"secondary"}
                />
              ) : null}
            </>
          }
        />
      </Tabs>
      <Alert severity={"info"} style={{ marginTop: 15 }}>
        Schedules are displayed in your local time zone ({getTimeZone()})
      </Alert>
      <CustomTabPanel value={tabValue} index={0}>
        <RedditListHistory />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={1}>
        <TwitterHistoryList />
      </CustomTabPanel>
    </>
  );
}