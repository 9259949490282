import React, {useCallback, useEffect, useState} from "react";
import { useQuery } from "@tanstack/react-query";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextareaAutosize,
  IconButton,
  Button,
  colors,
  styled,
  Alert, CircularProgress, TextField, FormControl, InputLabel, Select, MenuItem, FormHelperText, useTheme,
} from "@mui/material";
import {
  Delete as DeleteIcon,
  AutoAwesome as AutoAwesomeIcon,
} from "@mui/icons-material";
import {getRedditTitles} from "../../../api";
import { PostScheduleBody } from "../../../api/types";
import Box from "@mui/material/Box";
import RenderCreatePostMedia from "../../create-post/components/render-create-post-media";
import {useTweetRequestContext} from "./tweet-request-provider";
import {useMediaQuery} from "@material-ui/core";
import Typography from "@mui/material/Typography";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";

const { blue, grey } = colors;
const SUBREDDIT_NAME_FOR_TITLE = ['realgirls']
const ReviewComponentTwitter = () => {
  const { tweetRequestContext, setTweetRequestContext } =
    useTweetRequestContext();

  const [tweetFrequency, setTweetFrequency] = useState(2);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const titleQuery = useQuery(
    [`getRedditTitles`, SUBREDDIT_NAME_FOR_TITLE],
    () => getRedditTitles(SUBREDDIT_NAME_FOR_TITLE),
    { enabled: false }
  );

  useEffect(() => {
    (async () => {
      if (tweetRequestContext.length > 0) {
        await titleQuery.refetch();
      }
    })();
  }, []);


  const buildPostRequestContext = useCallback(() => {
    if (!titleQuery.isLoading) {
      const tempContext = tweetRequestContext.map((post) => {
        post.postSchedule = [...post.postSchedule];
        post.tweetFrequency = tweetFrequency;
        if(post.postSchedule.length < 1){
          const newSchedule: PostScheduleBody = {
            platformId: 2,
            metaData: {
              twitter: {
                body: getRandomTitle(SUBREDDIT_NAME_FOR_TITLE[0]),
              },
            },
          };
          post.postSchedule.push(newSchedule);
        }
        return post;
      });

      setTweetRequestContext(tempContext);
    }
  }, [titleQuery.isLoading, tweetFrequency]);

  useEffect(() => {
    buildPostRequestContext();
  }, [buildPostRequestContext, tweetFrequency]);

  const getRandomTitle = (subreddit: string): string => {
    const subredditKey = subreddit.toLowerCase();
    if (titleQuery.data && titleQuery.data[subredditKey]) {
      const index = Math.floor(
        Math.random() * titleQuery.data[subredditKey].length
      );
      return titleQuery.data[subredditKey][index];
    }
    return "example title";
  };

  const handleRemove = useCallback(
    (postIndex: number, scheduleIndex: number) => {
      const updatedPosts = [...tweetRequestContext];
      updatedPosts[postIndex].postSchedule.splice(scheduleIndex, 1);
      setTweetRequestContext(updatedPosts);
    },
    [tweetRequestContext]
  );
  const TextareaContainer = styled("div")({
    position: "relative",
    width: "322px", // Increased to accommodate the 2px red border you specified earlier
  });

  const StyledButton = styled(Button)(({ theme }) => ({
    fontSize: "0.75rem",
    backgroundColor: blue[100],
    border: "none",
    borderRadius: "5px",
    padding: "5px 8px",
    cursor: "pointer",
    position: "absolute",
    right: "8px",
    bottom: "8px",
    transition: "background-color 0.3s",
    "&:hover": {
      backgroundColor: blue[400],
      color: "#fff",
    },
  }));

  const StyledTextarea = styled(TextareaAutosize)(
    ({ theme }) => `
    width: 320px;
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 12px;
    border-radius: 12px 12px 0 12px;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${
      theme.palette.mode === "dark" ? grey[900] : grey[50]
    };
  
    &:hover {
      border-color: ${blue[400]};
    }
  
    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${
        theme.palette.mode === "dark" ? blue[500] : blue[200]
      };
    }
  
    // firefox
    &:focus-visible {
      outline: 0;
    }
  `
  );

  interface TitleProps{
    schedule: PostScheduleBody
  }
  
  function Title({ schedule }: TitleProps) {
    const [title, setTitle] = useState(schedule.metaData.twitter?.body);

    useEffect(() => {
      // @ts-ignore
      schedule.metaData.twitter.body = title
    }, [title, schedule])

    return (
      <TextareaContainer>
        <StyledTextarea
          minRows={5}
          placeholder={"Title"}
          value={title}
          defaultValue={schedule.metaData.twitter?.body}
          onChange={(e) => {
            // @ts-ignore
            setTitle(e.target.value);
          }}
        />
        <StyledButton
          onClick={(e) => {
            const newTitle = getRandomTitle(SUBREDDIT_NAME_FOR_TITLE[0]);
            if (newTitle) {
              // @ts-ignore
              setTitle(newTitle);
            }
          }}
        >
          New Title <AutoAwesomeIcon />
        </StyledButton>
      </TextareaContainer>
    );
  }
  
  function RenderTitleRow() {
    if(!tweetRequestContext.length){
      return (
        <TableRow>
          <TableCell>
            <CircularProgress />
          </TableCell>
        </TableRow>
      )
    }

    const rows = tweetRequestContext.reduce<JSX.Element[]>((acc, post, postIndex) => {
      const rowElements = post.postSchedule.map((schedule, scheduleIndex) => {
        return (
          <TableRow key={`${postIndex}-${scheduleIndex}`}>
            <TableCell>
              <Title schedule={schedule}/>
            </TableCell>
            <TableCell>
              <RenderCreatePostMedia post={post} key={postIndex} />
            </TableCell>
            <TableCell>
              <IconButton
                onClick={() => handleRemove(postIndex, scheduleIndex)}
              >
                <DeleteIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        );
      });

      return acc.concat(rowElements);
    }, []);

    return <>{rows}</>;

  }

  return (
    <>
      <Box hidden={tweetRequestContext.length > 0} mb={5} mt={3}>
        <Alert severity="warning">Please upload some media</Alert>
      </Box>
      <Typography variant="body1" sx={{display: 'flex', flexDirection: 'row', gap: 2}} mb={1} mt={2}>
        <TipsAndUpdatesIcon color={'info'}/> Reposted tweets will spread organically throughout a week.
      </Typography>
      <Box mt={5} mb={2}>
        <FormControl fullWidth={isMobile} variant="outlined">
          <InputLabel id="tweet-frequency-label">Tweet Frequency</InputLabel>
          <Select
            labelId="tweet-frequency-label"
            value={tweetFrequency}
            onChange={(e) => {
              console.log('bro', Number(e.target.value))
              setTweetFrequency(Number(e.target.value))}
          }
            label="Tweet Frequency"
          >
            <MenuItem value={1}>1 time</MenuItem>
            <MenuItem value={2}>2 times (Default) </MenuItem>
            <MenuItem value={4}>4 times</MenuItem>
          </Select>
          <FormHelperText>
            Select how many times you want each tweet to be re-posted.
          </FormHelperText>
        </FormControl>
      </Box>
      <Box hidden={tweetRequestContext.length < 1}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: "600px" }}>Title</TableCell>
              <TableCell>Content</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <RenderTitleRow />
          </TableBody>
        </Table>
      </Box>
    </>
  );
};

export const MemoizedReviewComponentTwitter = React.memo(ReviewComponentTwitter);
