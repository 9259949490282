// FeatureUsageBar.tsx
import React from "react";
import { Box, Typography, LinearProgress } from "@mui/material";
import { makeStyles } from '@material-ui/core/styles';

interface FeatureUsageBarProps {
  featureName: string;
  usage: number;
  limit: number;
}

const useStyles = makeStyles({
  featureName: {
    fontWeight: "bold",
  },
  usageLimitContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
});

const FeatureUsageBar: React.FC<FeatureUsageBarProps> = ({
  featureName,
  usage,
  limit,
}) => {
  const classes = useStyles();

  const percentage = (usage / limit) * 100;

  return (
    <Box style={{width: "100%"}}>
      <Typography className={classes.featureName} variant="subtitle2">
        {featureName}
      </Typography>
      <Box>
        <LinearProgress variant="determinate" value={percentage} />
      </Box>
      <Box>
        <Typography variant="body2">{usage} / {limit} </Typography>
      </Box>
    </Box>
  );
};

export default FeatureUsageBar;
