import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {useEffect, useState} from "react";
import { useMutation } from "@tanstack/react-query";
import {
  submitViralToRedgifs as submitViralToRedgifsApi,
  SubmitViralToRedgifsBody,
} from "../../../api";
import toast from "react-hot-toast";
import LoadingButton from "@mui/lab/LoadingButton";
import Autocomplete from "@mui/material/Autocomplete";
import { TAGS } from "../../../constants/redgif-tags";

interface SubmitToRedgifsProps {
  isOpen: boolean;
  viralId: string;
  thumbnailUrl: string;
  handleOnClose: () => void;
}

export default function SubmitToRedgifsDialog({
  isOpen,
  thumbnailUrl,
  handleOnClose,
  viralId,
}: SubmitToRedgifsProps) {
  const submitViralToRedgifsMutation = useMutation({
    mutationFn: (submitViralToRedgifsBody: SubmitViralToRedgifsBody) =>
      submitViralToRedgifsApi(submitViralToRedgifsBody),
    onSuccess: (result) => {
      toast.success("Posted successfully!");
      handleOnClose();
    },
  });

  const [tags, setTags] = useState<string[]>([""]);

  const handleSubmitToRedgifs = (
    viralId: string,
    tags: string[]
  ) => {
    submitViralToRedgifsMutation.mutate({ viralId, tags });
  };

  const handleOnCloseLocal = () => {
    setTags([""]);
    handleOnClose();
  };

  const handleOnTagChange = (
    event: React.SyntheticEvent<HTMLElement>,
    newTags: string[]
  ) => {
    setTags(newTags)
  };

  const isTagLengthValid = tags.length <= 10 && tags.length >= 3;

  return (
    <>
      <Dialog open={isOpen} onClose={handleOnCloseLocal} fullWidth>
        <DialogTitle>Post to Redgifs</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{display: 'flex', justifyContent: 'center', backgroundColor: 'black', mb: 2}}>
            <img
              style={{ height: 200, objectFit: "cover" }}
              src={thumbnailUrl}
              alt="thumbnail-image"
            />
          </DialogContentText>
          <Autocomplete
            disabled={submitViralToRedgifsMutation.isLoading}
            // @ts-ignore
            onChange={handleOnTagChange}
            multiple
            id="tags-standard"
            options={TAGS}
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Select Redgif Tags"
                placeholder="Redgfs Tags"
                helperText={!isTagLengthValid ? "Must have minimum of 3 tags. Max 10" : ''}
                error={!isTagLengthValid}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={submitViralToRedgifsMutation.isLoading}
            onClick={handleOnCloseLocal}
          >
            Cancel
          </Button>
          <LoadingButton
            disabled={!isTagLengthValid }
            loading={submitViralToRedgifsMutation.isLoading}
            onClick={() => handleSubmitToRedgifs(viralId, tags)}
          >
            Submit
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
