import { useQuery } from "@tanstack/react-query";
import { getStatistics } from "../../api";
import { UserStatisticsResponse } from "../../api/types";

export const useGetStatistics = () => {
  return useQuery<UserStatisticsResponse>(
    ["getStatistics"],
    () => getStatistics(),
    {
      cacheTime: 1800,
    }
  );
};
