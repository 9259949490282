import { Grid, Typography, Box, Chip } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import AnalyticsIcon from '@mui/icons-material/Analytics';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import CheckIcon from "@mui/icons-material/Check";
import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import { useGetPlans } from "../../hooks/api-hooks/useGetPlans";
import { useGetUser } from "../../hooks/api-hooks/useGetUser";
import { useMutation } from "@tanstack/react-query";
import * as viralApi from "../../api";
import LoadingButton from "@mui/lab/LoadingButton";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";

const useStyles = makeStyles((theme) => ({
  gridItem: {
    maxWidth: "400px !important",
  },
  recommended: {
    position: "absolute",
    top: 9, // adjust this value to control vertical placement
    left: "50%", // center horizontally
    transform: "translateX(-50%)", // center horizontally
    zIndex: 2, // stack on top of other elements
  },
  tierContainer: {
    position: "relative",
    padding: 20,
    borderRadius: 4,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    paddingTop: 30, // add padding to avoid overlap with chip
  },
  recommendedBorder: {
    border: "1px solid #5D5DFF",
  },
  normalBorder: {
    border: "1px solid #ECECEC",
  },
  planRow: {
    padding: ".70em .70em .70em 2em",
    display: "flex",
    alignItems: "center",
    width: "100%",
    backgroundColor: "#eeeeee",
    marginBottom: 1,
  },
  planRowIncluded: {
    padding: ".70em .70em .70em 2em",
    display: "flex",
    alignItems: "center",
    width: "100%",
    backgroundColor: "lighten(#eeeeee, 99%)",
    marginBottom: 1,
  },
  featureIncluded: {
    padding: ".70em .70em .70em 2em",
    display: "flex",
    alignItems: "flex-start",
    width: "100%",
  },
  dollarSymbol: {
    verticalAlign: "super",
    marginRight: "0.25rem",
    fontSize: "1rem !important",
    fontWeight: 400,
    color: "#16324f",
  },
}));

export const Pricing = () => {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const getPlans = useGetPlans();
  const user = useGetUser();

  const createCheckoutMutation = useMutation({
    mutationFn: (subscriptionId: number) =>
      viralApi.createCheckoutSession(subscriptionId),
  });

  const handleChoosePlan = async (subscriptionId: number) => {
    // @ts-ignore
    setSelectedPlan(subscriptionId);
    const response = await createCheckoutMutation.mutateAsync(subscriptionId);
    setSelectedPlan(null);
    window.location.href = response.url;
  };

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center" direction="column">
      <Box
        display="flex"
        justifyContent="center"
        flexDirection={"column"}
        mt={5}
        mb={5}
      >
        <Typography variant={"h2"} style={{ fontWeight: 400 }} align="center">
          Get more paid subs
        </Typography>
        <Typography variant="h5" align="center" color="textSecondary" paragraph>
          Invest in your growth with the perfect plan
        </Typography>
      </Box>
      <Grid container item xs={12} spacing={3} justifyContent="center">
        {getPlans.data?.map((tier) => (
          <>
            <Grid
              className={!isMobile ? classes.gridItem : ""}
              item
              xs={12}
              md={6}
              xl={4}
              key={tier.id}
              style={{ position: "relative" }}
            >
              {tier.code === "viral_daddy_influencer" && (
                <Chip
                  style={{ backgroundColor: "#5D5DFF", color: "white" }}
                  className={classes.recommended}
                  icon={<StarIcon style={{ color: "white" }} />}
                  label="Recommended"
                />
              )}
              <Box
                className={
                  tier.code === "viral_daddy_influencer"
                    ? `${classes.tierContainer} ${classes.recommendedBorder}`
                    : `${classes.tierContainer} ${classes.normalBorder}`
                }
              >
                <Typography variant="h4" style={{}}>
                  {tier.label}
                </Typography>
                <Typography variant="h3" style={{ fontWeight: 700 }}>
                  <span className={classes.dollarSymbol}>$</span>
                  <span style={{ color: "#5D5DFF" }}> {tier.monthlyPrice}</span>
                </Typography>
                <Typography variant="caption">per month</Typography>
                <LoadingButton
                  disabled={user.data?.subscription.id === tier.id}
                  fullWidth={true}
                  loading={
                    createCheckoutMutation.isLoading && selectedPlan === tier.id
                  }
                  variant="contained"
                  color="primary"
                  style={{ marginTop: "10px" }}
                  size={"large"}
                  onClick={() => handleChoosePlan(tier.id)}
                >
                  {user.data?.subscription.id === tier.id
                    ? "current plan"
                    : "choose plan"}
                </LoadingButton>
                <Box className={classes.featureIncluded} mt={1}>
                  <Typography variant={'body1'} style={{fontWeight:500}}>
                    Features included:
                  </Typography>
                </Box>
                <Box style={{ width: "100%", marginTop: 10 }}>
                  {tier.features.map((feature, i) => (
                    <Box className={classes.planRow}>
                      <CheckIcon
                        color="success"
                        style={{ marginRight: "1em" }}
                      />
                      <Typography
                        variant="body2"
                        key={feature.name}
                        component={"span"}
                      >
                        {feature.description.split("%s").map((part, index) =>
                          index < feature.description.split("%s").length - 1 ? (
                            <>
                              <span>{part}</span>
                              <strong>{feature.limit.toString()}</strong>
                            </>
                          ) : (
                            part
                          )
                        )}
                      </Typography>
                    </Box>
                  ))}
                  <Box className={classes.planRowIncluded}>
                    <DynamicFeedIcon
                      color="action"
                      style={{ marginRight: "1em" }}
                    />
                    <Typography variant="body2" component={"span"}>
                      Organic Posting Algorithm
                    </Typography>
                  </Box>
                  <Box className={classes.planRowIncluded}>
                    <AnalyticsIcon
                      color={"action"}
                      style={{ marginRight: "1em" }}
                    />
                    <Typography variant="body2" component={"span"}>
                      Viral Score & Analytics
                    </Typography>
                  </Box>
                  <Box className={classes.planRowIncluded}>
                    <SearchOutlinedIcon
                      color="action"
                      style={{ marginRight: "1em" }}
                    />
                    <Typography variant="body2" component={"span"}>
                      Subreddit Discovery
                    </Typography>
                  </Box>
                  <Box className={classes.planRowIncluded}>
                    <WorkspacesIcon
                      color="action"
                      style={{ marginRight: "1em" }}
                    />
                    <Typography variant="body2" component={"span"}>
                      Subreddit Group Management
                    </Typography>
                  </Box>
                  {tier.id !== 1 ?  <Box className={classes.planRowIncluded}>
                    <TrendingUpIcon
                      color="action"
                      style={{ marginRight: "1em" }}
                    />
                    <Typography variant="body2" component={"span"}>
                      Trend Analysis
                    </Typography>
                  </Box> : null }

                </Box>
              </Box>
            </Grid>
          </>
        ))}
      </Grid>
      <Grid container item xs={12} spacing={3} justifyContent="center" mt={5}>
        <Grid item xs={12} md={6}>
          <Typography variant={"h4"} style={{ fontWeight: 400 }}>
            Frequently Asked Questions
          </Typography>

          {/* Individual FAQ */}
          <Box mt={3}>
            <Typography variant="h6" gutterBottom>
              Do I need a credit card to try?
            </Typography>
            <Typography variant="body1" paragraph>
              No, you can sign up for Viraldaddy without a credit card. On the Free plan, you'll have access to all the free tier features listed in the plans above.
            </Typography>
          </Box>

          <Box mt={3}>
            <Typography variant="h6" gutterBottom>
              Can I change my plan later?
            </Typography>
            <Typography variant="body1" paragraph>
              Yes! You can change your plan at any time. If you're on a monthly plan then you'll be charged for the next month at the new plan's price. If you're on a free plan and you want to upgrade to a paid plan then you'll be charged for the next month at the new plan's price.
            </Typography>
          </Box>

          <Box mt={3}>
            <Typography variant="h6" gutterBottom>
              Is my account safe using Viraldaddy?
            </Typography>
            <Typography variant="body1" paragraph>
              Yep! Viraldaddy requires the minimum Reddit/X/Instagram permissions to do its job. To see which permissions (or "scopes") Viraldaddy requests, check out our Privacy Policy, which lists them out including reasons why that scope is required.
            </Typography>
          </Box>

          <Box mt={3}>
            <Typography variant="h6" gutterBottom>
              Can my account be banned by using Viraldaddy?
            </Typography>
            <Typography variant="body1" paragraph>
              Utilizing a Reddit post scheduler doesn't violate Reddit's terms of service, but spamming does. Viraldaddy assists content creators in distributing their content to appropriate subreddits at optimal times, without resorting to spamming.
            </Typography>
            <Typography variant="body1" paragraph>
              To ensure users don't spam Reddit, Viraldaddy incorporates features that safeguard your account.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};
