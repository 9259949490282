import {useQuery} from "@tanstack/react-query";
import {getPosts, updatePostSchedule} from "../../api";
import {
  Alert,
  Chip, IconButton,
  Link,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs, useTheme, Grid, Card, CardContent, TextField, Button, TablePagination, TableFooter
} from "@mui/material";
import React, {Suspense, useEffect, useState} from "react";
import {PostResponse} from "../../api/types";
import Typography from "@mui/material/Typography";
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import EditIcon from '@mui/icons-material/Edit';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import RenderMedia from "../create-post/components/render-media";
import RedditIcon from "@mui/icons-material/Reddit";
import CircularProgress from '@mui/material/CircularProgress';
import TwitterIcon from "@mui/icons-material/Twitter";
import Box from "@mui/material/Box";
import DeleteIcon from '@mui/icons-material/Delete';
import {useDialog} from "../../components/app-dialog-provider";
import {queryClient} from "../../app";
import useMediaQuery from "@mui/material/useMediaQuery";
import {Link as LinkRouter, useSearchParams} from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import { useStyles } from "../list-virals/list-virals";
import {getTimeZone, parseQueryStringIntoPagination} from "../../utils";

const PLATFORM_NAME: { [key: number]: string }= {
  1: 'Reddit',
  2: 'Twitter',
  3: 'Redgifs'
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0, pt: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

type RenderTitleCellProps = {
  post: PostResponse;
};
const RenderTitleCell = ({ post }: RenderTitleCellProps) => {
  const [editingPostScheduleId, setEditingPostScheduleId] = useState<string | null>(null);
  const [tempTitle, setTempTitle] = useState<string>("");

  const startEditing = (postScheduleId: string, post: PostResponse) => {
    let currentTitle: string = '';
    if(post.platformId === 1){
      currentTitle = post.metaData?.reddit?.title || '';
    }
    if(post.platformId === 2){
      currentTitle = post.metaData?.twitter?.body || '';
    }
    setEditingPostScheduleId(postScheduleId);
    setTempTitle(currentTitle);
  };

  const stopEditing = () => {
    setEditingPostScheduleId(null);
    setTempTitle("");
  };

  const saveTitle = async (postId: string, postScheduleId: string, platformId: number) => {
    let updatedMetaData = {};

    if (platformId === 1) {
      updatedMetaData = {
        // @ts-ignore
        reddit: {
          title: tempTitle
        }
      };
    } else if (platformId === 2) {
      updatedMetaData = {
        // @ts-ignore
        twitter: {
          body: tempTitle
        }
      };
    }

    await updatePostSchedule(postId, postScheduleId, { metaData: updatedMetaData });
    stopEditing();
    await queryClient.invalidateQueries({
      queryKey: ["getPosts", post.platformId],
    });
  };

  if (editingPostScheduleId === post.postScheduleId) {
    return (
      <Box display="flex" flexDirection="column" alignItems="flex-start">
        <TextField
          type="text"
          size="small"
          value={tempTitle}
          fullWidth={true}
          onChange={(e) => setTempTitle(e.target.value)}
        />
        <Box mt={1} display="flex" flexDirection="row" gap={1}>
          <Button size="small" onClick={() => saveTitle(post.postId, post.postScheduleId, post.platformId)}>Save</Button>
          <Button size="small" onClick={stopEditing}>Cancel</Button>
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'flex-start',
      flexWrap: 'nowrap'
    }}>
      <IconButton
        size="small"
        sx={{ flexShrink: 0 }}
        onClick={() => startEditing(post.postScheduleId, post)}
      >
        <EditIcon fontSize="small" />
      </IconButton>
      <Box
        sx={{
          ml: 1,
          whiteSpace: 'normal',
          wordBreak: 'break-word',
          flexShrink: 1
        }}
      >
        {post.platformId === 1 ? post.metaData.reddit?.title : post.platformId === 2 ? post.metaData.twitter?.body : null}
      </Box>
    </Box>
  );
};

export const ListPosts = () => {
  const classes = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();
  const startingTab = searchParams.get('tab');

  // Pagination info
  const [paginationTableCount, setPaginationTableCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [limit, setLimit] = useState<number>(20);
  const [offset, setOffset] = useState<number>(0);
  const [previous, setPrevious] = useState<string | null>(null);
  const [next, setNext] = useState<string | null>(null);

  const [tabValue, setTabValue] = React.useState(getTabValue(startingTab));
  const [platformQueryId, setPlatformQueryId] = React.useState(1);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { showDialog, hideDialog } = useDialog();

  // Get search params
  const STATUS = 'scheduled,created';
  const DEFAULT_ORDER_KEY = 'scheduledAt:ASC';
  const posts = useQuery(
    [`getPosts`, platformQueryId, STATUS, offset, limit],
    () => getPosts(platformQueryId, limit, offset, STATUS, DEFAULT_ORDER_KEY),
    {
      enabled: true,
      onSuccess: (data) => {
        if (data.count !== paginationTableCount) {
          setPaginationTableCount(data.count);
        }
        setPrevious(data.previous);
        setNext(data.next);
      },
    }
  );

  useEffect(() => {
    if(tabValue === 0){
      setPlatformQueryId(1)
    }
    if(tabValue === 1){
      setPlatformQueryId(2)
    }
  }, [tabValue])


  /**
   * Returns the tab value based on the search params
   * @param platform
   */
  function getTabValue(platform: string | null){
    if(platform === 'reddit'){
      return 0
    }
    if(platform === 'twitter'){
      return 1
    }
    return 0;
  }

  /**
   * Returns the platform name from tabs to set in teh search params
   * @param tabs
   */
  function getPlatformFromTabs(tabs: number | null){
    if(tabs === 0){
      return 'reddit'
    }
    if(tabs === 1){
      return 'twitter'
    }
    return 'reddit';
  }


  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    const platform = getPlatformFromTabs(newValue);
    setSearchParams(`tab=${platform}`)
    setTabValue(newValue);
  };

  const handleSendNow = (postId: string, postScheduleId: string, platformId: number) => {
    showDialog({
      title: `Confirm Schedule: Send Post Now?`,
      content: (
        <Typography>
          Your post will be prioritized and typically sent within the next 5 minutes. Would you like to proceed?
        </Typography>
      ),
      actions: [
        {
          label: "confirm",
          onClick: async () => {
            const now  = new Date();
            await updatePostSchedule(postId, postScheduleId, {
              scheduledAt: now.toISOString(),
            });
            await queryClient.invalidateQueries({
              queryKey: ["getPosts", platformId],
            });
            hideDialog();
          },
        },
        {
          label: "cancel",
          onClick: () => {
            hideDialog();
          },
        },
      ],
    });
  }

  const handleUnschedule = (postId: string, postScheduleId: string, platformId: number) => {
    showDialog({
      title: `Unschedule Post`,
      content: (
        <Typography>
          {" "}
          Are you sure you want to unschedule your post?{" "}
        </Typography>
      ),
      actions: [
        {
          label: "confirm",
          onClick: async () => {
            await updatePostSchedule(postId, postScheduleId, {
              status: "deleted",
            });
            await queryClient.invalidateQueries({
              queryKey: ["getPosts", platformId],
            });
            hideDialog();
          },
        },
        {
          label: "cancel",
          onClick: () => {
            hideDialog();
          },
        },
      ],
    });
  };

  const setNextPage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setCurrentPage(newPage);
    if (newPage > currentPage) {
      const pagination = parseQueryStringIntoPagination(posts.data?.next || null);
      setLimit(pagination.limit);
      setOffset(pagination.offset);
    } else if (newPage < currentPage) {
      const pagination = parseQueryStringIntoPagination(posts.data?.previous || null);
      setLimit(pagination.limit);
      setOffset(pagination.offset);
    }
  };

  function RedditListPosts() {
    if(isMobile){
      return (
        <>
          <Grid container spacing={2} mt={1}>
            {posts.isLoading || posts.isFetching ?
              <Grid item xs={12}>
                <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                  <CircularProgress />
                </Box>
              </Grid>
              : null}
            {!posts.isLoading && posts.data && posts.data.results?.length > 0 ? posts.data.results?.map((post, i) => {
              const utcDate = new Date(post.scheduledAt);
              const localDateStr = utcDate.toLocaleString();

              return (
                <Grid item xs={12} key={post.postId + i}>
                  <Card elevation={3}>
                    <CardContent>
                      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                        <Typography variant="subtitle1" component="h6" gutterBottom>
                          <RenderTitleCell post={post}/>
                        </Typography>
                        <Tooltip title={'Unschedule and remove this post'}>
                          <IconButton
                            onClick={() => handleUnschedule(post.postId, post.postScheduleId, post.platformId)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={'Attempt to post as soon as possible'}>
                          <IconButton
                            onClick={() => handleSendNow(post.postId, post.postScheduleId, post.platformId)}
                          >
                            <SendOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                      <Typography variant="body2" color="textSecondary" gutterBottom>
                        Scheduled At - <b>{localDateStr}</b>
                      </Typography>
                      <Typography variant="body2" color="textSecondary" gutterBottom>
                        Subreddit - <b>{post.metaData.reddit?.subreddit}</b>
                      </Typography>
                      <Box mt={2}>
                        <RenderMedia post={post} isMobile={isMobile} />
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              );
            }) : (
              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary" align="center">
                  No upcoming posts. <LinkRouter to={'new/upload'}> Create a post </LinkRouter>
                </Typography>
              </Grid>
            )}
          </Grid>
          <TableFooter
            className={`${classes.tableNavigationMobile} ${classes.paperStyle} `}
          >
            <TableRow>
              <TablePagination
                colSpan={12}
                count={paginationTableCount || 0}
                rowsPerPage={limit}
                page={currentPage}
                rowsPerPageOptions={[-1]}
                nextIconButtonProps={{
                  disabled: posts.isFetching || !next,
                }}
                backIconButtonProps={{
                  disabled: posts.isFetching || !previous
                }}
                onPageChange={setNextPage}
              />
            </TableRow>
          </TableFooter>
        </>
      );
    }
    return (
      <Suspense fallback={<h1>loading</h1>}>
        <TablePagination
          className={classes.tableNavigationDesktop}
          colSpan={12}
          count={paginationTableCount || 0}
          rowsPerPage={limit}
          page={currentPage}
          rowsPerPageOptions={[-1]}
          nextIconButtonProps={{
            disabled: posts.isFetching || !next,
          }}
          backIconButtonProps={{
            disabled: posts.isFetching || !previous
          }}
          onPageChange={setNextPage}
        />
        <Table>
          <TableHead>
            <TableCell sx={{ width: '20%' }}>Scheduled At</TableCell>
            <TableCell sx={{ width: '20%' }}>Title</TableCell>
            <TableCell sx={{ width: '20%' }}>Content</TableCell>
            <TableCell sx={{ width: '20%' }}>Subreddit</TableCell>
            <TableCell sx={{ width: '20%' }}></TableCell>
          </TableHead>
          <TableBody>
            {posts.isLoading || posts.isFetching ?
              <TableRow>
                <TableCell colSpan={5} style={{textAlign: 'center'}}>
                  <CircularProgress />
                </TableCell>
              </TableRow> : null}
            {!posts.isLoading && posts.data && posts.data?.results?.length > 0 && posts.data.results.map((post) => {
              const utcDate = new Date(post.scheduledAt);
              // Convert to local date string
              const localDateStr = utcDate.toLocaleString();
              return (
                <TableRow>
                  <TableCell>
                    {localDateStr}
                  </TableCell>
                  <TableCell>
                    <RenderTitleCell post={post}/>
                  </TableCell>
                  <TableCell>
                    <RenderMedia post={post} isMobile={isMobile}/>
                  </TableCell>
                  <TableCell>
                    <Link target={'_blank'} href={`https://reddit.com/r/${post.metaData.reddit?.subreddit}`} > {post.metaData.reddit?.subreddit} </Link>
                  </TableCell>
                  <TableCell>
                    <Tooltip title={'Unschedule and remove this post'}>
                      <IconButton
                        onClick={() => handleUnschedule(post.postId, post.postScheduleId, post.platformId)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={'Attempt to post as soon as possible'}>
                      <IconButton
                        onClick={() => handleSendNow(post.postId, post.postScheduleId, post.platformId)}
                      >
                        <SendOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              )
            })}
            {!posts.isLoading && posts.data && posts.data.results?.length < 1 ?
              <TableRow>
                <TableCell colSpan={5} style={{textAlign: 'center'}}> No upcoming posts. <LinkRouter to={'new/upload'}> Create a post </LinkRouter> </TableCell>
              </TableRow>
              : null}
          </TableBody>
        </Table>
      </Suspense>
    )
  }

  function TwitterPosts(){
    if(isMobile){
      return(
        <>
          <Grid container spacing={2} mt={1}>
            {posts.isLoading || posts.isFetching ?
              <Grid item xs={12}>
                <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                  <CircularProgress />
                </Box>
              </Grid>
              : null}
            {!posts.isLoading && posts.data && posts.data.results?.length > 0 ? posts.data.results?.map((post, i) => {
              const utcDate = new Date(post.scheduledAt);
              const localDateStr = utcDate.toLocaleString();

              return (
                <Grid item xs={12} key={post.postId + i}>
                  <Card elevation={3}>
                    <CardContent>
                      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                        <Typography variant="subtitle1" component="h6" gutterBottom>
                          <RenderTitleCell post={post}/>
                        </Typography>
                        <Tooltip title={'Unschedule and remove this post'}>
                          <IconButton
                            onClick={() => handleUnschedule(post.postId, post.postScheduleId, post.platformId)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={'Attempt to post as soon as possible'}>
                          <IconButton
                            onClick={() => handleSendNow(post.postId, post.postScheduleId, post.platformId)}
                          >
                            <SendOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                      <Typography variant="body2" color="textSecondary" gutterBottom>
                        Scheduled At - <b>{localDateStr}</b>
                      </Typography>
                      <Typography variant="body2" color="textSecondary" gutterBottom>
                        Subreddit - <b>{post.metaData.reddit?.subreddit}</b>
                      </Typography>
                      <Box mt={2}>
                        <RenderMedia post={post} isMobile={isMobile} />
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              );
            }) : (
              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary" align="center">
                  No upcoming posts. <LinkRouter to={'new/upload'}> Create a post </LinkRouter>
                </Typography>
              </Grid>
            )}
          </Grid>
          <TableFooter
            className={`${classes.tableNavigationMobile} ${classes.paperStyle} `}
          >
            <TableRow>
              <TablePagination
                colSpan={12}
                count={paginationTableCount || 0}
                rowsPerPage={limit}
                page={currentPage}
                rowsPerPageOptions={[-1]}
                nextIconButtonProps={{
                  disabled: posts.isFetching || !next,
                }}
                backIconButtonProps={{
                  disabled: posts.isFetching || !previous
                }}
                onPageChange={setNextPage}
              />
            </TableRow>
          </TableFooter>
        </>
      )
    }
    return (
      <Suspense fallback={<h1>loading</h1>}>
        <TablePagination
          className={classes.tableNavigationDesktop}
          colSpan={12}
          count={paginationTableCount || 0}
          rowsPerPage={limit}
          page={currentPage}
          rowsPerPageOptions={[-1]}
          nextIconButtonProps={{
            disabled: posts.isFetching || !next,
          }}
          backIconButtonProps={{
            disabled: posts.isFetching || !previous
          }}
          onPageChange={setNextPage}
        />
        <Table>
          <TableHead>
            <TableCell sx={{ width: '20%' }}>Scheduled At</TableCell>
            <TableCell sx={{ width: '20%' }}>Body</TableCell>
            <TableCell sx={{ width: '20%' }}>Content</TableCell>
            <TableCell sx={{ width: '20%' }}></TableCell>
          </TableHead>
          <TableBody>
            {posts.isLoading || posts.isFetching ?
              <TableRow>
                <TableCell colSpan={5} style={{textAlign: 'center'}}>
                  <CircularProgress />
                </TableCell>
              </TableRow> : null}
            {!posts.isLoading && posts.data?.results?.map((post) => {
              const utcDate = new Date(post.scheduledAt);
              // Convert to local date string
              const localDateStr = utcDate.toLocaleString();
              return (
                <TableRow>
                  <TableCell>
                    {localDateStr}
                  </TableCell>
                  <TableCell>
                    <RenderTitleCell post={post}/>
                  </TableCell>
                  <TableCell>
                    <RenderMedia post={post} isMobile={isMobile}/>
                  </TableCell>
                  <TableCell>
                    <Tooltip title={'Unschedule and remove this post'}>
                      <IconButton
                        onClick={() => handleUnschedule(post.postId, post.postScheduleId, post.platformId)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={'Attempt to post as soon as possible'}>
                      <IconButton
                        onClick={() => handleSendNow(post.postId, post.postScheduleId, post.platformId)}
                      >
                        <SendOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              )
            })}
            {!posts.isLoading && posts.data && posts.data.results?.length < 1 ?
              <TableRow>
                <TableCell colSpan={5} style={{textAlign: 'center'}}> No upcoming tweets. <LinkRouter to={'new/upload'}> Create a tweet </LinkRouter> </TableCell>
              </TableRow>
              : null}
          </TableBody>
        </Table>
      </Suspense>
    )
  }

  return (
    <>
      <Typography variant={"h5"}>Upcoming Posts</Typography>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="icon position "
      >
        <Tab
          icon={<RedditIcon />}
          iconPosition="start"
          label={
            <>
              Reddit { platformQueryId === 1 ? <Chip sx={{marginLeft: 1}} size={'small'} label={posts.data?.count} color={'secondary'}/> : null }
            </>
          }
        />
        <Tab
          icon={<TwitterIcon />}
          iconPosition="start"
          label={
            <>
              Twitter / X { platformQueryId === 2 ? <Chip sx={{marginLeft: 1}} size={'small'} label={posts.data?.count} color={'secondary'}/> : null }
            </>
          }
        />
      </Tabs>
      <Alert severity={"info"} style={{ marginTop: 15 }}>
        Schedules are displayed in your local time zone ({getTimeZone()})
      </Alert>
      <Alert severity={"info"} style={{ marginTop: 15 }} icon={<TipsAndUpdatesIcon/>}>
        The more content you schedule, the more organic your feed will appear
      </Alert>
      <CustomTabPanel value={tabValue} index={0}>
        <RedditListPosts />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={1}>
        <TwitterPosts />
      </CustomTabPanel>
    </>
  );
}