import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

interface BetaDialogProps {
  open: boolean;
  handleClose: () => void;
}

export const AdultContentDialog: React.FC<BetaDialogProps> = ({ open, handleClose }) => {
  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    const hasAcknowledged = sessionStorage.getItem('shuttingDownAcknowledged');
    if (!hasAcknowledged) {
      setShowDialog(true);
    }
  }, []);

  const handleAcknowledge = () => {
    sessionStorage.setItem('shuttingDownAcknowledged', 'true');
    setShowDialog(false);
    handleClose();
  };

  return (
    <Dialog open={showDialog && open} onClose={handleAcknowledge}>
      <DialogTitle>ViralDaddy.ai is Shutting Down</DialogTitle>
      <DialogContent>
        <DialogContentText>
          We regret to inform you that ViralDaddy.ai is shutting down. Thank you for your support and understanding.
          <br/><br/>
          We are excited to announce our new platform, Neoface.ai. Click the link below to continue your journey with
          us.
          <br/><br/>
          <Button
            component="a"
            href="https://neoface.ai"
            color="primary"
            variant="contained"
            target="_blank"
            rel="noopener noreferrer"
          >
            Visit Neoface.ai
          </Button>
          <p>
            If you still want access to your old data, you will have until June 30th, 2024 to download it.
            All remaining subscriptions will be canceled and feature access will be limited.
          </p>
          <br/><br/>
          <i>The ViralDaddy Team</i>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleAcknowledge} color="primary">
          Acknowledge
        </Button>
      </DialogActions>
    </Dialog>
  );
};
