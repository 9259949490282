import Box from "@mui/material/Box";
import VerifiedIcon from "@mui/icons-material/Verified";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import SearchIcon from "@mui/icons-material/Search";
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import WhatshotIcon from "@mui/icons-material/Whatshot";
import {
  Alert,
  CircularProgress,
  FormControl,
  InputLabel,
  Link,
  List,
  ListItemText,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {SubredditResponse} from "../../../api/types";
import Typography from "@mui/material/Typography";
import LaunchIcon from "@mui/icons-material/Launch";
import ListItem from "@mui/material/ListItem";
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import {getRedditFlair, getRedditImages, getRedditRules, queryGroups,} from "../../../api";
import {useDialog} from "../../../components/app-dialog-provider";
import {makeStyles, useMediaQuery} from "@material-ui/core";
import {useTheme} from "@material-ui/core/styles";
import {useGetTopSubreddits} from "../../../hooks/api-hooks/useGetTopSubreddits";
import {usePostRequestContext} from "./post-request-provider";
import Divider from "@mui/material/Divider";
import {useQuery} from "@tanstack/react-query";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import {RedditSelect} from "../../../components/reddit-select";
import {useGetUser} from "../../../hooks/api-hooks/useGetUser";

interface RedditCustomizeProps {
  children?: React.ReactNode;
  subreddits: SubredditResponse[];
}

const useStyles = makeStyles(() => ({
  subredditLink: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none !important",
    textDecorationColor: "none",
    color: "black !important",
    backgroundColor: "#f5f5f5",
    "&:hover": {
      color: "gray !important",
    },
  },
  subredditRules: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none !important",
    textDecorationColor: "none",
    cursor: "pointer",
    marginLeft: "8px !important",
  },
}));
export const RedditCustomize = (props: RedditCustomizeProps) => {
  const theme = useTheme();
  const classes = useStyles();
  const navigate = useNavigate();
  const [searchInputValue, setSearchInputValue] = useState<string | null>(null);
  const { selectedSubreddits, setSelectedSubreddits, selectedFlair, setSelectedFlair } = usePostRequestContext();
  const [verifyFilter, setVerifyFilter] = useState(false);
  const [missingFlair, setMissingFlair] = useState(false);
  const [nsfwFilter, setNsfwFilter] = useState(false);
  const [subredditTopFilter, setSubredditTopFilter] = useState(false);
  const [filteredSubreddits, setFilteredSubreddits] = useState<
    SubredditResponse[]
  >([]);
  const { showDialog, hideDialog } = useDialog();

  const myTopSubredditsQuery = useGetTopSubreddits();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const user = useGetUser()

  const flairsQuery = useQuery(
    [`getRedditFlairs`],
    () => getRedditFlair(selectedSubreddits.map((s) => s.name.toLowerCase())),
    { enabled: false }
  );

  const subredditGroupQuery = useQuery(
    ["subredditGroupQuery"],
    () => queryGroups(),
    {
      enabled: true
    }
  );

  useEffect(() => {
    (async () => {
      if (selectedSubreddits.length > 0) {
        await flairsQuery.refetch();
      }
    })();
  }, [selectedSubreddits]);

  useEffect(() => {
    updateFilteredSubreddits();
  }, [props.subreddits, verifyFilter, nsfwFilter, subredditTopFilter, searchInputValue]);

  const updateFilteredSubreddits = () => {
    const lowercasedSearchInput = searchInputValue?.toLowerCase();
    let customSubAdded = false;
    const filtered = props.subreddits.filter((subreddit) => {
      if (subreddit.name.toLowerCase() === lowercasedSearchInput) {
        customSubAdded = true;
      }

      // If all filters are off, show all
      if (!verifyFilter && !nsfwFilter && !subredditTopFilter) {
        return true;
      }

      let verifyCondition = verifyFilter ? !subreddit.verify : true;
      let nsfwCondition = nsfwFilter ? subreddit.over18 : true;
      let subredditTopCondition = subredditTopFilter ? subreddit.top : true;

      // Return based on the combination of the three filters
      return verifyCondition && nsfwCondition && subredditTopCondition;
    });

    if(user.data?.redditUserName){
      filtered.unshift({
        name: `u_${user.data?.redditUserName}`,
        verify: false,
        over18: null,
        top: null,
        subs: 0,
      });
    }

    if (searchInputValue && !customSubAdded) {
      filtered.unshift({
        name: searchInputValue,
        verify: false,
        over18: null,
        top: null,
        subs: 0,
      });
    }

    setFilteredSubreddits(filtered);
  };

  const handleShowWhatsHot = async (subreddit: string) => {
    showDialog({
      title: `Trending examples from ${subreddit}`,
      actions: [
        {
          label: "Confirm",
          onClick: () => {
            hideDialog();
          },
        },
      ],
      fetchData: async () => {
        const images = await getRedditImages(subreddit);
        if (!images || !images.data.children.length) {
          return (
            <>
              <SearchIcon /> No rules found
            </>
          );
        }
        return images.data.children.map((subreddit) => {
          return (
            <>
              <img src={subreddit.data.thumbnail} alt={"reddit thumbnail"} />
            </>
          );
        });
      },
    });
  };

  const handleShowRules = async (subreddit: string) => {
    showDialog({
      title: `r/${subreddit} Rules`,
      actions: [
        {
          label: "Confirm",
          onClick: () => {
            hideDialog();
          },
        },
      ],
      fetchData: async () => {
        const rules = await getRedditRules(subreddit);
        return (
          <List>
            {rules.map((rule, index) => (
              <React.Fragment key={index}>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary={rule.title}
                    secondary={
                      <Typography variant="body2" color="textSecondary">
                        {rule.description}
                      </Typography>
                    }
                  />
                </ListItem>
                {index !== rules.length - 1 && <Divider />}
              </React.Fragment>
            ))}
            {!rules.length ? (
              <ListItem>
                <ListItemText>
                  {" "}
                  <SearchIcon /> No rules found{" "}
                </ListItemText>
              </ListItem>
            ) : null}
          </List>
        );
      },
    });
  };

  const setSelected = (list: SubredditResponse[]) => {
    const mergedMap = new Map();

    // Populating Map from the list
    list.forEach(item => {
      mergedMap.set(item.name, item);
    });

    // Adding items from selectedSubreddits if they exist in the list (mergedMap)
    selectedSubreddits.forEach(item => {
      if (mergedMap.has(item.name)) {
        // If the item exists in the list, keep it
        mergedMap.set(item.name, item);
      } // If the item doesn't exist in the list, it won't be added, effectively removing it
    });

    const mergedList = Array.from(mergedMap.values());
    setSelectedSubreddits(mergedList);
  };

  const handleAddSubredditGroups = (subreddits: string[]) => {
    const subredditsToAdd = subreddits.map(subreddit => {
      // Converting the subreddit name to lower case for case-insensitive comparison
      const lowerCaseName = subreddit.toLowerCase();

      // Searching for the subreddit in subreddits.data
      const foundSubreddit = filteredSubreddits.find(s => s.name.toLowerCase() === lowerCaseName);

      // If the subreddit is found, return its data
      // If not, return a new object with default values
      return foundSubreddit
        ? foundSubreddit
        : {
          name: subreddit,
          category: "custom",
          over18: null,
          verify: false,
          subs: 0,
        };
    });

    setSelected(subredditsToAdd);
  }

  const handleGetMyTopSubreddits = async () => {
    const filteredSubredditObjects: SubredditResponse[] = [];
    const uniqueNames = new Set<string>();

    // Adding names from already selected subreddits to the Set to ensure no duplicates later
    selectedSubreddits.forEach((subreddit) => uniqueNames.add(subreddit.name));
    if (myTopSubredditsQuery.data) {
      for (const str of myTopSubredditsQuery.data) {
        if (!uniqueNames.has(str)) {
          // Check if the name is not already in the set
          const selectedSub = {
            name: str,
            verify: false,
            over18: null,
            subs: 0,
            category: "custom",
          };
          filteredSubredditObjects.push(selectedSub);
          uniqueNames.add(str); // Add the name to the set
        }
      }
      setSelected([...selectedSubreddits, ...filteredSubredditObjects]);
    }
  };

  const handleFlairSelect = (subreddit: string, flairId: string) => {
    setSelectedFlair({
      ...selectedFlair,
      [subreddit.toLowerCase()]: flairId
    })
  }


  const checkMissingFlair = () => {
    let isMissing = false;
    if(flairsQuery.data){
      isMissing = selectedSubreddits.some(sub => flairsQuery.data[sub.name.toLowerCase()] && !selectedFlair[sub.name.toLowerCase()])
    }
    return isMissing;
  }


  const handleNext = () => {
    const isMissingFlair = checkMissingFlair()
    if(isMissingFlair){
      setMissingFlair(true)
      return;
    }else{
      setMissingFlair(false)
      navigate('/posts/new/review')
    }
  }

  // @ts-ignore
  return (
    <>
    <Box
      style={{ padding: "0 !important" }}
      component="ol"
      sx={{ listStyle: "none", padding: 0, margin: 0, color: "gray" }}
    >
      {/* Select your subreddits */}
      <Typography
        component="li"
        sx={{ marginBottom: 5, paddingLeft: 1, fontSize: "1.2em" }}
      >
        <Typography
          mb={2}
          variant={"body1"}
          sx={{ fontSize: "1.2em", color: "gray" }}
        >
          Select your subreddits
        </Typography>
        <RedditSelect
          nsfwFilter={nsfwFilter}
          setNsfwFilter={setNsfwFilter}
          verifyFilter={verifyFilter}
          setVerifyFilter={setVerifyFilter}
          subreddits={filteredSubreddits}
          setFilteredSubreddits={setFilteredSubreddits}
          searchInputValue={searchInputValue}
          selectedSubreddits={selectedSubreddits}
          setSelectedSubreddits={setSelectedSubreddits}
          updateFilteredSubreddits={updateFilteredSubreddits}
          subredditsTopFilter={subredditTopFilter}
          setSubredditsTopFilter={setSubredditTopFilter}
          setSearchInputValue={setSearchInputValue}
        />
        <Typography mt={3}>
          <Stack direction={"column"} gap={2}>
            <Link
              onClick={handleGetMyTopSubreddits}
              style={{ textDecoration: "none", cursor: "pointer" }}
            >
              Add your past subreddits
              {myTopSubredditsQuery.isLoading ? (
                <CircularProgress size={"1em"} />
              ) : null}
            </Link>
            {subredditGroupQuery.data && subredditGroupQuery.data.length ? (
              <FormControl sx={{ maxWidth: 200 }}>
                <InputLabel id="subreddit-group-select-label">
                  Add Subreddit Group
                </InputLabel>
                <Select
                  labelId="subreddit-group-select-label"
                  id="subreddit-group-select"
                  label="Subreddit Group"
                  onChange={(e, value) => {
                    handleAddSubredditGroups(e.target.value as string[]);
                  }}
                >
                  {subredditGroupQuery.data?.map((sg) => {
                    return <MenuItem value={sg.subreddits}>{sg.name}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            ) : (
              <Box>
                Try creating a{" "}
                <RouterLink to={"/groups"}> Subreddit Group</RouterLink> to
                quickly add frequent subreddits you use
              </Box>
            )}
          </Stack>
        </Typography>
      </Typography>

      {/* Check your subreddits */}

      <Typography
        hidden={selectedSubreddits.length < 1}
        component="li"
        sx={{ marginBottom: 1, paddingLeft: 1, fontSize: "1.2em" }}
      >
        <Typography
          mb={2}
          variant={"body1"}
          sx={{ fontSize: "1.2em", color: "gray" }}
        >
          Check your subreddits
        </Typography>
        <List>
          {selectedSubreddits.map((subreddit) => {
            return (
              <ListItem>
                <ListItemText
                  primary={
                    <Stack
                      direction={"row"}
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Typography>
                        <b>
                          <Link
                            target={"_blank"}
                            className={classes.subredditLink}
                            href={`https://reddit.com/r/${subreddit.name}`}
                          >
                            r/{subreddit.name}
                            <LaunchIcon
                              style={{ marginLeft: 8 }}
                              target={"_blank"}
                              fontSize={"small"}
                              color={"action"}
                            />
                          </Link>
                        </b>
                      </Typography>
                      <Tooltip
                        title={subreddit.verify ? "Verify" : "No Verify"}
                      >
                        {subreddit.verify ? (
                          <VerifiedIcon color={"primary"} />
                        ) : (
                          <VerifiedOutlinedIcon />
                        )}
                      </Tooltip>
                      <Typography>
                        <Link
                          onClick={() => handleShowWhatsHot(subreddit.name)}
                          className={classes.subredditRules}
                        >
                          Trending <WhatshotIcon />
                        </Link>
                      </Typography>
                      <Typography>
                        <Link
                          onClick={() => handleShowRules(subreddit.name)}
                          className={classes.subredditRules}
                        >
                          Rules <FlagOutlinedIcon />
                        </Link>
                      </Typography>
                    </Stack>
                  }
                  secondary={
                    <>
                      {flairsQuery.data &&
                      flairsQuery.data[subreddit.name.toLowerCase()] ? (
                        <Stack
                          direction={"row"}
                          justifyContent="flex-start"
                          alignItems="center"
                          style={{ marginTop: 10 }}
                        >
                          <FormControl
                            fullWidth={isMobile}
                            size={"small"}
                            sx={{ width: isMobile ? "100%" : "256px" }}
                          >
                            <InputLabel id="demo-simple-select-label">
                              Select Flair
                            </InputLabel>
                            <Select
                              labelId="select-flair-label"
                              id="select-flair"
                              value={
                                selectedFlair[subreddit.name.toLowerCase()]
                              }
                              onChange={(e, value) => {
                                handleFlairSelect(
                                  subreddit.name,
                                  e.target.value as string
                                );
                              }}
                            >
                              {flairsQuery.data[
                                subreddit.name.toLowerCase()
                              ].map((flair) => {
                                return (
                                  <MenuItem value={flair.flairId}>
                                    {flair.flairText}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Stack>
                      ) : null}
                    </>
                  }
                />
              </ListItem>
            );
          })}
        </List>
      </Typography>
    </Box>
    {missingFlair ? <Alert severity={'error'}>Missing required flairs</Alert> : null}
    <Button style={{marginTop: 15}} variant={"contained"} onClick={() => handleNext()}>
      Next
    </Button>
  </>
  );
};
