import React, {ChangeEvent, Dispatch, SetStateAction} from 'react';
import {Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {ViralLengthPreset} from "../../../api/types";
import Tooltip from "@mui/material/Tooltip";

interface LengthPresetProps {
  lengthPreset:  ViralLengthPreset;
  setLengthPreset: Dispatch<SetStateAction<ViralLengthPreset>>;
}
const LengthPreset: React.FC<LengthPresetProps> = ({lengthPreset, setLengthPreset}) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLengthPreset(event.target.value as ViralLengthPreset);
  };

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">
        <Box style={{display:'flex', alignItems: 'center'}}>
          Length Preset
          <Tooltip title={"ViralDaddy wil determine the best parts of your video and cut them up into this preset time"}>
            <HelpOutlineIcon style={{marginLeft: 10}} fontSize={'small'}/>
          </Tooltip>
        </Box>
      </FormLabel>
      <RadioGroup row aria-label="length-preset" name="length-preset" value={lengthPreset} onChange={handleChange}>
        <FormControlLabel value={ViralLengthPreset.SHORT} control={<Radio />} label={<span>Short <span style={{color: 'gray'}}> | 5 seconds</span></span>} />
        <FormControlLabel value={ViralLengthPreset.MEDIUM} control={<Radio />} label={<span>Medium <span style={{color: 'gray'}}> | 10 seconds</span></span>} />
        <FormControlLabel value={ViralLengthPreset.LONG} control={<Radio />} label={<span>Long <span style={{color: 'gray'}}> | 15 seconds</span></span>} />
      </RadioGroup>
    </FormControl>
  );
};

export default LengthPreset;