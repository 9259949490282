import {
  Alert,
  Box, CircularProgress,
  Grid,
  Hidden,
  LinearProgress,
  Menu,
  MenuItem,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { useMutation, useQuery } from "@tanstack/react-query";

import { downloadViral, getVirals } from "../../api";
import { timeAgo } from "../../utils";
import { StyledChip } from "./components/styled-status-chip";
import { useDeleteViral } from "../../hooks";
import Button from "@mui/material/Button";
import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import { Link, useNavigate } from "react-router-dom";
import DownloadIcon from "@mui/icons-material/Download";
import BrokenImageIcon from "@mui/icons-material/BrokenImage";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RedditIcon from "@mui/icons-material/Reddit";
import TwitterIcon from "@mui/icons-material/Twitter";
import Badge from "@mui/material/Badge";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { ViralLengthPreset, ViralResponse } from "../../api/types";
import LoadingButton from "@mui/lab/LoadingButton";
import { useGetUser } from "../../hooks/api-hooks/useGetUser";
import { WebSocketContext } from "../../context";
import SubmitToRedditDialog from "./components/submit-to-reddit-dialog";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BrandingWatermarkIcon from "@mui/icons-material/BrandingWatermark";
import CustomizedBadge from "./components/styled-badge";
import { AlertDialog } from "../../components/alert-dialog";
import { PlayVideoModal } from "./components/play-video-modal";
import SvgIcon from "@mui/material/SvgIcon";
import SubmitToTwitterDialog from "./components/submit-twitter-dialog";
import SubmitToRedgifsDialog from "./components/submit-redgifs-dialog";
import {ConfirmDialog} from "../../components/confirm-dialog";

export const useStyles = makeStyles((theme) =>
  createStyles({
    tableNavigationDesktop: {
      display: "flex !important",
      justifyContent: "right",
      padding: "0 !important",
      borderBottom: "0 !important",
      "& div": {
        padding: "0 !important",
      },
    },
    tableNavigationMobile: {
      position: "fixed",
      display: "flex !important",
      justifyContent: "center",
      alignItems: "center",
      bottom: 0,
      left: 0,
      width: "100%",
      backgroundColor: theme.palette.background.paper,
    },
    paperStyle: {
      boxShadow: `${theme.shadows[3]} !important`,
      borderRadius: theme.shape.borderRadius,
    },
    image: {
      height: "110px",
      objectFit: "cover",
    },
    imageSm: {
      width: "100%",
      height: "85px",
      objectFit: "cover",
    },
    boxVideo: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      maxWidth: "72em",
      width: "100%",
    },
    video: {
      width: "100%",
    },
  })
);

interface ColumnHeader {
  createdAt: string;
  length: string;
  duration: string;
  status: ViralResponse["status"];
  postedTo: string;
  name: string;
  watermarked: string;
  action: string;
}
interface HeadCell {
  disablePadding: boolean;
  id?: keyof ColumnHeader;
  label: string | number;
  numeric: boolean;
}

const poll = (items: ViralResponse[] | undefined): number | boolean => {
  const pollingInterval = 15000; // 15 seconds
  const maxPollingTime = 8 * 60 * 1000; // 10 minutes in milliseconds
  const beginPolling = 15 * 1000;

  if (!items) return pollingInterval;

  // Check if any item has status "processing" and its createdAt is more than 1 minute but less than 10 minutes from now
  const now = new Date();
  const processingItems = items.filter((item) => item.status === "processing");

  if (processingItems.length < 1) {
    return false;
  }

  const lessThanBeginPolling = processingItems.find((item) => {
    const elapsedTime = now.getTime() - new Date(item.createdAt).getTime();
    return elapsedTime <= beginPolling;
  });

  const moreThanBeginPollingLessThan10 = processingItems.find((item) => {
    const elapsedTime = now.getTime() - new Date(item.createdAt).getTime();
    return elapsedTime >= beginPolling && elapsedTime < maxPollingTime;
  });

  // If there is no item to poll, stop polling and resolve the promise
  if (lessThanBeginPolling && !moreThanBeginPollingLessThan10) {
    return beginPolling;
  }

  if (moreThanBeginPollingLessThan10) {
    return 15000;
  }
  return false;
};

export const ListVirals = () => {
  const { socketMessageUpdate } = useContext(WebSocketContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const user = useGetUser();
  const classes = useStyles();
  const navigate = useNavigate();
  const deleteViral = useDeleteViral();

  const [isDeleteViralOpen, setIsDeleteViralOpen] = useState(false);
  const [deleteViralId, setDeleteViralId] = useState('');

  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [selectedVideoViralId, setSelectedVideoViralId] = useState<string>("");

  const [isNotifyAlert, setIsNotifyAlert] = React.useState(false);

  const [reachedMax, setReachedMax] = useState<boolean>(false);

  // Submit to reddit
  const [isSubmitRedditDialogOpen, setIsSubmitRedditDialogOpen] =
    useState<boolean>(false);
  const [currentPostToRedditThumbnail, setCurrentPostToRedditThumbnail] =
    useState<string>("");
  const [currentPostToRedditViralId, setCurrentPostToRedditViralId] =
    useState<string>("");
  const [isMissingRedditLinked, setMissingRedditLinked] =
    useState<boolean>(false);

  // Submit to Twitter
  const [isSubmitTwitterDialogOpen, setIsSubmitTwitterDialogOpen] =
    useState<boolean>(false);
  const [currentPostToTwitterThumbnail, setCurrentPostToTwitterThumbnail] =
    useState<string>("");
  const [currentPostToTwitterViralId, setCurrentPostToTwitterViralId] =
    useState<string>("");
  const [isMissingTwitterLinked, setMissingTwitterLinked] =
    useState<boolean>(false);

  // Submit to Redgifs
  const [isSubmitRedgifsDialogOpen, setIsSubmitRedgifsDialogOpen] =
    useState<boolean>(false);
  const [currentPostToRedgifsThumbnail, setCurrentPostToRedgifsThumbnail] =
    useState<string>("");
  const [currentPostToRedgifsViralId, setCurrentPostToRedgifsViralId] =
    useState<string>("");
  const [isMissingRedgifsLinked, setMissingRedgifsLinked] =
    useState<boolean>(false);

  const [paginationTableCount, setPaginationTableCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedActionRowId, setSelectedActionRowId] = useState<string | null>(
    null
  );
  const [selectedActionAnchor, setSelectedActionAnchor] =
    React.useState<null | HTMLElement>(null);
  const [limit, setLimit] = useState<number>(20);
  const [offset, setOffset] = useState<number>(0);
  const [query, setQuery] = useState<string | null>(null);

  const viralsQuery = useQuery({
    // @ts-ignore
    queryKey: [`getVirals`, { query }],
    cacheTime: 0,
    queryFn: () => getVirals(limit, offset, query),
    onSuccess: (data) => {
      if (data.count !== paginationTableCount) {
        setPaginationTableCount(data.count);
      }
      if (
        data.results?.length <= limit &&
        currentPage === Math.ceil(data.count / limit) - 1
      ) {
        setReachedMax(true);
      } else {
        setReachedMax(false);
      }
    },
    refetchInterval: (data, query) => {
      return poll(data?.results);
    },
  });

  useEffect(() => {
    if (socketMessageUpdate?.data === "statusUpdate") {
      viralsQuery.refetch();
    }
  }, [socketMessageUpdate]);

  useEffect(() => {
    const hasSeenMessage = sessionStorage.getItem("seenNotifyMeOnComplete");
    const processingItems = viralsQuery.data?.results.some(
      (item) => item.status === "processing"
    );
    if (processingItems && !hasSeenMessage) {
      setIsNotifyAlert(true);
    }
  }, [user.data?.notifyOnComplete, viralsQuery.data?.results]);

  const downloadViralMutation = useMutation({
    mutationFn: (viralId: string) => downloadViral(viralId),
    onSuccess: (result) => {
      setTimeout(() => {
        window.open(result, "_blank");
      });
    },
  });

  const headCells: readonly Partial<HeadCell>[] = [
    {},
    {
      id: "name",
      disablePadding: true,
      label: "Name",
    },
    {
      id: "watermarked",
      disablePadding: true,
      label: "Watermarked",
    },
    {
      id: "length",
      disablePadding: true,
      label: "Length",
    },
    {
      id: "createdAt",
      disablePadding: false,
      label: "Created at",
    },
    {
      id: "status",
      disablePadding: false,
      label: "Status",
    },
    {},
    {
      id: "action",
      disablePadding: false,
      label: "Total:",
    },
  ];

  const handleDeleteViral = (viralId: string) => {
    deleteViral.mutate(viralId, {
      onSuccess: () => {
        viralsQuery.refetch();
      },
    });
  };

  const handleOpenDeleteViral = (viralId: string) => {
    setDeleteViralId(viralId);
    setIsDeleteViralOpen(true);
  }

  const handleDownloadViral = (viralId: string) => {
    downloadViralMutation.mutate(viralId);
  };

  const handleVideoClick = (viralId: string) => {
    setSelectedVideoViralId(viralId);
    setVideoModalOpen(true);
  };

  // Reddit Handlers
  const handleSubmitRedditOpen = (thumbnailUrl: string, viralId: string) => {
    if (!user.data?.isRedgifsLinked) {
      setMissingRedgifsLinked(true);
      return;
    }

    if (!user.data?.isRedditLinked) {
      setMissingRedditLinked(true);
      return;
    }
    setIsSubmitRedditDialogOpen(true);
    setCurrentPostToRedditThumbnail(thumbnailUrl);
    setCurrentPostToRedditViralId(viralId);
    setSelectedActionRowId(null);
  };

  const handleSubmitRedditClose = () => {
    setIsSubmitRedditDialogOpen(false);
    viralsQuery.refetch();
  };

  // Twitter Handlers
  const handleSubmitTwitterOpen = (thumbnailUrl: string, viralId: string) => {
    if (!user.data?.isTwitterLinked || !user.data?.isTwitterV1Linked) {
      setMissingTwitterLinked(true);
      return;
    }

    setIsSubmitTwitterDialogOpen(true);
    setCurrentPostToTwitterThumbnail(thumbnailUrl);
    setCurrentPostToTwitterViralId(viralId);
    setSelectedActionRowId(null);
  };

  const handleSubmitTwitterClose = () => {
    setIsSubmitTwitterDialogOpen(false);
    viralsQuery.refetch();
  };

  // Redgifs Handlers
  const handleSubmitRedgifsOpen = (thumbnailUrl: string, viralId: string) => {
    if (!user.data?.isTwitterLinked) {
      setMissingRedgifsLinked(true);
      return;
    }

    setIsSubmitRedgifsDialogOpen(true);
    setCurrentPostToRedgifsThumbnail(thumbnailUrl);
    setCurrentPostToRedgifsViralId(viralId);
    setSelectedActionRowId(null);
  };

  const handleSubmitRedgifsClose = () => {
    setIsSubmitRedgifsDialogOpen(false);
    viralsQuery.refetch();
  };

  const setNextPage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setCurrentPage(newPage);

    if (newPage > currentPage) {
      // @ts-ignore
      setQuery(viralsQuery.data?.next || null);
    } else if (newPage < currentPage) {
      // @ts-ignore
      setQuery(viralsQuery.data?.previous || null);
    }
  };

  const getLengthPresetIcons = (preset: string) => {
    if (!preset) return null;
    if (preset === ViralLengthPreset.SHORT) {
      return <Typography sx={{ color: "action.active" }}>5s</Typography>;
    }
    if (preset === ViralLengthPreset.MEDIUM) {
      return <Typography sx={{ color: "action.active" }}>10s</Typography>;
    }
    if (preset === ViralLengthPreset.LONG) {
      return <Typography sx={{ color: "action.active" }}>15</Typography>;
    }
  };

  return (
    <>
      <ConfirmDialog
        open={isDeleteViralOpen}
        okText={'Yes'}
        cancelText={'No'}
        title={"Delete Viral?"}
        message={
          "Are you sure you want to delete this viral?"
        }
        onClose={() => {setIsDeleteViralOpen(false); setDeleteViralId('')}}
        onCancel={() => {setIsDeleteViralOpen(false); setDeleteViralId('')}}
        onOk={() => handleDeleteViral(deleteViralId)}
        confirmColor={'error'}
      />
      <AlertDialog
        open={isMissingRedditLinked}
        title={"Please link your reddit account"}
        message={
          "You must first link your reddit account before you can post to reddit"
        }
        onClose={() => setMissingRedditLinked(false)}
      />
      <AlertDialog
        title={"Please link your redgifs account"}
        open={isMissingRedgifsLinked}
        message={
          <div>
            You must first link your redgifs account before you can post to
            redgifs
            <Link to={"/settings/checklist"}> Go to profile</Link>
          </div>
        }
        onClose={() => setMissingRedgifsLinked(false)}
      />
      <AlertDialog
        title={"Please link your twitter account"}
        open={isMissingTwitterLinked}
        message={
          <div>
            You must first link your twitter account (Both Account & Media Upload checklist item) before you can post your viral to
            twitter
            <Link to={"/settings/checklist"}> Go to checklist to link </Link>
          </div>
        }
        onClose={() => setMissingTwitterLinked(false)}
      />

      <SubmitToRedditDialog
        isOpen={isSubmitRedditDialogOpen}
        thumbnailUrl={currentPostToRedditThumbnail}
        handleOnClose={handleSubmitRedditClose}
        viralId={currentPostToRedditViralId}
      />
      <SubmitToTwitterDialog
        isOpen={isSubmitTwitterDialogOpen}
        thumbnailUrl={currentPostToTwitterThumbnail}
        handleOnClose={handleSubmitTwitterClose}
        viralId={currentPostToTwitterViralId}
      />
      <SubmitToRedgifsDialog
        isOpen={isSubmitRedgifsDialogOpen}
        thumbnailUrl={currentPostToRedgifsThumbnail}
        handleOnClose={handleSubmitRedgifsClose}
        viralId={currentPostToRedgifsViralId}
      />
      <Typography sx={{ mb: 2 }} variant={"h5"}>
        My Virals
      </Typography>
      {isNotifyAlert ? (
        <Alert
          color={'info'}
          action={
          <>
            <Button
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setIsNotifyAlert(false);
                sessionStorage.setItem('seenNotifyMeOnComplete', 'true')
              }}
            >
              Got it!
            </Button>
          </>

          }
          sx={{ mb: 1 }}
        >
          Processing could take 2-10 minutes depending on a number of factors. <Link to={'/settings'}>Add your email and turn on notifications in settings</Link> and we'll notify you when it's complete!
        </Alert>
      ) : null}

      {isMobile ? (
        <Button
          sx={{ mb: 2 }}
          fullWidth={true}
          variant={"outlined"}
          color={"primary"}
          startIcon={<AddCircleOutlineIcon />}
          onClick={() => navigate("new")}
        >
          Create Viral
        </Button>
      ) : null}
      {!isMobile ? (
        <TablePagination
          className={classes.tableNavigationDesktop}
          colSpan={12}
          count={paginationTableCount || 0}
          rowsPerPage={limit}
          page={currentPage}
          rowsPerPageOptions={[-1]}
          SelectProps={{
            disabled: reachedMax,
          }}
          nextIconButtonProps={
            reachedMax
              ? {
                  disabled: reachedMax,
                }
              : undefined
          }
          onPageChange={setNextPage}
        />
      ) : null}
      <TableContainer component={Paper} style={{ marginBottom: 40 }}>
        <Table aria-label="custom pagination table">
          <Hidden mdDown>
            <TableHead>
              <TableRow>
                {/*<TableCell padding="checkbox">*/}
                {/*  <Checkbox*/}
                {/*    color="primary"*/}
                {/*    inputProps={{*/}
                {/*      'aria-label': 'delete box',*/}
                {/*    }}*/}
                {/*  />*/}
                {/*</TableCell>*/}
                {headCells.map((headCell, i) => (
                  <TableCell key={i}>
                    <b>{headCell.label}</b>
                    {headCell.id === "action" ? (
                      <Badge
                        style={{ paddingLeft: 20 }}
                        badgeContent={viralsQuery.data?.count}
                        color="primary"
                      />
                    ) : null}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
          </Hidden>
          <TableBody >
            {
              viralsQuery.isLoading ? (
                  <TableRow>
                    <TableCell align="center" colSpan={headCells.length}>
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) :
              (viralsQuery.data?.results?.length ?? [].length) > 0 ? (
              viralsQuery.data?.results.map((row) => {
                const redditSubmission = row.submissions.reduce(
                  (acc, curr) => {
                    if (curr.provider === "reddit") {
                      acc.count = (acc.count || 0) + 1;
                    }
                    return acc;
                  },
                  { count: 0 }
                );

                const twitterSubmission = row.submissions.reduce(
                  (acc, curr) => {
                    if (curr.provider === "twitter") {
                      acc.count = (acc.count || 0) + 1;
                    }
                    return acc;
                  },
                  { count: 0 }
                );

                const redgifsSubmissions = row.submissions.reduce(
                  (acc, curr) => {
                    if (curr.provider === "redgifs") {
                      acc.count = (acc.count || 0) + 1;
                    }
                    return acc;
                  },
                  { count: 0 }
                );
                const { thumbnailUrl, status } = row;
                const isComplete = status === "completed";
                const isProcessing = status === "processing";
                const isFailed = status === "failed";
                const BrokenImage = (
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#f2f2f2",
                    }}
                  >
                    <BrokenImageIcon
                      color={"action"}
                      fontSize={"large"}
                      style={{ height: 110 }}
                    />
                  </Box>
                );
                const SkeletonImage = <Skeleton height={110} width="100%" />;
                const ThumbnailImage = (className: any) => (
                  <img
                    className={className}
                    src={thumbnailUrl}
                    alt="thumbnail-image"
                  />
                );

                let thumbnail = BrokenImage;
                let thumbnailSmall = BrokenImage;

                if (thumbnailUrl) {
                  if (isComplete) {
                    thumbnail = ThumbnailImage(classes.image);
                    thumbnailSmall = ThumbnailImage(classes.imageSm);
                  } else if (isProcessing) {
                    thumbnail = SkeletonImage;
                    thumbnailSmall = SkeletonImage;
                  } else if (isFailed) {
                    thumbnail = BrokenImage;
                    thumbnailSmall = BrokenImage;
                  }
                }

                const Duration = getLengthPresetIcons(
                  row.metaData!.lengthPreset
                );
                return (
                  <TableRow key={row.id}>
                    {/*<TableCell padding="checkbox">*/}
                    {/*  <Checkbox*/}
                    {/*    color="primary"*/}
                    {/*    inputProps={{*/}
                    {/*      'aria-label': 'select all virals',*/}
                    {/*    }}*/}
                    {/*  />*/}
                    {/*</TableCell>*/}
                    <Hidden mdUp>
                      <TableCell>
                        <Grid
                          container
                          spacing={.5}
                          alignItems={"center"}
                          justifyContent={"space-between"}
                        >
                          <Grid item xs={12}>
                            <Box display={'flex'} justifyContent={'space-between'}>
                              <Typography variant={"body1"}>
                                {row.name || "My viral"}
                              </Typography>
                              {row.status === "processing" ? (
                                <LinearProgress
                                  style={{ marginTop: 10, width: 91 }}
                                />
                              ) : (
                                <StyledChip
                                  label={row.status}
                                  status={row.status}
                                  size={"small"}
                                />
                              )}
                            </Box>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              handleVideoClick(row.id);
                            }}
                          >
                            {isFailed ? (
                              thumbnailSmall
                            ) : (
                              <Box
                                style={{
                                  position: "relative",
                                }}
                              >
                                {thumbnailSmall}
                                <Box
                                  style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                  }}
                                >
                                  <PlayCircleOutlineIcon
                                    style={{ color: "white" }}
                                    fontSize={"large"}
                                  />
                                </Box>
                              </Box>
                            )}
                          </Grid>
                          <Grid item>
                            <Box style={{ display: "flex", gap: 24 }}>
                              {row.metaData &&
                              row.metaData["includeWatermark"] ? (
                                <BrandingWatermarkIcon
                                  fontSize={"small"}
                                  color={"primary"}
                                />
                              ) : (
                                <BrandingWatermarkIcon
                                  fontSize={"small"}
                                  color={"disabled"}
                                />
                              )}
                              {row.status !== "processing" ? Duration : null}
                              <CustomizedBadge
                                provider={"reddit"}
                                count={redditSubmission.count}
                              />
                              <CustomizedBadge
                                provider={"twitter"}
                                count={twitterSubmission.count}
                              />
                              <CustomizedBadge
                                provider={"redgifs"}
                                count={redgifsSubmissions.count}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <Button
                              variant={'contained'}
                              color={'primary'}
                              fullWidth={true}
                              endIcon={<ExpandMoreIcon/>}
                              disabled={row.status === "processing"}
                              onClick={(event) => {
                                setSelectedActionRowId(row.id);
                                setSelectedActionAnchor(event.currentTarget);
                              }}
                            >
                              Actions
                            </Button>
                            <Menu
                              id="demo-positioned-menu"
                              aria-labelledby="demo-positioned-button"
                              onClose={() => setSelectedActionRowId(null)}
                              open={selectedActionRowId === row.id}
                              anchorEl={selectedActionAnchor}
                            >
                              <MenuItem>
                                <LoadingButton onClick={() => navigate(`/posts/new/upload?viralId=${row.id}`)}>
                                  <EditCalendarIcon
                                    color={"action"}
                                    style={{ marginRight: 5 }}/>
                                  Schedule Posts
                                </LoadingButton>
                              </MenuItem>
                              <MenuItem
                                onClick={() => handleDownloadViral(row.id)}
                              >
                                <LoadingButton
                                  loading={downloadViralMutation.isLoading}
                                >
                                  <DownloadIcon color={"action"} style={{marginRight: 5}} /> Download
                                </LoadingButton>
                              </MenuItem>
                              <MenuItem>
                                <LoadingButton
                                  onClick={() =>
                                    handleSubmitRedditOpen(
                                      row.thumbnailUrl,
                                      row.id
                                    )
                                  }
                                >
                                  <RedditIcon
                                    color={"action"}
                                    style={{ marginRight: 5 }}
                                  />
                                  Post to Reddit Wall
                                </LoadingButton>
                              </MenuItem>
                              <MenuItem>
                                <LoadingButton
                                  onClick={() =>
                                    handleSubmitRedgifsOpen(
                                      row.thumbnailUrl,
                                      row.id
                                    )
                                  }
                                >
                                  <SvgIcon style={{marginRight: 5}}>
                                    <image xlinkHref="/redgifs.svg" width="22" height="22" />
                                  </SvgIcon>
                                  Post to Redgifs
                                </LoadingButton>
                              </MenuItem>
                              <MenuItem onClick={() => handleSubmitTwitterOpen(row.thumbnailUrl, row.id)}>
                                <LoadingButton>
                                  <TwitterIcon
                                    color={"action"}
                                    style={{ marginRight: 5 }}
                                  />
                                  Post to Twitter
                                </LoadingButton>
                              </MenuItem>
                              <MenuItem onClick={() => handleOpenDeleteViral(row.id)}>
                                <LoadingButton loading={deleteViral.isLoading}>
                                  <DeleteForeverOutlinedIcon color={"error"} style={{ marginRight: 5 }} />{" "}
                                  Delete
                                </LoadingButton>
                              </MenuItem>
                              {/*<Divider/>*/}
                              {/*<MenuItem>*/}
                              {/*  <LoadingButton>*/}
                              {/*    <ContentCopyIcon color={"action"} style={{ marginRight: 5 }} />{" "}*/}
                              {/*    Copy redgifs link*/}
                              {/*  </LoadingButton>*/}
                              {/*</MenuItem>*/}
                            </Menu>
                          </Grid>
                        </Grid>
                      </TableCell>
                    </Hidden>
                    <Hidden mdDown>
                      <TableCell
                        align="left"
                        style={{ width: 110, height: 110, cursor: "pointer" }}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleVideoClick(row.id);
                        }}
                      >
                        {isFailed ? (
                          thumbnail
                        ) : (
                          <Box
                            style={{
                              position: "relative",
                              display: 'flex',
                              justifyContent: 'center',
                              backgroundColor: 'black',
                            }}
                          >
                            {thumbnail}
                            <Box
                              style={{ position: "absolute", top: 5, left: 5 }}
                            ></Box>
                            <Box
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                              }}
                            >
                              <PlayCircleOutlineIcon
                                style={{ color: "white" }}
                                fontSize={"large"}
                              />
                            </Box>
                          </Box>
                        )}
                      </TableCell>
                      <TableCell align="left">
                        {/*<EditOutlinedIcon fontSize={'small'} color={'secondary'}/>*/}
                        {row.name || "my viral"}
                      </TableCell>
                      <TableCell align="left">
                        {row.metaData && row.metaData["includeWatermark"] ? (
                          <BrandingWatermarkIcon
                            fontSize={"small"}
                            color={"primary"}
                          />
                        ) : (
                          <BrandingWatermarkIcon
                            fontSize={"small"}
                            color={"disabled"}
                          />
                        )}
                      </TableCell>
                      <TableCell align="left">
                        {row.status !== "processing" ? Duration : null}
                      </TableCell>
                      <TableCell align={"left"}>
                        <Typography>{timeAgo(row.createdAt)}</Typography>
                      </TableCell>
                      <TableCell style={{ width: 160 }} align="left">
                        {row.status === "processing" ? (
                          <LinearProgress style={{ width: 91 }} />
                        ) : (
                          <StyledChip status={row.status} label={row.status} />
                        )}
                      </TableCell>
                      <TableCell style={{ width: 110 }}>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 3,
                          }}
                        >
                          <CustomizedBadge
                            provider={"reddit"}
                            count={redditSubmission.count}
                          />
                          <CustomizedBadge
                            provider={"twitter"}
                            count={twitterSubmission.count}
                          />
                          <CustomizedBadge
                            provider={"redgifs"}
                            count={redgifsSubmissions.count}
                          />
                        </Box>
                      </TableCell>
                      <TableCell style={{ width: 160 }} align="left">
                        <Button
                          variant={'contained'}
                          endIcon={<ExpandMoreIcon/>}
                          disabled={row.status === "processing"}
                          onClick={(event) => {
                            setSelectedActionRowId(row.id);
                            setSelectedActionAnchor(event.currentTarget);
                          }}
                        >
                          Actions
                        </Button>
                        <Menu
                          id="demo-positioned-menu"
                          aria-labelledby="demo-positioned-button"
                          onClose={() => setSelectedActionRowId(null)}
                          open={selectedActionRowId === row.id}
                          anchorEl={selectedActionAnchor}
                        >
                          <MenuItem>
                            <LoadingButton
                              onClick={() => navigate(`/posts/new/upload?viralId=${row.id}`)
                              }
                            >
                              <EditCalendarIcon
                                color={"action"}
                                style={{ marginRight: 5 }}/>
                              Schedule Posts
                            </LoadingButton>
                          </MenuItem>
                          <MenuItem onClick={() => handleDownloadViral(row.id)}>
                            <LoadingButton
                              loading={downloadViralMutation.isLoading}
                            >
                              <DownloadIcon
                                color={"action"}
                                style={{ marginRight: 5 }}
                              />
                              Download
                            </LoadingButton>
                          </MenuItem>
                          <MenuItem onClick={() => handleSubmitRedgifsOpen(row.thumbnailUrl, row.id)}>
                            <LoadingButton>
                              <SvgIcon style={{marginRight: 5}}>
                                <image xlinkHref="/redgifs.svg" width="22" height="22" />
                              </SvgIcon>
                              Post to Redgifs
                            </LoadingButton>
                          </MenuItem>
                          <MenuItem>
                            <LoadingButton
                              onClick={() =>
                                handleSubmitRedditOpen(row.thumbnailUrl, row.id)
                              }
                            >
                              <RedditIcon
                                color={"action"}
                                style={{ marginRight: 5 }}
                              />
                              Post to Reddit Wall
                            </LoadingButton>
                          </MenuItem>
                          <MenuItem onClick={() => handleSubmitTwitterOpen(row.thumbnailUrl, row.id)}>
                            <LoadingButton>
                              <TwitterIcon
                                color={"action"}
                                style={{ marginRight: 5 }}
                              />
                              Post to Twitter
                            </LoadingButton>
                          </MenuItem>
                          <MenuItem onClick={() => handleOpenDeleteViral(row.id)}>
                            <LoadingButton loading={deleteViral.isLoading}>
                              <DeleteForeverOutlinedIcon color={"error"} style={{ marginRight: 5 }} />{" "}
                              Delete
                            </LoadingButton>
                          </MenuItem>
                          {/*<Divider/>*/}
                          {/*<MenuItem onClick={() => onDeleteViral(row.id)}>*/}
                          {/*  <LoadingButton>*/}
                          {/*    <ContentCopyIcon color={"action"}  style={{ marginRight: 5 }} />{" "}*/}
                          {/*    Copy redgifs link*/}
                          {/*  </LoadingButton>*/}
                          {/*</MenuItem>*/}
                        </Menu>
                      </TableCell>
                    </Hidden>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell>
                  You don't have any Virals yet.{" "}
                  <Link to={"new"}>Create your first viral.</Link>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          {isMobile ? (
            <TableFooter
              className={`${isMobile ? classes.tableNavigationMobile : ""} ${
                isMobile ? classes.paperStyle : ""
              }`}
            >
              <TableRow>
                <TablePagination
                  colSpan={12}
                  count={paginationTableCount || 0}
                  rowsPerPage={limit}
                  page={currentPage}
                  rowsPerPageOptions={[-1]}
                  SelectProps={{
                    disabled: reachedMax,
                  }}
                  nextIconButtonProps={
                    reachedMax
                      ? {
                          disabled: reachedMax,
                        }
                      : undefined
                  }
                  onPageChange={setNextPage}
                />
              </TableRow>
            </TableFooter>
          ) : null}
        </Table>
      </TableContainer>
      {/*<Modal*/}
      {/*  id="modal"*/}
      {/*  aria-label="modal-headline"*/}
      {/*  open={videoModalOpen}*/}
      {/*  onClose={() => setVideoModalOpen(false)}*/}
      {/*>*/}
      {/*  <>*/}
      {/*    <Box className={classes.boxVideo}>*/}
      {/*      <video*/}
      {/*        ref={video}*/}
      {/*        key={selectedVideo}*/}
      {/*        className={classes.video}*/}
      {/*        loop*/}
      {/*        autoPlay*/}
      {/*        controls*/}
      {/*      >*/}
      {/*        <source src={selectedVideo!} type="video/mp4" />*/}
      {/*        Your browser does not support the video tag.*/}
      {/*      </video>*/}
      {/*    </Box>*/}
      {/*  </>*/}
      {/*</Modal>*/}
      {videoModalOpen && (
        <PlayVideoModal
          videoModalOpen={videoModalOpen}
          setVideoModalOpen={setVideoModalOpen}
          viralId={selectedVideoViralId}
        />
      )}
    </>
  );
};
