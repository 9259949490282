import React, {useState} from "react";
import {
  Typography,
  Button,
  Grid,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useGetUser } from "../../../hooks/api-hooks/useGetUser";
import { Chip } from "@mui/material";
import {useMutation} from "@tanstack/react-query";
import * as viralApi from "../../../api";
import toast from "react-hot-toast";
import LoadingButton from "@mui/lab/LoadingButton";
import {AlertDialog} from "../../../components/alert-dialog";
import {ConfirmDialog} from "../../../components/confirm-dialog";

const MembershipPage = () => {
  const [isCancelMembership, setIsCancelMembership] = useState(false);
  const user = useGetUser();
  const cancelMembershipMutation = useMutation({
    mutationFn: () =>
      viralApi.cancelSubscription(),
  });

  const handleCancelMembership = async () => {
    // Handle cancel membership logic here
    await cancelMembershipMutation.mutateAsync();
    toast.success('Membership cancelled successfully');
    user.refetch();
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const renewDate = new Date(user.data?.subscription?.currentPeriodEnd!)
  const month = renewDate.toLocaleString('en-US', { month: 'long' });
  const day = renewDate.getDate();
  const year = renewDate.getFullYear();

  const formattedDate = `${month} ${day}, ${year}`;

  return (
    <>
      <ConfirmDialog
        open={isCancelMembership}
        okText={'Yes'}
        cancelText={'No'}
        title={"Cancel Membership"}
        message={
          "Are you sure you want to cancel your membership?"
        }
        onClose={() => setIsCancelMembership(false)}
        onCancel={() => setIsCancelMembership(false)}
        onOk={() => handleCancelMembership()}
      />
      <Grid
        container
        spacing={3}
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Grid item xs={12}>
          <Typography variant="h5" component="h2">
            My Membership
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1">
            Current Plan: <b>{user.data?.subscription.label}</b>
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1">
            Plan Status
            <Chip
              sx={{marginLeft: 1}}
              variant={'outlined'}
              size={'small'}
              color={
                user.data?.subscription.status === "active"
                  ? "primary"
                  : "default"
              }
              label={user.data?.subscription.status ? user.data?.subscription.status : 'not active'}
            />
          </Typography>
          {user.data?.subscription.status === 'active' && user.data?.subscription.cancelAtPeriodEnd ?
            <Typography color='error'  style={{marginTop: 8}} variant={'body1'}>
              Expires At: <i>{formattedDate}</i>
            </Typography>
            : null
          }
          {user.data?.subscription.status === 'active' && !user.data?.subscription.cancelAtPeriodEnd ?
            <Typography style={{marginTop: 8}} variant={'body1'}>
              Renews: <i>{formattedDate}</i>
            </Typography>
            : null
          }
        </Grid>

        <Grid item xs={12}>
          <LoadingButton
            loading={cancelMembershipMutation.isLoading}
            disabled={user.data?.subscription.status !== 'active' || user.data?.subscription.cancelAtPeriodEnd === true}
            variant="contained"
            color="secondary"
            onClick={() => setIsCancelMembership(true)}
            fullWidth={isMobile}
          >
            Cancel Membership
          </LoadingButton>
        </Grid>
      </Grid>
    </>

  );
};

export default MembershipPage;
