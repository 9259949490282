import React from "react";
import {CircularProgress} from "@mui/material";
import {RedditCustomize} from "./reddit-customize";
import {useQuery} from "@tanstack/react-query";
import {getSubreddits} from "../../../api";
import Box from "@mui/material/Box";

export const CustomizeComponent = () => {
  const subreddits = useQuery( [`getSubreddits`], () => getSubreddits(),
    {
      enabled: true
    }
  );

  return (
    <Box mt={4}>
      {subreddits.isLoading ? <CircularProgress/> : <RedditCustomize subreddits={subreddits.data!}/> }
    </Box>
  );
};
