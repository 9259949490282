import {FormHelperText, Grid, InputAdornment, MenuItem, Select, Stack, TextField} from "@mui/material";
import React, {useCallback, useMemo, useState} from "react";
import {useGetUser} from "../../../hooks/api-hooks/useGetUser";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import AddCircleOutlineSharpIcon from '@mui/icons-material/AddCircleOutlineSharp';
import {useDropzone} from "react-dropzone";
import Box from "@mui/material/Box";
import {acceptStyle, baseStyle, focusedStyle, rejectStyle} from "../../../shared/styles/drag-n-drop-style";
import {makeStyles} from "@material-ui/core/styles";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import * as viralApi from '../../../api'
import {WatermarkUploadResponse} from "../../../api/types";
import LoadingButton from "@mui/lab/LoadingButton";
import {useFinalizeWatermarkUpload} from "../../../hooks/api-hooks/useFinalizeWatermarkUpload";
import toast from "react-hot-toast";
import {useUpdateUser} from "../../../hooks";
const FontDropdown = () => {
  const user = useGetUser();
  const [selectedFont, setSelectedFont] = useState('Roboto');

  const handleChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setSelectedFont(event.target.value);
  };

  const fontOptions = [
    { value: 'Roboto', label: 'Roboto' },
    { value: 'Open Sans', label: 'Open Sans' },
    { value: 'Lato', label: 'Lato' },
    // Add more font options here
  ];

  return (
    <div>
      <style>{`
        @font-face {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 400;
          src: local('Roboto'), local('Roboto-Regular'),
              url(https://fonts.gstatic.com/s/roboto/v27/KFOmCnqEu92Fr1Mu4mxK.woff2) format('woff2');
        }

        @font-face {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 400;
          src: local('Open Sans'), local('OpenSans-Regular'),
              url(https://fonts.gstatic.com/s/opensans/v22/mem8YaGs126MiZpBA-UFVZ0b.woff2) format('woff2');
        }

        @font-face {
          font-family: 'Lato';
          font-style: normal;
          font-weight: 400;
          src: local('Lato'), local('Lato-Regular'),
              url(https://fonts.gstatic.com/s/lato/v20/S6uyw4BMUTPHjx4wWw.ttf) format('truetype');
        }
      `}</style>
      <Select
        size={'small'}
        labelId="font-selection"
        id="front-selection"
        value={selectedFont}
        label="Font"
        onChange={handleChange}
      >
        {fontOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>Select a font</FormHelperText>
      <p style={{ fontFamily: selectedFont }}>
        {user.data?.watermarkText}
      </p>
    </div>
  );
};


const useStyles = makeStyles({
  image: {
    width: '100%',
  },
  currentWaterMark: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    padding: 10,
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle:'solid',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    width: 150,
    height: 150,
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
  },
});
export function Watermark () {
  const classes = useStyles();
  const user = useGetUser();
  const client = useQueryClient();
  const [isWatermarkTextEditable, toggleWatermarkTextEdit] = useState<boolean>(false);
  const [editedWatermark, setEditedWatermark] = useState(user.data?.watermarkText);
  const [watermarkPref, setWatermarkPref] = useState(user.data?.watermarkPref || 'text')
  const [newWatermarkFile, setNewWatermarkFile] = useState<File>()
  const [newWatermark, setNewWatermark] = useState<string>()

  const finalizeWatermarkUploadMutation = useFinalizeWatermarkUpload()
  const updateUserMutation = useUpdateUser();

  const uploadNewWatermark = async (watermarkUploadResponse: WatermarkUploadResponse) => {
    await fetch(watermarkUploadResponse.uploadUrl, {method: "PUT", body:newWatermarkFile ,
      headers: {
        "Content-Type":'image/png',
        'x-goog-meta-file-id': watermarkUploadResponse.fileId,
      }});
  }
  const uploadWatermarkMutation =  useMutation( {mutationFn: () => viralApi.uploadWatermark(),
    onSuccess: async (result) => {
      try{
        await uploadNewWatermark(result);
        await finalizeWatermarkUploadMutation.mutateAsync(result.fileId);
        user.refetch();
        setNewWatermark(undefined)
      }catch(error){
        // We should handle errors
        toast.error('Sorry, something went wrong uploading your watermark');
        console.error(error);
      }
    }});

  const handleToggleWatermark = async (
    event: React.MouseEvent<HTMLElement>,
    newIncludeWatermark: string,
  ) => {
    if(newIncludeWatermark){
      await updateUserMutation.mutateAsync({watermarkPref: newIncludeWatermark})
      user.refetch();
      setWatermarkPref(newIncludeWatermark);
    }
  };

  const handleUpdateWatermarkText = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditedWatermark(event.target.value)
  }
  const handleWatermarkTextEdit = async () => {
    if(isWatermarkTextEditable){
      if(editedWatermark){
        await updateUserMutation.mutateAsync({watermarkText: editedWatermark})
      }
    }
    toggleWatermarkTextEdit(!isWatermarkTextEditable);
  };

  const onDrop = useCallback((file: any[]) => {
    setNewWatermarkFile(file[0]);
    setNewWatermark(URL.createObjectURL(file[0]));
  }, []);

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject} = useDropzone({
      onDrop,
      accept: {
        'image/png': [],
      }
    })


  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {}),
    justifyContent: 'center',
    textAlign: 'center',
    width: 150,
    height: 150
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);

  return (
    <Grid container columnSpacing={{ xs: 2}}>
      <Grid item xs={12} md={6}>
        <Stack spacing={2}>
          <Typography variant={'h5'}>
            Watermark Type
          </Typography>
          <ToggleButtonGroup
            size={'medium'}
            color="primary"
            value={watermarkPref}
            exclusive
            aria-label="Platform"
            onChange={handleToggleWatermark}
          >
            <ToggleButton value="text">Text</ToggleButton>
            <ToggleButton value="image">Image</ToggleButton>
          </ToggleButtonGroup>

          <Box className={classes.flexContainer}>
            <Typography variant={'h6'}>
              Text
            </Typography>
            {watermarkPref === 'text' ? <CheckCircleRoundedIcon color={'success'}/> : null}
          </Box>

          <TextField fullWidth
                     variant="outlined"
                     onChange={handleUpdateWatermarkText}
                     disabled={!isWatermarkTextEditable}
                     helperText={"Watermark Text"}
                     value={editedWatermark}
                     InputProps={
                       {
                         endAdornment: (
                           <InputAdornment position="end">
                             <Button
                               aria-label="edit-watermark-button"
                               color={isWatermarkTextEditable ? 'primary' : 'inherit'}
                               variant="contained"
                               onClick={handleWatermarkTextEdit}
                             >
                               { isWatermarkTextEditable ? 'Save' : 'Edit'}
                             </Button>
                           </InputAdornment>
                         )
                       }
                     }>
          </TextField>
          {/*<FontDropdown />*/}

          <Box className={classes.flexContainer}>
            <Typography variant={'h6'}>
              Image
            </Typography>
            {watermarkPref === 'image' ? <CheckCircleRoundedIcon color={'success'}/> : null}
          </Box>
          <Box>
            <Grid container spacing={{ xs: 2}}>
              <Grid item xs={12}>
                <Typography>
                  Current watermark
                </Typography>
              </Grid>
              <Grid item xs={12} lg={6} >
                <div className={classes.currentWaterMark}>
                  {user.data?.watermarkImageUrl ? <img className={classes.image} src={user.data?.watermarkImageUrl} alt={'new-watermark'} /> : 'Please upload a watermark'}
                </div>
              </Grid>
              <Grid item xs={12} lg={6}>
                {/*// @ts-ignore*/}
                <div {...getRootProps({style})}>
                  <input {...getInputProps()} />
                  <AddCircleOutlineSharpIcon fontSize={'large'}/>
                  {newWatermark ? <img className={classes.image} src={newWatermark} alt={'new-watermark'} /> : <Typography>Upload new watermark</Typography>}
                </div>
              </Grid>
              <Grid item xs={12}>
                <LoadingButton
                  loading={uploadWatermarkMutation.isLoading}
                  disabled={!newWatermark}
                  variant="contained"
                  onClick={async () => uploadWatermarkMutation.mutate()}
                  style={{height: '100%'}}>
                    Upload new watermark
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </Stack>
      </Grid>
    </Grid>
  )
}