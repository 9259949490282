import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import {useGetUser} from "../hooks/api-hooks/useGetUser";
import {useNavigate} from "react-router-dom";
import {useMediaQuery} from "@material-ui/core";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {Dispatch, SetStateAction} from "react";
import SvgIcon from "@mui/material/SvgIcon";
import Button from "@mui/material/Button";
import {Hidden} from "@mui/material";
import ListItem from "@mui/material/ListItem";
import StarIcon from "@mui/icons-material/Star";

interface ButtonAppBarProps {
  setNavManuOpen?: Dispatch<SetStateAction<boolean>>;
  hideMenu?: boolean;
  navManuOpen: boolean;
}

const useStyles = makeStyles((theme) => ({
  button: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    marginRight: '1.5em',
    color: 'white',
    '&:hover': {
      background: 'linear-gradient(45deg, #FF8E53 30%, #FE6B8B 90%)',
    },
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}));

export default function ButtonAppBar({setNavManuOpen, navManuOpen, hideMenu = false}: ButtonAppBarProps ) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const classes = useStyles();
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const user = useGetUser();

  return (
      <AppBar sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: '#000023'}}>
        <Toolbar>
          {isMobile ? <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => {
              if(setNavManuOpen){
                setNavManuOpen(!navManuOpen)
              }
            }}
          >
            <MenuIcon />
          </IconButton> : null}
          <Box component="a" href="/virals" style={{display: 'flex', alignItems:'center', textDecoration: 'none'}}>
            {!isMobile ? <Typography
              variant="h6"
              noWrap
              sx={{
                mr: 2,
                fontFamily: 'Arial, sans-serif',
                fontWeight: 700,
                color: 'white',
              }}
            >
              Viral Daddy
            </Typography> : null}
            <SvgIcon style={{marginRight: 5}}>
              <image xlinkHref="/g56.svg" width="24" height="24" />
            </SvgIcon>
          </Box>

          {hideMenu ? null :
            <Box sx={{ flexGrow: 1, justifyContent:'end', alignItems:'center', display: { xs: 'flex'}}}>
              <Button className={classes.button} color={'secondary'} variant="contained" style={{marginRight: '1.5em'}} onClick={() => navigate('/pricing')}  >
                {user.data && user.data.subscription.id !== 1 ? <StarIcon className={classes.icon}/> : ''}
                {user.data && user.data.subscription.id === 1 ? 'Upgrade' : user.data?.subscription.label}
              </Button>
              <Hidden mdDown>
                <Typography style={{paddingRight: '10px'}}>
                  {user.data?.userName}
                </Typography>
              </Hidden>

              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="My Avatar" src="/static/images/avatar/2.jpg" />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <ListItem>
                  <Typography variant={"caption"} textAlign="center">
                    {user.data?.userName}
                  </Typography>
                </ListItem>
                <MenuItem onClick={() => {navigate('/settings'); handleCloseUserMenu()}}>
                  <Typography textAlign="center">
                    Settings
                  </Typography>
                </MenuItem>
                <MenuItem onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">
                    {user.isSuccess && user.data.userName ?
                      <a href="/api/auth/logout">Logout</a> :
                      <a href="/api/login/reddit">Login</a>
                    }
                  </Typography>
                </MenuItem>
              </Menu>
            </Box>
          }

        </Toolbar>
      </AppBar>
  );
}