import React, { useEffect, useState } from "react";
import { Divider, Typography } from "@material-ui/core";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import ElectricalServicesIcon from "@mui/icons-material/ElectricalServices";
import EmailIcon from "@mui/icons-material/Email";
import { useGetUser } from "../../../hooks/api-hooks/useGetUser";
import {Link, useNavigate} from "react-router-dom";

interface ChecklistItem {
  id: number;
  title: string;
  subtitle: string;
}

const useStyles = makeStyles((theme) => ({
  checklistItem: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    textDecoration: "none",
  },
  checklistIcon: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(0.5),
  },
  checklistCheckedIcon: {
    color: "green",
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(0.5),
  },
  checklistText: {
    flexGrow: 1,
    marginLeft: theme.spacing(1),
    textDecoration: "inherit",
  },
  crossedOut: {
    textDecoration: "line-through",
  },
}));

function CheckList(): JSX.Element {
  const [checked, setChecked] = useState<boolean[]>([true, false]);
  const classes = useStyles();

  const user = useGetUser();
  const navigate = useNavigate(); // Use useNavigate hook

  useEffect(() => {
    //   Call everything you need to know if checklist is good or not
  }, []);

  const handleLinkClick = (path: string, linking: string) => {
    // Set the cookie
    document.cookie =
      `${linking}=true; path=/; Secure; SameSite=Strict`;

    // // Navigate to the href path
    // window.location.href = path;
  };

  return (
    <>
      <Typography variant="h5" component="h2">
        Checklist
      </Typography>
      <List>
        {/*Redgifs*/}
        <ListItem className={classes.checklistItem}>
          {user.data?.isRedgifsLinked ? (
            <CheckCircleIcon className={classes.checklistCheckedIcon} />
          ) : (
            <RadioButtonUncheckedIcon className={classes.checklistIcon} />
          )}
          <ElectricalServicesIcon />
          <div className={classes.checklistText}>
            <a href={`/api/auth/redgifs/link`}>
              <Typography
                variant="subtitle1"
                color="primary"
                className={user.data?.isRedgifsLinked ? classes.crossedOut : ""}
              >
                Connect your Redgifs account
              </Typography>
            </a>
            <Typography variant="body2" color="textSecondary">
              Connect your Redgifs account to Viraldaddy so you can post to
              reddit, redgifs and other socials.
            </Typography>
          </div>
        </ListItem>
        <Divider />
        {/*Twitter*/}
        <ListItem className={classes.checklistItem}>
          {user.data?.isTwitterLinked ? (
            <CheckCircleIcon className={classes.checklistCheckedIcon} />
          ) : (
            <RadioButtonUncheckedIcon className={classes.checklistIcon} />
          )}
          <ElectricalServicesIcon />
          <div className={classes.checklistText}>
            <a
              href={`/api/auth/twitter/link`}
              onClick={() => handleLinkClick(`/api/auth/twitter/link`, 'twitter_linking')}
            >
              <Typography
                variant="subtitle1"
                color="primary"
                className={user.data?.isTwitterLinked ? classes.crossedOut : ""}
              >
                Connect your Twitter Account
              </Typography>
            </a>
            <Typography variant="body2" color="textSecondary">
              Connect your twitter account to Viraldaddy so you can post your
              virals to twitter directly from viraldaddy
            </Typography>
          </div>
        </ListItem>
        <Divider />
        {/*Twitte V1r*/}
        <ListItem className={classes.checklistItem}>
          {user.data?.isTwitterV1Linked ? (
            <CheckCircleIcon className={classes.checklistCheckedIcon} />
          ) : (
            <RadioButtonUncheckedIcon className={classes.checklistIcon} />
          )}
          <ElectricalServicesIcon />
          <div className={classes.checklistText}>
            <a
              href={`/api/auth/twitter-v1/link`}
            >
              <Typography
                variant="subtitle1"
                color="primary"
                className={user.data?.isTwitterV1Linked ? classes.crossedOut : ""}
              >
                Connect your Twitter Media Account
              </Typography>
            </a>
            <Typography variant="body2" color="textSecondary">
              Connect to twitter version 1 to Viraldaddy to give us permission to tweet media content like
              images and videos (This is a technicality with twitters v2 api)
            </Typography>
          </div>
        </ListItem>
        <Divider />
        {/*Reddit*/}
        <ListItem className={classes.checklistItem}>
          {user.data?.isRedditLinked ? (
            <CheckCircleIcon className={classes.checklistCheckedIcon} />
          ) : (
            <RadioButtonUncheckedIcon className={classes.checklistIcon} />
          )}
          <ElectricalServicesIcon />
          <div className={classes.checklistText}>
            <a
              href={`/api/auth/reddit/link`}
              onClick={() => handleLinkClick(`/api/auth/reddit/link`, 'reddit_linking')}
            >
              <Typography
                variant="subtitle1"
                color="primary"
                className={user.data?.isRedditLinked ? classes.crossedOut : ""}
              >
                Connect your Reddit account
              </Typography>
            </a>
            <Typography variant="body2" color="textSecondary">
              Connect your Reddit account to Viraldaddy.
            </Typography>
          </div>
        </ListItem>
        <Divider />
        {/*Add your email*/}
        <ListItem className={classes.checklistItem}>
          {user.data?.email ? (
            <CheckCircleIcon className={classes.checklistCheckedIcon} />
          ) : (
            <RadioButtonUncheckedIcon className={classes.checklistIcon} />
          )}
          <EmailIcon />
          <div className={classes.checklistText}>
            <Link to={`/settings`}>
              <Typography
                variant="subtitle1"
                color="primary"
                className={user.data?.email ? classes.crossedOut : ""}
              >
                Add your email
              </Typography>
            </Link>
            <Typography variant="body2" color="textSecondary">
              Add your email to grant you the ability to turn on notifications
            </Typography>
          </div>
        </ListItem>
        <Divider />
        <ListItem className={classes.checklistItem}>
          {user.data?.notifyOnComplete ? (
            <CheckCircleIcon className={classes.checklistCheckedIcon} />
          ) : (
            <RadioButtonUncheckedIcon className={classes.checklistIcon} />
          )}
          <NotificationsActiveIcon />
          <div className={classes.checklistText}>
            <Link to={`/settings`}>
              <Typography
                variant="subtitle1"
                color="primary"
                className={user.data?.notifyOnComplete ? classes.crossedOut : ""}
              >
                Turn on notifications
              </Typography>
            </Link>
            <Typography variant="body2" color="textSecondary">
              Receive notifications when your virals are done processing
            </Typography>
          </div>
        </ListItem>
      </List>
    </>
  );
}

export default CheckList;
