import React, {Suspense} from "react";
import {Tab, Tabs} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import RedditIcon from "@mui/icons-material/Reddit";
import TwitterIcon from "@mui/icons-material/Twitter";
import {ReviewComponentReddit} from "./review-component-reddit";


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const ReviewComponent = () => {
  return (
    <>
      <Typography variant="h5" gutterBottom mt={3}>
        Review and Edit
      </Typography>
      <Box mb={2} mt={3}>
        <Typography variant="body1" sx={{display: 'flex', flexDirection: 'row', gap: 2}} mb={1}>
         <TipsAndUpdatesIcon color={'info'}/> We will automatically select the best time for you.
        </Typography>
        <Typography variant="body1" sx={{display: 'flex', flexDirection: 'row', gap: 2}} mb={1}>
          <TipsAndUpdatesIcon color={'info'}/>  Posts are scheduled to appear organically. Never posted back to back.
        </Typography>
        <Typography variant="body1" sx={{display: 'flex', flexDirection: 'row', gap: 2}}>
          <TipsAndUpdatesIcon color={'info'}/> Posts shown below are not shown in the order they will be posted in
        </Typography>
      </Box>
      <Suspense fallback={<h1>loading</h1>}>
        <ReviewComponentReddit/>
      </Suspense>
    </>
  );
};

export const MemoizedReviewComponent = React.memo(ReviewComponent);