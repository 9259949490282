import * as React from "react";
import { createContext, ReactNode, useContext, useState } from "react";
import { CreatePostBody, SubredditResponse } from "../../../api/types";

// Define the type for the post request context
type PostRequestContextType = {
  postRequestContext: CreatePostBody[];
  setPostRequestContext: React.Dispatch<React.SetStateAction<CreatePostBody[]>>;
  selectedFlair: Record<string, string>;
  setSelectedFlair: React.Dispatch<React.SetStateAction<Record<string, string>>>;
  selectedSubreddits: SubredditResponse[];

  setSelectedSubreddits: React.Dispatch<
    React.SetStateAction<SubredditResponse[]>
  >;
};

export const PostRequestContext = createContext<
  PostRequestContextType | undefined
>(undefined);

// Provider to share state

// Custom hook to use PostRequestContext
export const usePostRequestContext = () => {
  const context = useContext(PostRequestContext);
  if (!context) {
    throw new Error(
      "usePostRequestContext must be used within a PostRequestProvider"
    );
  }
  return context;
};

export const PostRequestProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [postRequestContext, setPostRequestContext] = useState<
    CreatePostBody[]
  >([]);
  const [selectedSubreddits, setSelectedSubreddits] = useState<
    SubredditResponse[]
  >([]);

  const [selectedFlair, setSelectedFlair] = useState<
    Record<string, string>
  >({});

  return (
    <PostRequestContext.Provider
      value={{
        postRequestContext,
        setPostRequestContext,
        selectedSubreddits,
        setSelectedSubreddits,
        selectedFlair,
        setSelectedFlair,
      }}
    >
      {children}
    </PostRequestContext.Provider>
  );
};
