import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import CancelIcon from '@mui/icons-material/Cancel';
import useMediaQuery from '@mui/material/useMediaQuery';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import { CreatePostBody } from "../../../api/types";
import Typography from "@mui/material/Typography";
import {Link} from "@mui/material";
import {useTheme} from "@material-ui/core";
import {transformUrl} from "../../../utils";

const THUMBNAIL_SIZE = 160;

interface ThumbnailRowProps {
  postRequestContext: CreatePostBody[];
  setPostRequestContext: React.Dispatch<React.SetStateAction<CreatePostBody[]>>;
}

export const ThumbnailRow: React.FC<ThumbnailRowProps> = ({ postRequestContext, setPostRequestContext }) => {
  const theme = useTheme();
  const [openDialog, setOpenDialog] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [linkMediaTypes, setLinkMediaTypes] = useState<Record<number, 'image' | 'video' | 'redgifs'>>({});

  useEffect(() => {
    postRequestContext.forEach((f, i) => {
      if (f.link) {
        const extension = f.link.split('.').pop()?.toLowerCase();
        if (['png', 'jpg', 'jpeg', 'gif', 'webp'].includes(extension || '')) {
          setLinkMediaTypes(prevState => ({ ...prevState, [i]: 'image' }));
        } else if (['mp4', 'webm', '.mov'].includes(extension || '')) {
          setLinkMediaTypes(prevState => ({ ...prevState, [i]: 'video' }));
        } else if (f.link.includes('redgifs.com')){
          setLinkMediaTypes(prevState => ({ ...prevState, [i]: 'redgifs' }));
        }
        // Add more media types as necessary
      }
    });
  }, [postRequestContext]);

  const removeFile = (index: number) => {
    const newFiles = [...postRequestContext];
    newFiles.splice(index, 1);
    setPostRequestContext(newFiles);
  };

  const renderContent = (f: CreatePostBody, index: number) => {
    if (f.file && f.objectFile && f.objectFile.type.startsWith('image/')) {
      return (
        <img
          src={URL.createObjectURL(f.objectFile)}
          alt=""
          style={{
            maxWidth: '100%',
            maxHeight: '100%',
            objectFit: 'contain'
          }}
        />
      );
    } else if (f.file && f.objectFile && f.objectFile.type.startsWith('video/')) {
      return (
        <video
          controls={true}
          style={{
            maxWidth: '100%',
            maxHeight: '100%',
            objectFit: 'contain'
          }}>
          <source src={URL.createObjectURL(f.objectFile)} />
        </video>
      );
    } else if (f.link) {
      const mediaType = linkMediaTypes[index];
      if (mediaType === 'image') {
        return (
          <img
            src={f.link}
            alt=""
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              objectFit: 'contain'
            }}
          />
        );
      } else if (mediaType === 'video') {
        return (
          <video
            controls={true}
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              objectFit: 'contain'
            }}>
            <source src={f.link} />
          </video>

        );
      } else if (mediaType === 'redgifs') {
        return (
          <div style={{position: 'relative', width: '100%', height: '100%'}}>
            <iframe src={transformUrl(f.link)}
                    frameBorder='0'
                    scrolling='no'
                    style={{maxWidth: "100%", maxHeight: "100%", objectFit: "contain"}}
                    allowFullScreen>
            </iframe>
          </div>

        );
      }
    }
  };

  const renderThumbnails = () => (
    <Box
      hidden={postRequestContext.length < 1}
      sx={{
        ...(postRequestContext.length > 0 ? { display: 'flex' } : null),
        flexWrap: 'wrap',  // Add this line
        overflowX: 'auto',
        border: '1px solid #eaeaf0',
        padding: '1em',
        margin: '1em 0',
      }}
    >
      {postRequestContext.map((f, i) => (
        <Box
          key={i}
          sx={{
            border: '2px dotted #784af4',
            borderRadius: '5px',
            margin: '0 1em 1em 0',  // Adjusted margin for wrapping
            position: 'relative',
            width: `${THUMBNAIL_SIZE}px`,     // Keep fixed width
            height: `${THUMBNAIL_SIZE}px`,    // Keep fixed height
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {renderContent(f, i)}

          <Box
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              cursor: 'pointer',
              color: 'red',
              fontWeight: 'bold',
            }}
            onClick={() => removeFile(i)}
          >
            <CancelIcon />
          </Box>
        </Box>
      ))}
      {postRequestContext.length === 1 ?
        <Box
          key={'one-off-1'}
          p={2}
          sx={{
            border: '2px dotted #784af4',
            borderRadius: '5px',
            margin: '0 1em 1em 0',  // Adjusted margin for wrapping
            position: 'relative',
            width: `${THUMBNAIL_SIZE}px`,     // Keep fixed width
            height: `${THUMBNAIL_SIZE}px`,    // Keep fixed height
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography variant={'body1'} color={'gray'}>
            You can schedule multiple photos & videos!
          </Typography>

        </Box> : null
      }

    </Box>
  );

  if (isMobile) {
    return (
      <Box
        hidden={postRequestContext.length < 1}
        mt={3}
        ml={2}
        sx={{
          flexWrap: 'wrap',  // Add this line
          overflowX: 'auto',
          border: '1px solid #eaeaf0',
          padding: '1em',
          margin: '1em 0',
        }}
      >
        <Typography variant={'h5'}>
         You added  <Link style={{cursor:'pointer'}} onClick={() => setOpenDialog(true)}> {postRequestContext.length} files <PhotoLibraryIcon /> </Link>
        </Typography>
        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
          <DialogContent>
            {renderThumbnails()}
          </DialogContent>
        </Dialog>
      </Box>
    );
  }

  return renderThumbnails();
};