// Builder Class
import {CreatePostBody, PostScheduleMetaData} from "../../api/types";

export class PostScheduleBuilder {
  private body: CreatePostBody = {
    link: null,
    file: null,
    objectFile: null,
    extension: null,
    postSchedule: []
  };

  private currentPostSchedule: {
    platformId: number;
    metaData: PostScheduleMetaData;
  } = {
    platformId: 0,
    metaData: {}
  };

  setLink(link: string | null): this {
    this.body.link = link;
    return this;
  }

  setFile(file: boolean | null): this {
    this.body.file = file;
    return this;
  }
  setObjectFile(file: File | null): this {
    this.body.objectFile = file;
    return this;
  }

  setExtension(extension: string | null): this {
    this.body.extension = extension;
    return this;
  }

  startNewSchedule(platformId: number): this {
    if (this.currentPostSchedule.platformId !== 0) {
      this.body.postSchedule.push(this.currentPostSchedule);
    }
    this.currentPostSchedule = {
      platformId,
      metaData: {}
    };
    return this;
  }

  setRedditMeta(subreddit: string, title: string, flairId?: string | null): this {
    this.currentPostSchedule.metaData.reddit = { subreddit, title, flairId };
    return this;
  }

  setTwitterMeta(body: string): this {
    this.currentPostSchedule.metaData.twitter = { body };
    return this;
  }

  setRedgifsMeta(tags: string[]): this {
    this.currentPostSchedule.metaData.redgifs = { tags };
    return this;
  }

  build(): CreatePostBody {
    if (this.currentPostSchedule.platformId !== 0) {
      this.body.postSchedule.push(this.currentPostSchedule);
    }
    return this.body;
  }
}