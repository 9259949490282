import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import {
  submitViralToTwitter,
  SubmitViralToTwitterBody,
} from "../../../api";
import toast from "react-hot-toast";
import LoadingButton from "@mui/lab/LoadingButton";

interface SubmitToTwitterDialogProps {
  isOpen: boolean;
  viralId: string;
  thumbnailUrl: string;
  handleOnClose: () => void;
}

export default function SubmitToTwitterDialog({
  isOpen,
  thumbnailUrl,
  handleOnClose,
  viralId,
}: SubmitToTwitterDialogProps) {
  const submitViralToTwitterMutation = useMutation({
    mutationFn: (submitViralToTwitterBody: SubmitViralToTwitterBody) =>
      submitViralToTwitter(submitViralToTwitterBody),
    onSuccess: (result) => {
      toast.success("Tweet successful!");
      handleOnClose();
    },
  });

  const [tweet, setTweet] = useState("");
  const handleSubmitToTwitter = (
    viralId: string,
    tweet: string
  ) => {
    submitViralToTwitterMutation.mutate({ viralId, tweet });
  };

  const handleOnCloseLocal = () => {
    setTweet("");
    handleOnClose();
  };
  return (
    <div>
      <Dialog open={isOpen} onClose={handleOnCloseLocal} fullWidth>
        <DialogTitle>Post to Twitter</DialogTitle>
        <DialogContent>
          <DialogContentText
            sx={{
              display: "flex",
              justifyContent: "center",
              backgroundColor: "black",
              mb: 2,
            }}
          >
            <img
              style={{ height: 200, objectFit: "cover" }}
              src={thumbnailUrl}
              alt="thumbnail-image"
            />
          </DialogContentText>
          <TextField
            placeholder={
              "Add your tweet here. You can include #hastags as well"
            }
            disabled={submitViralToTwitterMutation.isLoading}
            autoFocus
            margin="dense"
            id="name"
            label="Tweet"
            type="text"
            fullWidth
            variant="standard"
            error={tweet.length < 1}
            required={true}
            inputProps={{ maxLength: 280 }}
            onChange={(e) => setTweet(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={submitViralToTwitterMutation.isLoading}
            onClick={handleOnCloseLocal}
          >
            Cancel
          </Button>
          <LoadingButton
            disabled={submitViralToTwitterMutation.isLoading || tweet.length < 1}
            loading={submitViralToTwitterMutation.isLoading}
            onClick={() => handleSubmitToTwitter(viralId, tweet)}
          >
            Submit
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
