import React, { useCallback, useEffect, useMemo, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useDropzone } from "react-dropzone";
import LoadingButton from "@mui/lab/LoadingButton";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  LinearProgress,
  List,
  ListItemText,
  SelectChangeEvent,
  Stack,
  TextField,
} from "@mui/material";
import { useCreateViral } from "../../hooks";
import { CreateViralResponse, getCaptions, getViral } from "../../api";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useDeleteViral, useProcessViral } from "../../hooks";
import { useGetUser } from "../../hooks/api-hooks/useGetUser";
import { useQuery } from "@tanstack/react-query";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  acceptStyle,
  baseStyle,
  focusedStyle,
  rejectStyle,
} from "../../shared/styles/drag-n-drop-style";
import LengthPreset from "./components/length-present";
import { ViralLengthPreset } from "../../api/types";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";

export const CreateViral = (): React.ReactElement => {
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [isVideoTooShort, setIsVideoTooShort] = useState<boolean>(false);

  const [categoryWeights, setCategoryWeights] = React.useState<string[]>([]);
  const [viralName, setViralName] = useState<string | null>(null);
  const [isPromo, setIsPromo] = useState<boolean>(false);
  const [promoText, setPromoText] = useState<string | undefined>(undefined);
  const [isCreatingViral, setIsCreatingViral] = useState(false);
  const [includeWatermark, setIncludeWatermark] = useState("false");
  const [lengthPreset, setLengthPreset] = useState(ViralLengthPreset.MEDIUM);
  const [videoFilesForUpload, setVideoFilesForUpload] = useState<File[]>([]);
  const [uploadedVideos, setUploadedVideos] = useState<string[]>([]);
  const createViralMutation = useCreateViral();
  const processViralMutation = useProcessViral();
  const deleteViral = useDeleteViral();
  const user = useGetUser();
  const navigate = useNavigate();
  const [selectedCaptionCategory, setSelectedCaption] =
    useState<string>("NONE");

  useEffect(() => {
    if (selectedCaptionCategory !== "NONE") {
      getCaptionHook.refetch();
    }
  }, [selectedCaptionCategory]);

  const getCaptionHook = useQuery(
    [`getViral`, selectedCaptionCategory],
    () => getCaptions(selectedCaptionCategory),
    {
      enabled: false,
      refetchInterval: 0,
    }
  );

  const onDrop = useCallback(async (acceptedFiles: any[]) => {
    setIsVideoTooShort(false);
    const videosForPlaying: string[] = [];
    acceptedFiles.forEach((file: File) => {
      const video = URL.createObjectURL(file);
      videosForPlaying.push(video);
    });
    setVideoFilesForUpload(acceptedFiles);
    setUploadedVideos(videosForPlaying);
    setTimeout(async () => {
      const duration = await getVideoDuration(videosForPlaying.length);
      if (duration < 30) {
        setIsVideoTooShort(true);
      }
    }, 0);
  }, []);

  const handleIncludeWaterMark = (
    event: React.MouseEvent<HTMLElement>,
    newIncludeWatermark: string
  ) => {
    if (newIncludeWatermark) {
      setIncludeWatermark(newIncludeWatermark);
    }
  };

  /**
   * Get captions on drop down select
   *
   * @param event
   */
  const handleGetCaptions = (event: SelectChangeEvent) => {
    setSelectedCaption(event.target.value as string);
  };
  const handleCategoryWeightChange = (
    event: SelectChangeEvent<typeof categoryWeights>
  ) => {
    const {
      target: { value },
    } = event;
    setCategoryWeights(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const processViral = async () => {
    setIsCreatingViral(true);
    setUploadProgress(0);
    const duration = getVideoDurationForUpload(uploadedVideos.length);
    const watermark = includeWatermark === "true";
    const captions = getCaptionHook.data || [];
    await createViralMutation.mutateAsync(
      {
        lengthPreset,
        sourceNumberOfClips: uploadedVideos.length,
        totalRawDuration: duration,
        includeWatermark: watermark,
        name: viralName,
        captions,
        isPromo,
        promoText,
      },
      {
        onSuccess: async (createViralResponse) => {
          try {
            await uploadFilesToUrls(createViralResponse);
            await processViralMutation.mutateAsync(
              createViralResponse.viral.id
            );
            if (processViralMutation.isError) {
              throw Error("Error processing");
            }
            setIsCreatingViral(false);
            user.refetch();
            navigate(`/virals`);
          } catch (err) {
            setIsCreatingViral(false);
            // This error could also throw because of the refetch or navigate but unlikely... sorry
            deleteViral.mutate(createViralResponse.viral.id);
            toast.error(`Error uploading file: ${err}`);
          }
        },
        onError: (err: unknown) => {
          const errRead = err as unknown as Error;
          setIsCreatingViral(false);
        },
      }
    );
  };

  const getVideoDuration = async (videos: number) => {
    let duration: number = 0;

    // Wrap each onloadedmetadata event in a Promise
    const durationPromises = Array.from({ length: videos }).map(
      (_, i) =>
        new Promise((resolve) => {
          const video = document.getElementById(`raw-${i}`) as HTMLVideoElement;
          video.onloadedmetadata = () => {
            duration += video.duration;
            // @ts-ignore
            resolve();
          };
        })
    );

    // Wait for all Promises to resolve
    await Promise.all(durationPromises);

    return duration;
  };

  const getVideoDurationForUpload = (videos: number) => {
    let duration: number = 0;
    for (let i = 0; i < videos; i += 1) {
      const video = document.getElementById(`raw-${i}`) as HTMLVideoElement;
      duration += video.duration;
    }
    return duration;
  };

  const uploadFilesToUrls = async (viralResponse: CreateViralResponse) => {
    await Promise.all(
      viralResponse.files.map((file, index) => {
        return new Promise<void>((resolve, reject) => {
          const totalBytes = videoFilesForUpload[index].size;
          let bytesUploaded = 0;

          const xhr = new XMLHttpRequest();
          xhr.open("PUT", file.uploadUrl, true);

          xhr.setRequestHeader("Content-Type", "video/mp4");
          xhr.setRequestHeader("x-goog-meta-viral-id", viralResponse.viral.id);
          xhr.setRequestHeader("x-goog-meta-viral-file-id", file.fileId);

          xhr.upload.onprogress = (event: ProgressEvent) => {
            if (event.lengthComputable) {
              bytesUploaded = event.loaded;
              const progress = Math.floor((bytesUploaded / totalBytes) * 100);
              setUploadProgress(progress);
            }
          };

          xhr.onload = () => {
            if (xhr.status >= 200 && xhr.status < 300) {
              resolve();
            } else {
              reject(new Error("Upload failed with status " + xhr.status));
            }
          };

          xhr.onerror = () => {
            reject(new Error("Upload failed with network error"));
          };

          xhr.send(videoFilesForUpload[index]);
        });
      })
    );
  };

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      maxSize: 3221225472,
      onDropRejected: (err) => {
        if (err[0].errors[0].code === "file-too-large") {
          toast.error("Files must be 3GB or less");
        }
      },
      onDrop,
      accept: {
        "video/mp4": [],
        "video/quicktime": [],
      },
    });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <>
      <Dialog open={isCreatingViral} fullWidth={true} maxWidth={"sm"}>
        <DialogTitle>Uploading...</DialogTitle>
        <DialogContent>
          <LinearProgress
            variant="determinate"
            color={"secondary"}
            value={uploadProgress}
          />
          <br />
          <DialogContentText>
            This should only take a minute, please don't close the browser while the
            video is uploading
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack
            direction={"column"}
            alignItems="flex-start"
            justifyContent={"space-between"}
          >
            <Typography variant={"h5"}>Create new viral</Typography>
            <List>
              <ListItem disableGutters={true} disablePadding={true}>
                <ListItemIcon>
                  <ArrowForwardIosIcon fontSize={"small"} />
                </ListItemIcon>
                <ListItemText>
                  <Typography variant={"body1"} color={"gray"}>
                    <Button
                      size={'small'}
                      onClick={() => {navigate('/how-to')}}
                    >
                      Tips for creating a good Viral
                    </Button>
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem disableGutters={true} disablePadding={true}>
                <ListItemIcon>
                  <ArrowForwardIosIcon fontSize={"small"} />
                </ListItemIcon>
                <ListItemText>
                  <Typography variant={"body1"} color={"gray"}>
                    <Button
                      size={'small'}
                      onClick={() => {
                        window.open('https://viraldaddy.ai/#/#showcase', '_blank');
                      }}
                    >
                      See Example Videos To Upload
                    </Button>
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem disableGutters={true} disablePadding={true}>
                <ListItemIcon>
                  <InfoOutlinedIcon fontSize={"small"} />
                </ListItemIcon>
                <ListItemText>
                  <Typography variant={"body1"} color={"gray"}>
                    Video must be 3GB or less
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem disableGutters={true} disablePadding={true}>
                <ListItemIcon>
                  <InfoOutlinedIcon fontSize={"small"} />
                </ListItemIcon>
                <ListItemText>
                  <Typography variant={"body1"} color={"gray"}>
                    Video must be a minimum of 30 seconds long
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem disableGutters={true} disablePadding={true}>
                <ListItemIcon>
                  <InfoOutlinedIcon fontSize={"small"} />
                </ListItemIcon>
                <ListItemText>
                  <Typography variant={"body1"} color={"gray"}>
                    Video must be .mp4 or .mov file
                  </Typography>
                </ListItemText>
              </ListItem>
            </List>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            {/*// @ts-ignore*/}
            <div {...getRootProps({ style })}>
              <input {...getInputProps()} />
              <p>Drag 'n' drop some files here, or click to select files!</p>
            </div>
          </Box>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 10 }}>
          <Grid container spacing={2}>
            {uploadedVideos.map((video, i) => {
              return (
                <Grid key={i} item style={{ width: "426px", height: "240px" }}>
                  <video
                    id={`raw-${i}`}
                    width={"100%"}
                    height={"100%"}
                    controls={true}
                    src={video}
                  />
                </Grid>
              );
            })}
          </Grid>
          <br />
          {isVideoTooShort ? (
            <Chip
              color="warning"
              label="Video Too Short - Video needs to be a minimum of 30 seconds - Try uploading a longer video!"
            />
          ) : (
            ""
          )}
        </Grid>
        <Grid xs={12} item>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
          >
            <Box style={{ marginBottom: "20px" }}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Viral name (Optional)</FormLabel>
                <TextField
                  size={"small"}
                  value={viralName}
                  inputProps={{ maxLength: 35 }}
                  onChange={(e) => setViralName(e.target.value)}
                />
              </FormControl>
            </Box>
            <Box style={{ marginBottom: "20px" }}>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  Watermark
                  <Tooltip title="Head to your settings to change your watermark">
                    <HelpOutlineIcon fontSize={"small"}></HelpOutlineIcon>
                  </Tooltip>
                </FormLabel>
                <ToggleButtonGroup
                  size={"small"}
                  color="primary"
                  value={includeWatermark}
                  exclusive
                  onChange={handleIncludeWaterMark}
                  aria-label="Platform"
                >
                  <ToggleButton value="true">Yes</ToggleButton>
                  <ToggleButton value="false">No</ToggleButton>
                </ToggleButtonGroup>
                <FormLabel>
                  <Typography variant={"caption"}>
                    Watermark preference: <b>{user.data?.watermarkPref}</b>
                    <br />
                    {user.data?.watermarkPref === "text" ? (
                      <span>
                        Watermark text: <b>{user.data?.watermarkText}</b>
                      </span>
                    ) : (
                      ""
                    )}
                  </Typography>
                </FormLabel>
              </FormControl>
            </Box>
            <Box>
              <LengthPreset
                lengthPreset={lengthPreset}
                setLengthPreset={setLengthPreset}
              />
            </Box>
            {/*<Box>*/}
            {/*  <Accordion style={{backgroundColor: 'none'}} disabled={true}>*/}
            {/*    <AccordionSummary expandIcon={<ExpandMoreIcon />}>*/}
            {/*      Advanced (coming soon)*/}
            {/*    </AccordionSummary>*/}
            {/*    <AccordionDetails>*/}
            {/*      <Box>*/}
            {/*        <FormControl size="small">*/}
            {/*          <InputLabel id="demo-simple-select-label">Add Captions: </InputLabel>*/}
            {/*          <Stack direction="row" spacing={2}>*/}
            {/*            <Select*/}
            {/*              sx={{minWidth: 150 }}*/}
            {/*              labelId="demo-simple-select-label"*/}
            {/*              id="demo-simple-select"*/}
            {/*              value={selectedCaptionCategory}*/}
            {/*              label="Generate Caption"*/}
            {/*              onChange={handleGetCaptions}*/}
            {/*            >*/}
            {/*              <MenuItem value={'NONE'}>None</MenuItem>*/}
            {/*              <MenuItem value={'GENERAL'}>General Kink</MenuItem>*/}
            {/*              <MenuItem value={'JOI'}>JOI</MenuItem>*/}
            {/*            </Select>*/}
            {/*            <Button disabled={!getCaptionHook.data} onClick={() => getCaptionHook.refetch()} variant={"contained"}> Regenerate</Button>*/}
            {/*          </Stack>*/}
            {/*        </FormControl>*/}
            {/*        <Box style={{marginTop: 10}}>*/}
            {/*          /!*<Typography style={{paddingBottom: 5}} variant={'caption'} display={'block'}>We'll display two captions split evenly over your viral clip.</Typography>*!/*/}
            {/*          {getCaptionHook.data?.map((caption,i) => {*/}
            {/*            return <Typography key={i}>Caption {i+1}: {caption}</Typography>*/}
            {/*          })}*/}
            {/*        </Box>*/}
            {/*      </Box>*/}
            {/*      <Box sx={{ mt: 3 }}>*/}
            {/*        <FormControl sx={{ width: 300 }}>*/}
            {/*          <InputLabel id="demo-multiple-chip-label">Category Weight</InputLabel>*/}
            {/*          <Select*/}
            {/*            labelId="multiple-chip-label"*/}
            {/*            id="multiple-chip"*/}
            {/*            multiple*/}
            {/*            size={"small"}*/}
            {/*            value={categoryWeights}*/}
            {/*            onChange={handleCategoryWeightChange}*/}
            {/*            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}*/}
            {/*            renderValue={(selected) => (*/}
            {/*              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>*/}
            {/*                {selected.map((value) => (*/}
            {/*                  <Chip key={value} label={value} />*/}
            {/*                ))}*/}
            {/*              </Box>*/}
            {/*            )}*/}
            {/*          >*/}
            {/*            {categories.map((name) => (*/}
            {/*              <MenuItem*/}
            {/*                key={name}*/}
            {/*                value={name}*/}
            {/*              >*/}
            {/*                {name}*/}
            {/*              </MenuItem>*/}
            {/*            ))}*/}
            {/*          </Select>*/}
            {/*          <FormHelperText>This feature helps guarantee at lease 1 cut from a specific category</FormHelperText>*/}

            {/*        </FormControl>*/}
            {/*      </Box>*/}
            {/*    </AccordionDetails>*/}
            {/*  </Accordion>*/}
            {/*</Box>*/}
            {user.data?.role === "admin" ? (
              <Box>
                <Accordion style={{ backgroundColor: "none" }} disabled={false}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    Admin
                  </AccordionSummary>
                  <AccordionDetails>
                    <Stack direction="row" spacing={2}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(event, checked) => {
                              setIsPromo(checked);
                            }}
                          />
                        }
                        label="Promo"
                      />
                      <TextField
                        disabled={!isPromo}
                        label={"Made for..."}
                        size={"small"}
                        inputProps={{ maxLength: 35 }}
                        onChange={(e) => setPromoText(e.target.value)}
                      />
                    </Stack>
                  </AccordionDetails>
                </Accordion>
              </Box>
            ) : null}

            <Box style={{ bottom: 0, left: 0, height: "50px" }}>
              <LoadingButton
                fullWidth={true}
                loading={isCreatingViral}
                disabled={uploadedVideos.length < 1 || isVideoTooShort}
                variant="contained"
                onClick={async () => processViral()}
                style={{ height: "100%" }}
              >
                Create Viral
              </LoadingButton>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};
