import React, { useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import toast from "react-hot-toast";
import LinkIcon from "@mui/icons-material/Link";
import CollectionsIcon from "@mui/icons-material/Collections";
import {
  acceptStyle,
  baseStyle,
  focusedStyle,
  rejectStyle,
} from "../../../shared/styles/drag-n-drop-style";
import {Tab, Tabs, useMediaQuery} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LinkChips from "./link-chips";
import {PostScheduleBuilder} from "../craete-post-builder";
import {CreatePostBody} from "../../../api/types";
import {usePostRequestContext} from "./post-request-provider";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import {GradientOpenWithIcon} from "../../../components/upload-icon";


const MAX_REDDIT_UPLOADS = 7;
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0, pt: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
export const UploadComponent = () => {
  const postContext = usePostRequestContext();
  const navigate = useNavigate();
  const [tabValue, setTabValue] = React.useState(0);
  const isSmallScreen = useMediaQuery('(max-width:768px)');

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    const posts: CreatePostBody[] = [];
    acceptedFiles.forEach(f => {
      const builder = new PostScheduleBuilder();
      const post = builder
        .setLink(null)
        .setFile(true)
        .setObjectFile(f)
        .setExtension(f.type);
      posts.push(post.build());
    })
    if(postContext.postRequestContext.length + acceptedFiles.length > MAX_REDDIT_UPLOADS){
      toast.error(`Too many files uploaded, maximum ${MAX_REDDIT_UPLOADS}`);
    } else {
      postContext.setPostRequestContext([...postContext.postRequestContext, ...posts])
    }
  }, [postContext]);

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      maxSize: 1000000000,
      maxFiles: MAX_REDDIT_UPLOADS,
      onDropRejected: (err) => {
        const uniqueErrors = new Set();

        err.forEach(file => {
          file.errors.forEach(error => {
            uniqueErrors.add(error.code);
          });
        });

        uniqueErrors.forEach(errorCode => {
          if (errorCode === "file-too-large") {
            toast.error("Files must be 1GB or less");
          } else if (errorCode === "too-many-files") {
            toast.error(`Too many files uploaded, maximum ${MAX_REDDIT_UPLOADS}`);
          } else {
            toast.error("An error occurred: " + errorCode);
          }
        });
      },
      onDrop,
      accept: {
        "video/mp4": [],
        "video/quicktime": [],
        'image/gif': [],
        'image/jpeg': [],
        'video/mpeg': [],
        'image/png': [],
        'video/webm': [],
        'image/webp': [],
      },
    });

  const style = useMemo(
    () => ({
      ...baseStyle,
      cursor: 'pointer',
      minHeight: 200,
      fontSize: isSmallScreen ? "1.5em" : "2em",  // Adjusted font size
      padding: isSmallScreen ? '30px' : '50px',   // Adjusted padding
      textAlign: 'center',
      display: 'flex',            // New
      flexDirection: 'column',    // New
      justifyContent: 'center',   // New
      alignItems: 'center',       // New
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const handleNext = () => {
    navigate('/posts/new/customize')
  }

  return (
    <>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="icon position tabs example"
      >
        <Tab icon={<CollectionsIcon />} iconPosition="start" label="Redgifs" />
        <Tab icon={<LinkIcon />} iconPosition="start" label="Links" />
      </Tabs>
      <CustomTabPanel value={tabValue} index={0}>
        {/*@ts-ignore*/}
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <div style={{ position: 'relative', marginBottom: '10px' }}>
            <GradientOpenWithIcon platform={'reddit'}/>
          </div>
          <Typography variant={'h5'} >
            Click here to upload file(s).
          </Typography>
          <Typography variant={'h5'} >
            We will upload them to redgifs for you.
          </Typography>
          <Typography variant={isSmallScreen ? 'body1' : 'h6'}>
            <b>For Reddit:</b> We always attempt to post with Redgifs links on your behalf.
          </Typography>
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={1}>
        <LinkChips/>
      </CustomTabPanel>
      <Button style={{marginTop: 15}} variant={"contained"} onClick={() => handleNext()}>
        Next
      </Button>
    </>
  );
};
