import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import {
  submitViralToReddit as submitViralToProviderApi,
  SubmitViralToRedditBody,
} from "../../../api";
import toast from "react-hot-toast";
import LoadingButton from "@mui/lab/LoadingButton";

interface SubmitToRedditDialogProps {
  isOpen: boolean;
  viralId: string;
  thumbnailUrl: string;
  handleOnClose: () => void;
}

export default function SubmitToRedditDialog({
  isOpen,
  thumbnailUrl,
  handleOnClose,
  viralId,
}: SubmitToRedditDialogProps) {
  const submitViralToProvider = useMutation({
    mutationFn: (submitViralToProviderBody: SubmitViralToRedditBody) =>
      submitViralToProviderApi(submitViralToProviderBody),
    onSuccess: (result) => {
      toast.success("Posted successful!");
      handleOnClose();
    },
  });

  const [title, setTitle] = useState("");
  const handleSubmitToProvider = (
    viralId: string,
    provider: string,
    title: string
  ) => {
    submitViralToProvider.mutate({ viralId, provider, title });
  };

  const handleOnCloseLocal = () => {
    setTitle("");
    handleOnClose();
  };
  return (
    <div>
      <Dialog open={isOpen} onClose={handleOnCloseLocal} fullWidth>
        <DialogTitle>Post to your Reddit wall</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{display: 'flex', justifyContent: 'center', backgroundColor: 'black', mb: 2}}>
            <img
              style={{ height: 200, objectFit: "cover" }}
              src={thumbnailUrl}
              alt="thumbnail-image"
            />
          </DialogContentText>
          <TextField
            disabled={submitViralToProvider.isLoading}
            autoFocus
            margin="dense"
            id="name"
            label="Title"
            type="text"
            fullWidth
            variant="standard"
            error={title.length <= 1}
            required={true}
            inputProps={{ maxLength: 130 }}
            onChange={(e) => setTitle(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={submitViralToProvider.isLoading}
            onClick={handleOnCloseLocal}
          >
            Cancel
          </Button>
          <LoadingButton
            disabled={!title}
            loading={submitViralToProvider.isLoading}
            onClick={() => handleSubmitToProvider(viralId, "reddit", title)}
          >
            Submit
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
