import React, { useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import toast from "react-hot-toast";
import CollectionsIcon from "@mui/icons-material/Collections";
import {
  acceptStyle,
  baseStyle,
  focusedStyle,
  rejectStyle,
} from "../../../shared/styles/drag-n-drop-style";
import {Tab, Tabs, useMediaQuery} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {PostScheduleBuilder} from "../../create-post/craete-post-builder";
import {CreatePostBody} from "../../../api/types";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import {useTweetRequestContext} from "./tweet-request-provider";
import {GradientOpenWithIcon} from "../../../components/upload-icon";

const MAX_TWITTER_UPLOADS = 7;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0, pt: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
export const UploadComponent = () => {
  const tweetContext = useTweetRequestContext();
  const navigate = useNavigate();
  const [tabValue, setTabValue] = React.useState(0);
  const isSmallScreen = useMediaQuery('(max-width:768px)');

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    const posts: CreatePostBody[] = [];
    acceptedFiles.forEach(f => {
      const builder = new PostScheduleBuilder();
      const post = builder
        .setLink(null)
        .setFile(true)
        .setObjectFile(f)
        .setExtension(f.type);
      posts.push(post.build());
    })
    if(tweetContext.tweetRequestContext.length + acceptedFiles.length > MAX_TWITTER_UPLOADS){
      toast.error(`Too many files uploaded, maximum ${MAX_TWITTER_UPLOADS}`);
    } else {
      tweetContext.setTweetRequestContext([...tweetContext.tweetRequestContext, ...posts])
    }
  }, [tweetContext]);

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      maxSize: 1000000000,
      maxFiles: MAX_TWITTER_UPLOADS,
      onDropRejected: (err) => {
        const uniqueErrors = new Set();

        err.forEach(file => {
          file.errors.forEach(error => {
            uniqueErrors.add(error.code);
          });
        });

        uniqueErrors.forEach(errorCode => {
          if (errorCode === "file-too-large") {
            toast.error("Files must be 1GB or less");
          } else if (errorCode === "too-many-files") {
            toast.error(`Too many files uploaded, maximum ${MAX_TWITTER_UPLOADS}`);
          } else {
            toast.error("An error occurred: " + errorCode);
          }
        });
      },
      onDrop,
      accept: {
        "video/mp4": [],
        "video/quicktime": [],
        'image/gif': [],
        'image/jpeg': [],
        'video/mpeg': [],
        'image/png': [],
        'video/webm': [],
        'image/webp': [],
      },
    });

  const style = useMemo(
    () => ({
      ...baseStyle,
      cursor: 'pointer',
      minHeight: 200,
      fontSize: isSmallScreen ? "1.5em" : "2em",
      padding: isSmallScreen ? '30px' : '50px',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const handleNext = () => {
    navigate('/tweets/new/review')
  }

  return (
    <>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="icon position tabs example"
      >
        <Tab icon={<CollectionsIcon />} iconPosition="start" label="Direct" />
      </Tabs>
      <CustomTabPanel value={tabValue} index={0}>
        {/*@ts-ignore*/}
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <div style={{ position: 'relative', marginBottom: '10px' }}>
            <GradientOpenWithIcon platform={'twitter'}/>
          </div>
          <Typography variant={'h5'} >
            Click here to upload file(s).
          </Typography>
          <Typography variant={'h5'} >
            We will upload them to Twitter for you.
          </Typography>
        </div>
      </CustomTabPanel>
      <Button style={{marginTop: 15}} variant={"contained"} onClick={() => handleNext()}>
        Next
      </Button>
    </>
  );
};
